import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import "../../pages.scss"

import { getResponse } from "../../../helpers/api_helper"

//i18n
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class Bitwise extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bitwises: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      disthresh1: false,
      disthresh2: false,
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.inputData1Change = this.inputData1Change.bind(this);
    this.inputData2Change = this.inputData2Change.bind(this);
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })

    const cloudInput = {
      input1: values.inputData1,
      input2: values.inputData2,
      bitwiseOperation: values.bitwiseOperation,
    };

    getResponse(cloudInput, "/cloud/v1/Bitwise/Bitwise")
      .then((resp) => {
        this.setState({ buttonTimer: false })
        if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
          this.setState({ respData: [resp.resultData] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addSuccessItem();
        }
        else {
          this.setState({ respErrorData: [resp.resultDesc] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addFailItem();
        }
      })
  }

  addSuccessItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("bitwiseOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-success" role="alert">
    //         <h4 className="alert-heading">{this.props.t("bitwiseOperationSuccess")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // )
    // this.setState({ items: [...this.state.items] })

  }
  addFailItem = () => {
    this.state({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now}>
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("bitwiseOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-danger" role="alert">
    //         <h4 className="alert-heading">{this.props.t("bitwiseOperationFail")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respErrorData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // );
    // this.setState({ items: [...this.state.items] })
  }

  inputData1Change(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ inputData1Change: event.target.value.length })
  }

  inputData2Change(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh2: true })
    } else {
      this.setState({ disthresh2: false })
    }
    this.setState({ inputData2Change: event.target.value.length })
  }

  render() {

    const defaultValues = { bitwiseOperation: "AND" }
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools| {this.props.t("bitwise")}</title>
              <meta name="description" content="Bitwise operations are fundamental operations used in computer programming to manipulate individual bits within binary numbers. Learn about bitwise AND, bitwise OR, bitwise XOR, bitwise NOT, and their applications in data manipulation and optimization." />
              <meta name="keywords" content="Bitwise Operations, Bitwise AND, Bitwise OR, Bitwise XOR, Bitwise NOT, Bit Manipulation, Binary Numbers, Programming, Computer Science, Data Manipulation, Optimization" />
            </MetaTags>
          </Helmet>

          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("bitwise")} breadcrumbItem={this.props.t("bitwiseOperation")} />
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit} model={defaultValues}>
                      <Row className="rowAes">
                        <Col>
                          <FormGroup>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="BitwiseOpt"
                            >
                              {this.props.t("bitwiseOperationInfo")}
                            </UncontrolledTooltip>
                            <AvRadioGroup inline name="bitwiseOperation" errorMessage={this.props.t("pickOne")} required>
                              <h6>{this.props.t("bitwiseOperation")}</h6>
                              <AvRadio label="AND" value="AND" />
                              <AvRadio label="XOR" value="XOR" />
                              <AvRadio label="OR" value="OR" />
                              <div className="infoSymbol">
                              </div>
                            </AvRadioGroup>
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="BitwiseOpt"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("inputData1")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="InputData1"
                            >
                              {this.props.t("inputData1Info")}
                            </UncontrolledTooltip>
                            <AvField
                              name="inputData1"
                              placeholder={this.props.t("fill")}
                              type="input"
                              value={this.state.bitwises
                                ? this.state.bitwises.inputData1 : ""}
                              onChange={this.inputData1Change}
                              validate={{
                                pattern: { value: /^[A-Fa-f0-9]+$/, errorMessage: this.props.t("dataShouldBeHEXDataFormat") },
                                required: { value: true, errorMessage: this.props.t("canNotBeEmpty") }
                              }}
                            />
                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.inputData1Change}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="InputData1"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("inputData2")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="InputData2"
                            >
                              {this.props.t("inputData2Info")}
                            </UncontrolledTooltip>
                            <AvField
                              name="inputData2"
                              placeholder={this.props.t("fill")}
                              type="input"
                              value={this.state.bitwises
                                ? this.state.bitwises.inputData2 : ""}
                              onChange={this.inputData2Change}
                              validate={{
                                pattern: { value: /^[A-Fa-f0-9]+$/, errorMessage: this.props.t("dataShouldBeHEXDataFormat") },
                                required: { value: true, errorMessage: this.props.t("canNotBeEmpty") }
                              }}
                            />
                            {this.state.disthresh2 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.inputData2Change}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="InputData2"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="secondary"
                          onClick={() => window.location.reload()}
                          style={{ textAlign: "start" }}
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          disabled={this.state.buttonTimer}
                          color="primary"
                        >
                          {this.props.t("calculateButton")}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Bitwise.propTypes = {
  t: PropTypes.any,
}

export default (withTranslation()(Bitwise));
