import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let matchingMenuItem = null;
    const ul = document.getElementById("navigation");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ appState: !this.state.appState });
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-customize me-2" />
                      {this.props.t("keys")} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.appState,
                      })}
                    >

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({ integrationState: !this.state.integrationState });
                          }}
                        >
                          {this.props.t("thalesKeyBlock")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.integrationState,
                          })}
                        >
                          <Link to="/TKBEncode" className="dropdown-item">
                            {this.props.t("tkbEncode")}
                          </Link>
                        </div>

                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.integrationState,
                          })}
                        >
                          <Link to="/applicationID" className="dropdown-item">
                            {this.props.t("aid")}
                          </Link>
                        </div>

                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.integrationState,
                          })}
                        >
                          <Link to="/IssuerAppData" className="dropdown-item">
                            {this.props.t("iad")}
                          </Link>
                        </div>

                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.integrationState,
                          })}
                        >
                          <Link to="/TKBDecode" className="dropdown-item">
                            {this.props.t("tkbDecode")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ appState: !this.state.appState });
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-customize me-2" />
                      {this.props.t("systemGeneral")} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.appState,
                      })}
                    >
                    </div>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ systemGeneralState: !this.state.systemGeneralState });
                        }}
                      >
                        {this.props.t("systemGeneral")}{" "}
                        <div className="arrow-down" />
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.systemGeneralState,
                        })}
                      >
                        <Link to="/testdefinition" className="dropdown-item">
                          {this.props.t("testDefinition")}
                        </Link>
                      </div>
                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.systemGeneralState,
                        })}
                      >
                        <Link to="/memberdefinition" className="dropdown-item">
                          {this.props.t("memberDefinition")}
                        </Link>
                      </div>
                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.systemGeneralState,
                        })}
                      >
                        <Link to="/pagedefinition" className="dropdown-item">
                          {this.props.t("pageDefinition")}
                        </Link>
                      </div>
                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.systemGeneralState,
                        })}
                      >
                        <Link to="/roledefinition" className="dropdown-item">
                          {this.props.t("roleDefinition")}
                        </Link>
                      </div>
                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.systemGeneralState,
                        })}
                      >
                        <Link to="/userdefinition" className="dropdown-item">
                          {this.props.t("userDefinition")}
                        </Link>
                      </div>

                    </div>
                  </li>

                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Navbar));
