import React, { Component } from "react"
import MetaTags from 'react-meta-tags';

import {
  Col,
  Container,
  Row,
} from "reactstrap"

import video from "../assets/images/video.mp4";
import "pages/pages.scss"

class Dashboard extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Wechip - Wechip Payment Tools</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Col>
              <div>
                <video
                  style={{ maxWidth: "100%", width: "100%", margin: "0 auto", borderBottomWidth: 0 }}
                  playsInline
                  disablePictureInPicture
                  autoPlay
                  muted
                  controls={false}
                  alt="All the devices"
                  type= "video/mp4"
                  src={video}
                  />
              </div>
            </Col>
            <Row>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Dashboard