import {
  GET_TEST_DEFINITIONS,
  GET_TEST_DEFINITIONS_SUCCESS,
  GET_TEST_DEFINITIONS_FAIL,
  ADD_NEW_TEST_DEFINITION,
  ADD_TEST_DEFINITION_SUCCESS,
  ADD_TEST_DEFINITION_FAIL,
  UPDATE_TEST_DEFINITION,
  UPDATE_TEST_DEFINITION_SUCCESS,
  UPDATE_TEST_DEFINITION_FAIL,
  DELETE_TEST_DEFINITION,
  DELETE_TEST_DEFINITION_SUCCESS,
  DELETE_TEST_DEFINITION_FAIL,

  GET_MEMBER_DEFINITIONS,
  GET_MEMBER_DEFINITIONS_SUCCESS,
  GET_MEMBER_DEFINITIONS_FAIL,
  ADD_NEW_MEMBER_DEFINITION,
  ADD_MEMBER_DEFINITION_SUCCESS,
  ADD_MEMBER_DEFINITION_FAIL,
  UPDATE_MEMBER_DEFINITION,
  UPDATE_MEMBER_DEFINITION_SUCCESS,
  UPDATE_MEMBER_DEFINITION_FAIL,
  DELETE_MEMBER_DEFINITION,
  DELETE_MEMBER_DEFINITION_SUCCESS,
  DELETE_MEMBER_DEFINITION_FAIL,

  GET_PAGE_DEFINITIONS,
  GET_PAGE_DEFINITIONS_SUCCESS,
  GET_PAGE_DEFINITIONS_FAIL,
  ADD_NEW_PAGE_DEFINITION,
  ADD_PAGE_DEFINITION_SUCCESS,
  ADD_PAGE_DEFINITION_FAIL,
  UPDATE_PAGE_DEFINITION,
  UPDATE_PAGE_DEFINITION_SUCCESS,
  UPDATE_PAGE_DEFINITION_FAIL,
  DELETE_PAGE_DEFINITION,
  DELETE_PAGE_DEFINITION_SUCCESS,
  DELETE_PAGE_DEFINITION_FAIL,

  GET_ROLE_DEFINITIONS,
  GET_ROLE_DEFINITIONS_SUCCESS,
  GET_ROLE_DEFINITIONS_FAIL,
  ADD_NEW_ROLE_DEFINITION,
  ADD_ROLE_DEFINITION_SUCCESS,
  ADD_ROLE_DEFINITION_FAIL,
  UPDATE_ROLE_DEFINITION,
  UPDATE_ROLE_DEFINITION_SUCCESS,
  UPDATE_ROLE_DEFINITION_FAIL,
  DELETE_ROLE_DEFINITION,
  DELETE_ROLE_DEFINITION_SUCCESS,
  DELETE_ROLE_DEFINITION_FAIL,

  GET_USER_DEFINITIONS,
  GET_USER_DEFINITIONS_SUCCESS,
  GET_USER_DEFINITIONS_FAIL,
  ADD_NEW_USER_DEFINITION,
  ADD_USER_DEFINITION_SUCCESS,
  ADD_USER_DEFINITION_FAIL,
  UPDATE_USER_DEFINITION,
  UPDATE_USER_DEFINITION_SUCCESS,
  UPDATE_USER_DEFINITION_FAIL,
  DELETE_USER_DEFINITION,
  DELETE_USER_DEFINITION_SUCCESS,
  DELETE_USER_DEFINITION_FAIL,

} from "./actionTypes"


export const getTestDefinitions = () => ({
  type: GET_TEST_DEFINITIONS,
})

export const getTestDefinitionsSuccess = testDefinition => ({
  type: GET_TEST_DEFINITIONS_SUCCESS,
  payload: testDefinition,
})

export const getTestDefinitionsFail = error => ({
  type: GET_TEST_DEFINITIONS_FAIL,
  payload: error,
})

export const addNewTestDefinition = testDefinition => ({
  type: ADD_NEW_TEST_DEFINITION,
  payload: testDefinition,
})

export const addTestDefinitionSuccess = testDefinition => ({
  type: ADD_TEST_DEFINITION_SUCCESS,
  payload: testDefinition,
})

export const addTestDefinitionFail = error => ({
  type: ADD_TEST_DEFINITION_FAIL,
  payload: error,
})

export const updateTestDefinition = testDefinition => ({
  type: UPDATE_TEST_DEFINITION,
  payload: testDefinition,
})

export const updateTestDefinitionSuccess = testDefinition => ({
  type: UPDATE_TEST_DEFINITION_SUCCESS,
  payload: testDefinition,
})

export const updateTestDefinitionFail = error => ({
  type: UPDATE_TEST_DEFINITION_FAIL,
  payload: error,
})

export const deleteTestDefinition = testDefinition => ({
  type: DELETE_TEST_DEFINITION,
  payload: testDefinition,
})

export const deleteTestDefinitionSuccess = testDefinition => ({
  type: DELETE_TEST_DEFINITION_SUCCESS,
  payload: testDefinition,
})

export const deleteTestDefinitionFail = error => ({
  type: DELETE_TEST_DEFINITION_FAIL,
  payload: error,
})

// MEMBER DEFINITION

export const getMemberDefinitions = () => ({
  type: GET_MEMBER_DEFINITIONS,
})

export const getMemberDefinitionsSuccess = memberDefinition => ({
  type: GET_MEMBER_DEFINITIONS_SUCCESS,
  payload: memberDefinition,
})

export const getMemberDefinitionsFail = error => ({
  type: GET_MEMBER_DEFINITIONS_FAIL,
  payload: error,
})

export const addNewMemberDefinition = memberDefinition => ({
  type: ADD_NEW_MEMBER_DEFINITION,
  payload: memberDefinition,
})

export const addMemberDefinitionSuccess = memberDefinition => ({
  type: ADD_MEMBER_DEFINITION_SUCCESS,
  payload: memberDefinition,
})

export const addMemberDefinitionFail = error => ({
  type: ADD_MEMBER_DEFINITION_FAIL,
  payload: error,
})

export const updateMemberDefinition = memberDefinition => ({
  type: UPDATE_MEMBER_DEFINITION,
  payload: memberDefinition,
})

export const updateMemberDefinitionSuccess = memberDefinition => ({
  type: UPDATE_MEMBER_DEFINITION_SUCCESS,
  payload: memberDefinition,
})

export const updateMemberDefinitionFail = error => ({
  type: UPDATE_MEMBER_DEFINITION_FAIL,
  payload: error,
})

export const deleteMemberDefinition = guid => ({
  type: DELETE_MEMBER_DEFINITION,
  payload: guid,
})

export const deleteMemberDefinitionSuccess = guid => ({
  type: DELETE_MEMBER_DEFINITION_SUCCESS,
  payload: guid,
})

export const deleteMemberDefinitionFail = error => ({
  type: DELETE_MEMBER_DEFINITION_FAIL,
  payload: error,
})

// PAGE DEFINITION

export const getPageDefinitions = () => ({
  type: GET_PAGE_DEFINITIONS,
})

export const getPageDefinitionsSuccess = pageDefinition => ({
  type: GET_PAGE_DEFINITIONS_SUCCESS,
  payload: pageDefinition,
})

export const getPageDefinitionsFail = error => ({
  type: GET_PAGE_DEFINITIONS_FAIL,
  payload: error,
})

export const addNewPageDefinition = pageDefinition => ({
  type: ADD_NEW_PAGE_DEFINITION,
  payload: pageDefinition,
})

export const addPageDefinitionSuccess = pageDefinition => ({
  type: ADD_PAGE_DEFINITION_SUCCESS,
  payload: pageDefinition,
})

export const addPageDefinitionFail = error => ({
  type: ADD_PAGE_DEFINITION_FAIL,
  payload: error,
})

export const updatePageDefinition = pageDefinition => ({
  type: UPDATE_PAGE_DEFINITION,
  payload: pageDefinition,
})

export const updatePageDefinitionSuccess = pageDefinition => ({
  type: UPDATE_PAGE_DEFINITION_SUCCESS,
  payload: pageDefinition,
})

export const updatePageDefinitionFail = error => ({
  type: UPDATE_PAGE_DEFINITION_FAIL,
  payload: error,
})

export const deletePageDefinition = guid => ({
  type: DELETE_PAGE_DEFINITION,
  payload: guid,
})

export const deletePageDefinitionSuccess = guid => ({
  type: DELETE_PAGE_DEFINITION_SUCCESS,
  payload: guid,
})

export const deletePageDefinitionFail = error => ({
  type: DELETE_PAGE_DEFINITION_FAIL,
  payload: error,
})

// ROLE DEFINITION 

export const getRoleDefinitions = () => ({
  type: GET_ROLE_DEFINITIONS,
})

export const getRoleDefinitionsSuccess = roleDefinition => ({
  type: GET_ROLE_DEFINITIONS_SUCCESS,
  payload: roleDefinition,
})

export const getRoleDefinitionsFail = error => ({
  type: GET_ROLE_DEFINITIONS_FAIL,
  payload: error,
})

export const addNewRoleDefinition = roleDefinition => ({
  type: ADD_NEW_ROLE_DEFINITION,
  payload: roleDefinition,
})

export const addRoleDefinitionSuccess = roleDefinition => ({
  type: ADD_ROLE_DEFINITION_SUCCESS,
  payload: roleDefinition,
})

export const addRoleDefinitionFail = error => ({
  type: ADD_ROLE_DEFINITION_FAIL,
  payload: error,
})

export const updateRoleDefinition = roleDefinition => ({
  type: UPDATE_ROLE_DEFINITION,
  payload: roleDefinition,
})

export const updateRoleDefinitionSuccess = roleDefinition => ({
  type: UPDATE_ROLE_DEFINITION_SUCCESS,
  payload: roleDefinition,
})

export const updateRoleDefinitionFail = error => ({
  type: UPDATE_ROLE_DEFINITION_FAIL,
  payload: error,
})

export const deleteRoleDefinition = guid => ({
  type: DELETE_ROLE_DEFINITION,
  payload: guid,
})

export const deleteRoleDefinitionSuccess = guid => ({
  type: DELETE_ROLE_DEFINITION_SUCCESS,
  payload: guid,
})

export const deleteRoleDefinitionFail = error => ({
  type: DELETE_ROLE_DEFINITION_FAIL,
  payload: error,
})

// USER DEFINITION

export const getUserDefinitions = () => ({
  type: GET_USER_DEFINITIONS,
})

export const getUserDefinitionsSuccess = userDefinition => ({
  type: GET_USER_DEFINITIONS_SUCCESS,
  payload: userDefinition,
})

export const getUserDefinitionsFail = error => ({
  type: GET_USER_DEFINITIONS_FAIL,
  payload: error,
})

export const addNewUserDefinition = userDefinition => ({
  type: ADD_NEW_USER_DEFINITION,
  payload: userDefinition,
})

export const addUserDefinitionSuccess = userDefinition => ({
  type: ADD_USER_DEFINITION_SUCCESS,
  payload: userDefinition,
})

export const addUserDefinitionFail = error => ({
  type: ADD_USER_DEFINITION_FAIL,
  payload: error,
})

export const updateUserDefinition = userDefinition => ({
  type: UPDATE_USER_DEFINITION,
  payload: userDefinition,
})

export const updateUserDefinitionSuccess = userDefinition => ({
  type: UPDATE_USER_DEFINITION_SUCCESS,
  payload: userDefinition,
})

export const updateUserDefinitionFail = error => ({
  type: UPDATE_USER_DEFINITION_FAIL,
  payload: error,
})

export const deleteUserDefinition = guid => ({
  type: DELETE_USER_DEFINITION,
  payload: guid,
})

export const deleteUserDefinitionSuccess = guid => ({
  type: DELETE_USER_DEFINITION_SUCCESS,
  payload: guid,
})

export const deleteUserDefinitionFail = error => ({
  type: DELETE_USER_DEFINITION_FAIL,
  payload: error,
})

