import React, { Component, useState } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
  Row,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import "../../pages.scss"

import { getResponse } from "../../../helpers/api_helper"
import { withTranslation } from "react-i18next";


class SubscribeOperation extends Component {
  constructor(props) {
    super(props)
    this.state = {

      respData: [],
      respErrorData: [],
      successAlert: [],
      histjson: [],
      items: []
    
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    const cloudInput = {
      userForRegisterDto: values,
    }
    getResponse(values, "/cloud/v1/Register")
      .then((resp) => {
        this.setState({ buttonTimer: false })
        if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
          this.setState({ respData: [resp.resultData] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addSuccessItem();
          this.props.history.push("/UserVerificationOperation",values)
        }
        else {
          this.setState({ respErrorData: [resp.resultDesc] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addFailItem();
        }
      })
  }

  addSuccessItem = () => {
    this.state.items.push(<Col key={Date.now()} >
      <Card>
        <CardBody>
          <Alert className="alert-success" role="alert">
            <h4 className="alert-heading">{this.props.t("signUpOperationFinishedSuccess")}</h4>
            <hr />
            <div className="display-linebreak">{this.state.respData}</div>
          </Alert>
        </CardBody>
      </Card>
    </Col>
    )
    this.setState({ items: [...this.state.items] })
  }

  addFailItem = () => {
    this.state.items.push(<Col key={Date.now()} >
      <Card>
        <CardBody>
          <Alert className="alert-danger" role="alert">
            <h4 className="alert-heading">{this.props.t("signUpOperationFinishedError")}</h4>
            <hr />
            <div className="display-linebreak">{this.state.respErrorData}</div>
          </Alert>
        </CardBody>
      </Card>
    </Col>
    );
    this.setState({ items: [...this.state.items] })
  }

  render() {
    const defaultValues = { searchOption: true }

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Wechip | SUBSCRIBE </title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("subscribeUser")} breadcrumbItem={this.props.t("signUp")} style={{ textAlign: 'center' }} />
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <p className="card-title-desc" style={{ marginBottom: 2, textAlign: 'center', color: 'blue', fontSize: 15, fontFamily: '' }}>{this.props.t("hello")}</p>
                    <p className="card-title-desc" style={{ textAlign: 'center', color: 'blue', fontSize: 15 }}>{this.props.t("createAccountText")}</p>
                    <AvForm onValidSubmit={this.handleValidSubmit} >

                      <Row className="rowAes">
                        <Col>
                          <FormGroup>
                            <Label htmlFor="productdesc">
                              {this.props.t("firstName")}
                            </Label>

                            <AvField
                              style={{ height: 45, width: 300, marginBottom: 20 }}
                              name="FirstName"
                              placeholder={this.props.t("enterFirstName")}
                              type="text"
                              className="form-control"
                              validate={{
                                pattern: { value: /^[a-z ,.'-]+$/i },
                                required: { value: true },
                                maxLength: { value: 10 },
                                // minLength: { value: 3 }
                              }} />

                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup>
                            <Label className="control-label">{this.props.t("lastname")} </Label>
                            <AvField
                              style={{ height: 45, width: 300, marginBottom: 20 }}
                              name="LastName"
                              placeholder={this.props.t("enterLastName")}
                              type="text"
                              className="form-control"
                              validate={{
                                pattern: { value: /^[a-z ,.'-]+$/i },
                                required: { value: true },
                                maxLength: { value: 50 },
                                // minLength: { value: 2 }
                              }} />



                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup>
                            <Label className="control-label">{this.props.t("emailAddress")} </Label>
                            <AvField
                              style={{ height: 45, width: 300, marginBottom: 20 }}
                              name="Email"
                              placeholder={this.props.t("enterEmailAddress")}
                              type="text"
                              className="form-control"
                              validate={{
                                pattern: { value: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" },
                                required: { value: true },
                                maxLength: { value: 50 },
                                // minLength: { value: 2 }
                              }} />



                          </FormGroup>
                        </Col>

                        <Col sm="12">
                          <FormGroup>
                            <Label className="control-label">{this.props.t("password")} </Label>

                            <UncontrolledTooltip
                              // style={{ marginLeft: 100}}
                              placement="bottom"
                              target="Data"
                            >Password: Your password must be at least 7 characters and at most 64 characters and contain letters(upper and lower)  numbers , special characters.
                            </UncontrolledTooltip>


                            <AvField
                              style={{ height: 45, width: 300 }}
                              name="Password"
                              placeholder={this.props.t("enterPassword")}
                              type="password"
                              className="form-control"
                              validate={{
                                pattern: { value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/},
                                required: { value: true },
                                maxLength: { value: 64 },
                                minLength: { value: 7 }
                              }} />

                            <div className="infoSymbol">
                              <i
                                style={{ marginRight: 420, marginBottom: 20 }}
                                className="dripicons-information "
                                id="Data"
                              ></i>
                            </div>

                          </FormGroup>

                        </Col>


                      </Row>

                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          style={{ marginLeft: 220 }}
                          type="submit"
                          disabled={this.state.buttonTimer}
                          color="primary"
                        >
                          {this.props.t("signUp")}
                        </Button>
                      </div>

                    </AvForm>
                  </CardBody>
                </Card>
              </Col>

              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
SubscribeOperation.propTypes = {
  t: PropTypes.any,
  history : PropTypes.any
}
export default (withTranslation()(SubscribeOperation));