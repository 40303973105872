import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
    Alert,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    FormGroup,
    Label,
    Row,
    UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvForm } from "availity-reactstrap-validation";
import "../../pages.scss"

import { getResponse } from "../../../helpers/api_helper"

//i18n
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class CAPublicKey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            caPublicKey: {
                issuerMode: "",
                keyLengthMode: "",
                keyTypeMode: "",
                //ridIndexMode:"",
            },
            respData: [],
            respErrorData: [],
            successAlert: [],
            items: [],
            selectionMode: false,
            buttonTimer: false,
        };

        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.addFailItem = this.addFailItem.bind(this);
        this.addSuccessItem = this.addSuccessItem.bind(this);
        this.handleSelectIssuer = this.handleSelectIssuer.bind(this);
        this.handleSelectKeyLength = this.handleSelectKeyLength.bind(this);
        this.handleSelectKeyType = this.handleSelectKeyType.bind(this);
        // this.handleSelectRIDIndex = this.handleSelectRIDIndex.bind(this)
    }

    handleValidSubmit = (e, values) => {
        this.setState({ buttonTimer: true })
        if (
            this.state.caPublicKey.issuerMode === "" &&
            this.state.caPublicKey.keyLengthMode === "" &&
            this.state.caPublicKey.keyTypeMode === ""
        ) {
            this.setState({ respErrorData: [this.props.t("caPublicKeyRequired")] });
            this.addFailItem();
            this.setState({ buttonTimer: false });
            return;
        }

        const cloudInput = {
            issuer: this.state.caPublicKey.issuerMode,
            keyLength: this.state.caPublicKey.keyLengthMode,
            keyType: this.state.caPublicKey.keyTypeMode,
            // ridIndex: this.state.caPublicKey.ridIndexMode
        }

        getResponse(cloudInput, "/cloud/v1/CA/CAPublicKey").then((resp) => {
            this.setState({ buttonTimer: false });
            if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
                this.setState({ respData: [resp.resultData] });
                const dnm = JSON.parse(localStorage.getItem("jsonHistory"));
                if (dnm != null && dnm !== 0) {
                    this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) });
                    const a = [...this.state.histjson];
                    a.push(resp);
                    localStorage.setItem("jsonHistory", JSON.stringify(a));
                } else {
                    localStorage.setItem("jsonHistory", JSON.stringify([resp]));
                }
                this.addSuccessItem();
            } else {
                this.setState({ respErrorData: [resp.resultDesc] });
                const dnm = JSON.parse(localStorage.getItem("jsonHistory"));
                if (dnm != null && dnm !== 0) {
                    this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) });
                    const a = [...this.state.histjson];
                    a.push(resp);
                    localStorage.setItem("jsonHistory", JSON.stringify(a));
                } else {
                    localStorage.setItem("jsonHistory", JSON.stringify([resp]));
                }
                this.addFailItem();
            }
        });

    };

    // addSuccessItem = () => {
    //     this.state.items.push(<Col key={Date.now()} >
    //         <Card>
    //             <CardBody>
    //                 <Alert className="alert-success" role="alert">
    //                     <h4 className="alert-heading">{this.props.t("caPublicKeyOperationSuccess")}</h4>
    //                     <hr />
    //                     <div className="display-linebreak">{this.state.respData}</div>
    //                 </Alert>
    //             </CardBody>
    //         </Card>
    //     </Col>
    //     )
    //     this.setState({ items: [...this.state.items] })

    // }

    addSuccessItem = () => {
        this.setState({ items: [] });

        this.setState((prevState) => ({
            items: [
                ...prevState.items,
                <Col key={Date.now()}>
                    <Card>
                        <CardBody>
                            <Alert className="alert-success" role="alert">
                                <h4 className="alert-heading">{this.props.t("caPublicKeyOperationSuccess")}</h4>
                                <hr />
                                <div className="display-linebreak">{this.state.respData}</div>
                            </Alert>
                        </CardBody>
                    </Card>
                </Col>,
            ],
        }));
    };


    addFailItem = () => {
        this.setState({ items: [] });
        this.setState((prevState) => ({
            items: [
                ...prevState.items,
                <Col key={Date.now()}>
                    <Card>
                        <CardBody>
                            <Alert className="alert-danger" role="alert">
                                <h4 className="alert-heading">{this.props.t("caPublicKeyOperationFail")}</h4>
                                <hr />
                                <div className="display-linebreak">{this.state.respErrorData}</div>
                            </Alert>
                        </CardBody>
                    </Card>
                </Col>,
            ],
        }));
    };

    // addFailItem = () => {
    //     this.state.items.push(<Col key={Date.now()} >
    //         <Card>
    //             <CardBody>
    //                 <Alert className="alert-danger" role="alert">
    //                     <h4 className="alert-heading">{this.props.t("caPublicKeyOperationFail")}</h4>
    //                     <hr />
    //                     <div className="display-linebreak">{this.state.respErrorData}</div>
    //                 </Alert>
    //             </CardBody>
    //         </Card>
    //     </Col>
    //     );
    //     this.setState({ items: [...this.state.items] })
    // }
    dataChange(event) {
        const count = event.target.value.length
        if (count > 0) {
            this.setState({ disthresh2: true })
        } else {
            this.setState({ disthresh2: false })
        }
        this.setState({ dataChange: event.target.value.length })
    }

    handleSelectIssuer(e) {
        this.setState({
            caPublicKey: {
                ...this.state.caPublicKey,
                issuerMode: e.value,
            },
            selectionMode: true,
        });
    }

    handleSelectKeyLength(e) {
        this.setState({
            caPublicKey: {
                ...this.state.caPublicKey,
                keyLengthMode: e.value,
            },
        });
    }

    handleSelectKeyType(e) {
        this.setState({
            caPublicKey: {
                ...this.state.caPublicKey,
                keyTypeMode: e.value,
            },
        });
    }

    // handleSelectRIDIndex(e) {
    //     this.setState({
    //         caPublicKey: ({
    //             ...this.state.caPublicKey,
    //             ridIndexMode: e.value
    //         })
    //     })
    // }

    render() {
        const issuer = [
            { value: "", label: this.props.t("none") },
            { value: "AMEX", label: this.props.t("amex") },
            { value: "Associazione Bancaria Italiana", label: this.props.t("associazioneBancariaItaliana") },
            { value: "Australian Payments Clearing Association Ltd", label: this.props.t("australianPaymentsClearingAssociationLtd") },
            { value: "China Unionpay Co. Ltd", label: this.props.t("chinaUnionpayCoLtd") },
            { value: "EuroPay", label: this.props.t("euroPay") },
            { value: "JCB", label: this.props.t("jcb") },
            { value: "LINK Interchange Network Ltd", label: this.props.t("linkInterchangeNetworkLtd") },
            { value: "Mastercard", label: this.props.t("mastercard") },
            { value: "VISA", label: this.props.t("visa") },
            { value: "RuPay", label: this.props.t("ruPay") },
            { value: "CB", label: this.props.t("cb") },
            { value: "BCard", label: this.props.t("bCard") },
            { value: "Multibanco", label: this.props.t("multibanco") },
            { value: "Saudi Arabian Monetary Agency (SAMA)", label: this.props.t("saudiArabianMonetaryAgencySAMA") },
            { value: "Interac Association", label: this.props.t("interacAssociation") },
            { value: "Currence Holding/PIN BV", label: this.props.t("currenceHoldingPINBV") },
            { value: "Poste Italiane S.P.A", label: this.props.t("posteItalianeSPA") },
            { value: "Unibanco (Hipercard)", label: this.props.t("unibancoHipercard") }
        ];

        const keyLength = [
            { value: "", label: this.props.t("none") },
            { value: "512", label: "512" },
            { value: "768", label: "768" },
            { value: "896", label: "896" },
            { value: "1024", label: "1024" },
            { value: "1152", label: "1152" },
            { value: "1280", label: "1280" },
            { value: "1408", label: "1408" },
            { value: "1536", label: "1536" },
            { value: "1600", label: "1600" },
            { value: "1792", label: "1792" },
            { value: "1984", label: "1984" },
            { value: "2048", label: "2048" }
        ];

        const keyType = [
            { value: "", label: this.props.t("none") },
            { value: "Live", label: this.props.t("live") },
            { value: "Test", label: this.props.t("test") }
        ]
        // const ridIndex = [
        //     { value: "", label: this.props.t("none") },
        //     { value: "00", label: "00" },
        //     { value: "01", label: "01" },
        //     { value: "02", label: "02" },
        //     { value: "03", label: "03" },
        //     { value: "04", label: "04" },
        //     { value: "05", label: "05" },
        //     { value: "06", label: "06" },
        //     { value: "07", label: "07" },
        //     { value: "08", label: "08" },
        //     { value: "09", label: "09" },
        //     { value: "10", label: "10" },
        //     { value: "11", label: "11" },
        //     { value: "12", label: "12" },
        //     { value: "13", label: "13" },
        //     { value: "14", label: "14" },
        //     { value: "16", label: "16" },
        //     { value: "18", label: "18" },
        //     { value: "20", label: "20" },
        //     { value: "22", label: "22" },
        //     { value: "23", label: "23" },
        //     { value: "40", label: "40" },
        //     { value: "41", label: "41" },
        //     { value: "50", label: "50" },
        //     { value: "51", label: "51" },
        //     { value: "52", label: "52" },
        //     { value: "53", label: "53" },
        //     { value: "58", label: "58" },
        //     { value: "60", label: "60" },
        //     { value: "62", label: "62" },
        //     { value: "64", label: "64" },
        //     { value: "65", label: "65" },
        //     { value: "66", label: "66" },
        //     { value: "67", label: "67" },
        //     { value: "68", label: "68" },
        //     { value: "80", label: "80" },
        //     { value: "82", label: "82" },
        //     { value: "83", label: "83" },
        //     { value: "84", label: "84" },
        //     { value: "85", label: "85" },
        //     { value: "86", label: "86" },
        //     { value: "89", label: "89" },
        //     { value: "90", label: "90" },
        //     { value: "92", label: "92" },
        //     { value: "94", label: "94" },
        //     { value: "95", label: "95" },
        //     { value: "96", label: "96" },
        //     { value: "97", label: "97" },
        //     { value: "98", label: "98" },
        //     { value: "99", label: "99" },
        //     { value: "0A", label: "0A" },
        //     { value: "0B", label: "0B" },
        //     { value: "0C", label: "0C" },
        //     { value: "0D", label: "0D" },
        //     { value: "0E", label: "0E" },
        //     { value: "0F", label: "0F" },
        //     { value: "6A", label: "6A" },
        //     { value: "6B", label: "6B" },
        //     { value: "6C", label: "6C" },
        //     { value: "6D", label: "6D" },
        //     { value: "A1", label: "A1" },
        //     { value: "A2", label: "A2" },
        //     { value: "C1", label: "C1" },
        //     { value: "C2", label: "C2" },
        //     { value: "C3", label: "C3" },
        //     { value: "C4", label: "C4" },
        //     { value: "C5", label: "C5" },
        //     { value: "C7", label: "C7" },
        //     { value: "C8", label: "C8" },
        //     { value: "C9", label: "C9" },
        //     { value: "CA", label: "CA" },
        //     { value: "E0", label: "E0" },
        //     { value: "E1", label: "E1" },
        //     { value: "E2", label: "E2" },
        //     { value: "EF", label: "EF" },
        //     { value: "F0", label: "F0" },
        //     { value: "F1", label: "F1" },
        //     { value: "F2", label: "F2" },
        //     { value: "F3", label: "F3" },
        //     { value: "F4", label: "F4" },
        //     { value: "F5", label: "F5" },
        //     { value: "F6", label: "F6" },
        //     { value: "F7", label: "F7" },
        //     { value: "F8", label: "F8" },
        //     { value: "F9", label: "F9" },
        //     { value: "FA", label: "FA" },
        //     { value: "FB", label: "FB" },
        //     { value: "FC", label: "FC" },
        //     { value: "FD", label: "FD" },
        //     { value: "FE", label: "FE" },
        //     { value: "FF", label: "FF" },
        //   ];

        const defaultValues = {}
        return (
            <React.Fragment>
                <div className="page-content">
                    <Helmet>
                        <MetaTags>
                            <title>Wechip - Wechip Payment Tools| {this.props.t("ca")}</title>
                            <meta name="description" content="CA (Certificate Authority) Public Key is a cryptographic key used in SSL/TLS certificates to verify the authenticity of a website or server. Learn about CA Public Key infrastructure, certificate issuance, and its role in establishing secure communication over the internet." />
                            <meta name="keywords" content="CA Public Key, Certificate Authority Public Key, SSL Certificate, TLS Certificate, Issuer, Cryptographic Key, Public Key Infrastructure, Certificate Issuance, Secure Communication, Internet Security, Authentication, Encryption" />
                        </MetaTags>
                    </Helmet>

                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs title={this.props.t("ca")} breadcrumbItem={this.props.t("caPublicKey")} />
                        <Row>
                            <Col md={6}>
                                <Card>
                                    <CardBody>
                                        <AvForm onValidSubmit={this.handleValidSubmit} model={defaultValues}>
                                            <Row className="rowCa">
                                                <Col>
                                                    <FormGroup className="select2-container">
                                                        <Label className="control-label">{this.props.t("issuer")}</Label>
                                                        <UncontrolledTooltip
                                                            placement="bottom"
                                                            target="Issuer"
                                                        >
                                                            {this.props.t("issuerInfo")}
                                                        </UncontrolledTooltip>
                                                        <Select
                                                            defaultValue={issuer[0]}
                                                            options={issuer}
                                                            onChange={this.handleSelectIssuer}

                                                        />
                                                        <div className="infoSymbol">
                                                            <i
                                                                className="dripicons-information "
                                                                id="Issuer"
                                                            ></i></div>
                                                    </FormGroup>
                                                </Col>

                                            </Row>

                                            <Row className="rowCa">
                                                <Col>
                                                    <FormGroup className="select2-container">
                                                        <Label className="control-label">{this.props.t("keyLength")}</Label>
                                                        <UncontrolledTooltip
                                                            placement="bottom"
                                                            target="keyLength"
                                                        >
                                                            {this.props.t("caKeyLengthInfo")}
                                                        </UncontrolledTooltip>
                                                        <Select
                                                            defaultValue={keyLength[0]}
                                                            options={keyLength}
                                                            onChange={this.handleSelectKeyLength}
                                                        />
                                                        <div className="infoSymbol">
                                                            <i
                                                                className="dripicons-information "
                                                                id="keyLength"
                                                            ></i></div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row className="rowCa">
                                                <Col>
                                                    <FormGroup className="select2-container">
                                                        <Label className="control-label">{this.props.t("keyType")}</Label>
                                                        <UncontrolledTooltip
                                                            placement="bottom"
                                                            target="keyType"
                                                        >
                                                            {this.props.t("keyTypeInfo")}
                                                        </UncontrolledTooltip>
                                                        <Select
                                                            defaultValue={keyType[0]}
                                                            options={keyType}
                                                            onChange={this.handleSelectKeyType}
                                                        />
                                                        <div className="infoSymbol">
                                                            <i
                                                                className="dripicons-information "
                                                                id="keyType"
                                                            ></i></div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row className="rowCa">
                                                <Col>
                                                    {/* <FormGroup className="select2-container">
                                                        <Label className="control-label">{this.props.t("ridIndex")}</Label>
                                                        <UncontrolledTooltip
                                                            placement="bottom"
                                                            target="ridIndex"
                                                        >
                                                            {this.props.t("ridIndexInfo")}
                                                        </UncontrolledTooltip>
                                                        <Select
                                                            defaultValue={ridIndex[0]}
                                                            options={ridIndex}
                                                            onChange={this.handleSelectRIDIndex}
                                                        />
                                                        <div className="infoSymbol">
                                                            <i
                                                                className="dripicons-information "
                                                                id="ridIndex"
                                                            ></i></div>
                                                    </FormGroup> */}
                                                </Col>

                                            </Row>


                                            <div className="d-flex flex-wrap gap-2">
                                                <Button
                                                    color="secondary"
                                                    onClick={() => window.location.reload()}
                                                    style={{ textAlign: "start" }}
                                                    className="buttonDecode"
                                                >
                                                    {this.props.t("clearButton")}
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    onClick={() => this.setState({ isEncrypt: true })}
                                                    disabled={this.state.buttonTimer}
                                                >
                                                    {this.props.t("searchButton")}
                                                </Button>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="6" >
                                <Card className="scroll-col">
                                    {this.state.items.slice().reverse()}
                                </Card>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

CAPublicKey.propTypes = {
    t: PropTypes.any,
}

export default (withTranslation()(CAPublicKey));
