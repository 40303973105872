import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from "prop-types";

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import "../../pages.scss"

import { getResponse } from "../../../helpers/api_helper"

//i18n
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class DESEncrypt extends Component {
  constructor(props) {
    super(props)
    this.state = {
      desEncrypts: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      disthresh1: false,
      disthresh2: false,
      disthresh3: false,
      ivDsb: false,
      dataDsb: false,
      dataInputDsb: false,
      isEncrypt: false,
      decodeDsb: false,
      buttonTimer: false,
      defValue: 0,
      desCont: false,
      dataInputTypeSelect: "2"
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.ivChange = this.ivChange.bind(this);
    this.keyChange = this.keyChange.bind(this)
    this.dataChange = this.dataChange.bind(this)
    this.handleSelectPadding = this.handleSelectPadding.bind(this)
    this.sendApi = this.sendApi.bind(this)
    this.handleSelectInputType = this.handleSelectInputType.bind(this)
  }

  handleSelectInputType(e) {
    this.setState({ dataInputTypeSelect: e.target.value })
  }

  handleSelectPadding(e) {
    this.setState({
      desEncrypts: ({
        ...this.state.desEncrypts,
        padding: e.value
      })
    })
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    if (this.state.desEncrypts.padding == null || this.state.desEncrypts.padding == "")
      this.setState({
        desEncrypts: ({
          ...this.state.desEncrypts,
          padding: "1"
        })
      })

    if (this.state.isEncrypt == true) {
      if (this.state.desCont == true) {
        const cloudInput = {
          key: values.key,
          data: values.data,
          paddingMode: this.state.desEncrypts.padding,
          dataInputType: values.dataInput,
        };

        getResponse(cloudInput, "/cloud/v1/Des/Encode")
          .then((resp) => {
            this.setState({ buttonTimer: false })
            if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
              this.setState({ respData: [resp.resultData] })
              const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
              if (dnm != null && dnm != 0) {
                this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
                const a = [...this.state.histjson]
                a.push(resp)
                localStorage.setItem("jsonHistory", JSON.stringify(a));
              } else {
                localStorage.setItem("jsonHistory", JSON.stringify([resp]));
              }
              this.addSuccessItem();
            }
            else {
              this.setState({ respErrorData: [resp.resultDesc] })
              const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
              if (dnm != null && dnm != 0) {
                this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
                const a = [...this.state.histjson]
                a.push(resp)
                localStorage.setItem("jsonHistory", JSON.stringify(a));
              } else {
                localStorage.setItem("jsonHistory", JSON.stringify([resp]));
              }
              this.addFailItem();
            }
          })
      }
      else {
        const cloudInput = {
          key: values.key,
          iv: values.iv,
          data: values.data,
          paddingMode: this.state.desEncrypts.padding,
          cipherMode: values.mode,
          dataInputType: values.dataInput,
        };

        getResponse(cloudInput, "/cloud/v1/TDes/TDesEncrypt")
          .then((resp) => {
            this.setState({ buttonTimer: false })
            if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
              this.setState({ respData: [resp.resultData] })
              const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
              if (dnm != null && dnm != 0) {
                this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
                const a = [...this.state.histjson]
                a.push(resp)
                localStorage.setItem("jsonHistory", JSON.stringify(a));
              } else {
                localStorage.setItem("jsonHistory", JSON.stringify([resp]));
              }
              this.addSuccessItem();
            }
            else {
              this.setState({ respErrorData: [resp.resultDesc] })
              const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
              if (dnm != null && dnm != 0) {
                this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
                const a = [...this.state.histjson]
                a.push(resp)
                localStorage.setItem("jsonHistory", JSON.stringify(a));
              } else {
                localStorage.setItem("jsonHistory", JSON.stringify([resp]));
              }
              this.addFailItem();
            }
          })
      }
    }
    if (this.state.isEncrypt == false) {
      if (this.state.desCont == true) {
        const cloudInput = {
          key: values.key,
          data: values.data,
          paddingMode: this.state.desEncrypts.padding,
          dataInputType: values.dataInput,
        };

        getResponse(cloudInput, "/cloud/v1/Des/Decode")
          .then((resp) => {
            this.setState({ buttonTimer: false })
            if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
              this.setState({ respData: [resp.resultData] })
              const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
              if (dnm != null && dnm != 0) {
                this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
                const a = [...this.state.histjson]
                a.push(resp)
                localStorage.setItem("jsonHistory", JSON.stringify(a));
              } else {
                localStorage.setItem("jsonHistory", JSON.stringify([resp]));
              }
              this.addSuccessItem();
            }
            else {
              this.setState({ respErrorData: [resp.resultDesc] })
              const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
              if (dnm != null && dnm != 0) {
                this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
                const a = [...this.state.histjson]
                a.push(resp)
                localStorage.setItem("jsonHistory", JSON.stringify(a));
              } else {
                localStorage.setItem("jsonHistory", JSON.stringify([resp]));
              }
              this.addFailItem();
            }
          })
      }
      else {
        const cloudInput = {
          key: values.key,
          iv: values.iv,
          data: values.data,
          paddingMode: this.state.desEncrypts.padding,
          cipherMode: values.mode,
          dataInputType: values.dataInput,
        };

        getResponse(cloudInput, "/cloud/v1/TDes/TDesDecrypt")
          .then((resp) => {
            this.setState({ buttonTimer: false })
            if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
              this.setState({ respData: [resp.resultData] })
              const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
              if (dnm != null && dnm != 0) {
                this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
                const a = [...this.state.histjson]
                a.push(resp)
                localStorage.setItem("jsonHistory", JSON.stringify(a));
              } else {
                localStorage.setItem("jsonHistory", JSON.stringify([resp]));
              }
              this.addSuccessItem();
            }
            else {
              this.setState({ respErrorData: [resp.resultDesc] })
              const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
              if (dnm != null && dnm != 0) {
                this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
                const a = [...this.state.histjson]
                a.push(resp)
                localStorage.setItem("jsonHistory", JSON.stringify(a));
              } else {
                localStorage.setItem("jsonHistory", JSON.stringify([resp]));
              }
              this.addFailItem();
            }
          })
      }
    }
  }


  addSuccessItem = () => {


    this.setState({ items: [] });


    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("desTDESOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-success" role="alert">
    //         <h4 className="alert-heading">{this.props.t("desTDESOperationSuccess")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // )
    // this.setState({ items: [...this.state.items] })

  }
  addFailItem = () => {

    this.setState({ items: [] });


    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("desTDESOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-danger" role="alert">
    //         <h4 className="alert-heading">{this.props.t("desTDESOperationFail")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respErrorData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // );
    // this.setState({ items: [...this.state.items] })
  }
  keyChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ keyChange: event.target.value.length })
  }

  dataChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh2: true })
    } else {
      this.setState({ disthresh2: false })
    }
    this.setState({ dataChange: event.target.value.length })
  }

  ivChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh3: true })
    } else {
      this.setState({ disthresh3: false })
    }
    this.setState({ ivChange: event.target.value.length })
  }
  sendApi() {
    this.setState({ buttonTimer: true });
    setTimeout(() => {
      this.setState({
        buttonTimer: false
      });
    }, 1000);
  }

  render() {
    const padding = [
      { value: "1", label: "None" },
      // { value: "2", label: "PKCS#7" },
      { value: "3", label: "Zeros" },
      // { value: "4", label: "ANSI X9.23" },
      // { value: "5", label: "ISO 10126" },
    ]
    const defaultValues = { algorithm: "3DES", iv: "0000000000000000", mode: "1" }

    const paddingNone = [
      { value: "1", label: "None" },
    ]

    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools| {this.props.t("des")}</title>
              <meta name="description" content="DES encryption (Data Encryption Standard) is a symmetric key algorithm used for encrypting and decrypting data. Learn about the principles of DES encryption, its usage in securing sensitive information, and its role in data protection." />
              <meta name="keywords" content="DES encryption, Data Encryption Standard, symmetric encryption, encryption algorithm, data security, cryptography, data protection, encryption techniques" />
            </MetaTags>
          </Helmet>

          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("des")} breadcrumbItem={this.props.t("desEncrypt")} />
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit} model={defaultValues}>
                      <Row className="rowAes">
                        <Col>
                          <FormGroup>
                            <AvRadioGroup inline name="algorithm" required errorMessage={this.props.t("pickOne")}>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="Algorithm"
                              >
                                {this.props.t("cipherAlgorithmInfo")}
                              </UncontrolledTooltip>
                              <h6>{this.props.t("algorithm")}</h6>
                              <AvRadio label={this.props.t("des")} value="DES"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ ivDsb: true, modeDsb: true, desCont: true }) } }}
                              />
                              <AvRadio label={this.props.t("3DES")} value="3DES"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ ivDsb: false, modeDsb: false, desCont: false }) } }}
                              />
                            </AvRadioGroup>
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Algorithm"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <AvRadioGroup inline name="mode" errorMessage={this.props.t("pickOne")}
                              required={this.state.modeDsb ? false : true}
                              disabled={this.state.modeDsb ? true : false}>

                              <UncontrolledTooltip
                                placement="bottom"
                                target="Mode"
                              >
                                {this.props.t("cipherModeInfo")}
                              </UncontrolledTooltip>
                              <h6>{this.props.t("mode")}</h6>
                              <AvRadio label="ECB" value="2"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ ivDsb: true }) } }}
                              />
                              <AvRadio label="CBC" value="1"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ ivDsb: false }) } }}
                              />
                              {/* <AvRadio label="CFB" value="4"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ ivDsb: false }) } }}
                              />
                              <AvRadio label="OFB" value="3"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ ivDsb: false }) } }}
                              />
                              <AvRadio label="CTS" value="5"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ ivDsb: false }) } }}
                              /> */}
                            </AvRadioGroup>
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Mode"
                              ></i></div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className="select2-container">
                            <Label className="control-label">{this.props.t("padding")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Padding"
                            >
                              {this.props.t("cipherPaddingInfo")}
                            </UncontrolledTooltip>
                            <Select
                              defaultValue={padding[0]}
                              options={this.state.desCont == false ? padding : paddingNone}
                              onChange={this.handleSelectPadding}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Padding"
                              ></i></div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("key")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Key"
                            >
                              {this.props.t("cipherKeyInfo")}
                            </UncontrolledTooltip>
                            {/* <AvField
                              name="key"
                              placeholder={this.props.t("fill")}
                              type="input"
                              value={this.state.desEncrypts
                                ? this.state.desEncrypts.key : ""}
                              onChange={this.keyChange}
                              validate={{
                                required: { value: true },
                                maxLength: {value: 48},
                                minLength: {value: 32}
                              }}
                            /> */}

                            <AvField
                              name="key"
                              placeholder={this.props.t("fill")}
                              type="input"
                              value={this.state.desEncrypts ? this.state.desEncrypts.key : ""}
                              onChange={this.keyChange}
                              validate={
                                this.state.desCont // DES
                                  ? {
                                    required: { value: true },
                                    minLength: { value: 16 },
                                    maxLength: { value: 16 },
                                  }
                                  : {   //3DES
                                    required: { value: true },
                                    minLength: { value: 32 },
                                    maxLength: { value: 48 },
                                  }
                              }
                            />

                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.keyChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Key"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="DataInput"
                            >
                              {this.props.t("cipherDataInputInfo")}
                            </UncontrolledTooltip>
                            <h6>{this.props.t("dataInput")}</h6>
                            <AvRadioGroup inline name="dataInput" errorMessage={this.props.t("pickOne")}

                              onChange={(e) => { if (e.target.checked == true) { this.setState({ dataInputTypeSelect: e.target.value }) } }} required
                            // disabled={this.state.dataInputDsb ? true : false}
                            >
                              <AvRadio label={this.props.t("ascii")} value="1" />
                              <AvRadio label={this.props.t("hexadecimal")} value="2" />
                            </AvRadioGroup>
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="DataInput"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="productdesc">
                              {this.props.t("data")}
                            </Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Data"
                            >
                              {this.props.t("cipherDataInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              style={{ height: 100 }}
                              name="data"
                              placeholder={this.props.t("fill")}
                              type="textarea"
                              value={this.state.desEncrypts
                                ? this.state.desEncrypts.data : ""}
                              className="form-control"
                              onChange={this.dataChange}
                              validate={this.state.dataInputTypeSelect === "2" ? {
                                pattern: { value: /^[A-Fa-f0-9]+$/ },
                                required: { value: true }
                              } : {
                                pattern: { value: /^[a-z0-9]+$/ },
                                required: { value: true },
                                maxLength: { value: 8 }
                              }
                              }
                            />
                            {this.state.disthresh2 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.dataChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Data"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup mb="2">
                            <Label htmlFor="productname">{this.props.t("iv")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="IV"
                            >
                              {this.props.t("cipherIVInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="iv"
                              placeholder={this.props.t("fill")}
                              type="input"
                              value={this.state.desEncrypts
                                ? this.state.desEncrypts.iv : ""}
                              onChange={this.ivChange}
                              // disabled={this.state.ivDsb ? true : false}
                              disabled={true}
                              validate={
                                this.state.ivDsb ? {
                                  required: { value: false }
                                } : {
                                  required: { value: true }
                                }}
                            />
                            {this.state.disthresh3 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.ivChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="IV"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="secondary"
                          onClick={() => window.location.reload()}
                          style={{ textAlign: "start" }}
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          onClick={() => this.setState({ isEncrypt: true })}
                          disabled={this.state.buttonTimer}
                        >
                          {this.props.t("encodeButton")}
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          onClick={() => this.setState({ isEncrypt: false })}
                          disabled={this.state.buttonTimer}
                        >
                          {this.props.t("decodeButton")}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

DESEncrypt.propTypes = {
  t: PropTypes.any,
}

export default (withTranslation()(DESEncrypt));
