import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "../../pages.scss"
import { getResponse } from "../../../helpers/api_helper"

//i18n
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class PinBlockGDecode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pinBlockGDecodes: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      disthresh1: false,
      disthresh2: false,
      disthresh3: false,
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleSelectPaddingCharacter = this.handleSelectPaddingCharacter.bind(this)
    this.handleSelectPINBlockFormat = this.handleSelectPINBlockFormat.bind(this)
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.panChange = this.panChange.bind(this);
    this.pinChange = this.pinChange.bind(this)
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    if (this.state.pinBlockGDecodes.pinBlockFormat == null || this.state.pinBlockGDecodes.pinBlockFormat == "")
      this.setState({
        pinBlockGDecodes: ({
          ...this.state.pinBlockGDecodes,
          pinBlockFormat: "ISO-0"
        })
      })
    if (this.state.pinBlockGDecodes.paddingCharacter == null || this.state.pinBlockGDecodes.paddingCharacter == "")
      this.setState({
        pinBlockGDecodes: ({
          ...this.state.pinBlockGDecodes,
          paddingCharacter: "0"
        })
      })

    const { pinBlockGDecodes } = this.state

    const cloudInput = {
      pan: values.pan,
      clearPin: values.pin,
      pinBlockFormat: pinBlockGDecodes.pinBlockFormat,
      paddingCharacter: pinBlockGDecodes.paddingCharacter,
    };

    getResponse(cloudInput, "/cloud/v1/PinBlockGeneral/Decode")
      .then((resp) => {
        this.setState({ buttonTimer: false })
        if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
          this.setState({ respData: [resp.resultData] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addSuccessItem();
        }
        else {
          this.setState({ respErrorData: [resp.resultDesc] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addFailItem();
        }
      })

  }


  handleSelectPINBlockFormat(e) {
    this.setState({
      pinBlockGDecodes: ({
        ...this.state.pinBlockGDecodes,
        pinBlockFormat: e.value
      })
    })
  }
  handleSelectPaddingCharacter(e) {
    this.setState({
      pinBlockGDecodes: ({
        ...this.state.pinBlockGDecodes,
        paddingCharacter: e.value
      })
    })
  }

  addSuccessItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("pinBlockOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-success" role="alert">
    //         <h4 className="alert-heading">{this.props.t("pinBlockOperationSuccess")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // )
    // this.setState({ items: [...this.state.items] })

  }
  addFailItem = () => {

    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("pinBlockOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-danger" role="alert">
    //         <h4 className="alert-heading">{this.props.t("pinBlockOperationFail")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respErrorData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // );
    // this.setState({ items: [...this.state.items] })
  }
  panChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ panChange: event.target.value.length })
  }

  pinChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh2: true })
    } else {
      this.setState({ disthresh2: false })
    }
    this.setState({ pinChange: event.target.value.length })
  }

  render() {
    const pinBlockFormat = [
      { value: "ISO-0", label: this.props.t("format0ISO0") },
      { value: "ISO-1", label: this.props.t("format1ISO1") },
      { value: "ISO-2", label: this.props.t("format2ISO2") },
      // { value: "ISO-3", label: this.props.t("format3ISO3") },
      // { value: "ISO-4", label: this.props.t("format4ISO4") },
      // { value: "ANSI X9.8", label: this.props.t("ansiX98") },
      // { value: "OEM-1", label: this.props.t("oem1DocutelDieboldNCRATMs") },
      // { value: "ECI-1", label: this.props.t("eci1") },
      // { value: "ECI-2", label: this.props.t("eci2") },
      // { value: "ECI-3", label: this.props.t("eci3") },
      // { value: "ECI-4", label: this.props.t("eci4") },
      // { value: "IBM 3621", label: this.props.t("ibm3621") },
      // { value: "IBM 3624", label: this.props.t("ibm3624") },
      // { value: "IBM 4704", label: this.props.t("ibm4704EncrPINPad") },
      // { value: "IBM 5906", label: this.props.t("ibm5906") },
      // { value: "VISA-1", label: this.props.t("visa1") },
      // { value: "VISA-2", label: this.props.t("visa2") },
      // { value: "VISA-3", label: this.props.t("visa3") },
      // { value: "VISA-4", label: this.props.t("visa4") },
      // { value: "Europay/Mastercard (Pay Noew & Pay Later)", label: "Europay/Mastercard (Pay Noew & Pay Later)" },

    ]
    const paddingCharacter = [
      { value: "0", label: "0" },
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
      { value: "8", label: "8" },
      { value: "9", label: "9" },
      { value: "A", label: "A" },
      { value: "B", label: "B" },
      { value: "C", label: "C" },
      { value: "D", label: "D" },
      { value: "E", label: "E" },
      { value: "F", label: "F" },

    ]
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | {this.props.t("pinBlocks")}</title>
              <meta name="description" content="Pin Block General Decode is a process used to decrypt PIN blocks, which are encrypted representations of Personal Identification Numbers (PINs) used in financial transactions. Explore the principles and techniques behind the general decoding of PIN blocks, their role in verifying PIN authenticity, and their significance in ensuring secure and reliable electronic payment systems." />
              <meta name="keywords" content="Pin Block General Decode, PIN block decryption, PIN security, financial transactions, encryption decoding, PIN authenticity, electronic payment systems, data decryption" />
            </MetaTags>
          </Helmet>
          
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("pinBlocks")} breadcrumbItem={this.props.t("decode")} />
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit}>
                      <Row>
                        <Col sm="12">
                          <FormGroup className="select2-container">
                            <Label className="control-label">{this.props.t("pinBlockFormat")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="PINBlockFormat"
                            >
                              {this.props.t("pinBlockFormatInfo")}
                            </UncontrolledTooltip>
                            <Select
                              defaultValue={pinBlockFormat[0]}
                              options={pinBlockFormat}
                              onChange={this.handleSelectPINBlockFormat}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="PINBlockFormat"
                              ></i></div>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup >
                            <Label htmlFor="productname">{this.props.t("clearPINBlock")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="PIN"
                            >
                              {this.props.t("clearPINBlockInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="pin"
                              placeholder={this.props.t("fill")}
                              type="input"
                              value={this.state.pinBlockGDecodes
                                ? this.state.pinBlockGDecodes.pin : ""}
                              onChange={this.pinChange}
                              validate={this.state.pinBlockGDecodes.pinBlockFormat == "ISO-4" ? {
                                pattern: { value: /^[A-Fa-f0-9]+$/, errorMessage: "Data should be HEX data format" },
                                minLength: { value: 32, errorMessage: "PIN Block has " + this.state.pinChange + " characters, its size has to be 32 characters." },
                                maxLength: { value: 32, errorMessage: "PIN Block has " + this.state.pinChange + " characters, its size has to be 32 characters." },
                                required: { value: true }
                              } : {
                                pattern: { value: /^[A-Fa-f0-9]+$/, errorMessage: "Data should be HEX data format" },
                                minLength: { value: 16, errorMessage: "PIN Block has " + this.state.pinChange + " characters, its size has to be 16 characters." },
                                maxLength: { value: 16, errorMessage: "PIN Block has " + this.state.pinChange + " characters, its size has to be 16 characters." },
                                required: { value: true }
                              }}
                            />
                            {this.state.disthresh2 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.pinChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="PIN"
                              ></i></div>
                          </FormGroup>
                        </Col>

                        <Col sm="12">
                          <FormGroup>
                            <Label htmlFor="productname">{this.state.pinBlockGDecodes.pinBlockFormat == "ISO-4" ? this.props.t("clearPANBlock") : this.props.t("pan")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="PAN"
                            >
                              {this.props.t("clearPANBlockInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="pan"
                              type="input"
                              placeholder={this.props.t("fill")}
                              value={this.state.pinBlockGDecodes
                                ? this.state.pinBlockGDecodes.pan : ""}
                              onChange={this.panChange}
                              validate={this.state.pinBlockGDecodes.pinBlockFormat == "ISO-4" ? {
                                minLength: { value: 31, errorMessage: "PAN has " + this.state.panChange + " characters, its size has to be 32 characters." },
                                maxLength: { value: 32, errorMessage: "PAN has " + this.state.panChange + " characters, its size has to be 32 characters." },
                                required: { value: true }
                              } : {
                                minLength: { value: 13, errorMessage: "PAN has " + this.state.panChange + " characters, its size has to be between 13 and 19 characters." },
                                maxLength: { value: 19, errorMessage: "PAN has " + this.state.panChange + " characters, its size has to be between 13 and 19 characters." },
                                required: { value: true }
                              }}
                            />
                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.panChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="PAN"
                              ></i></div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup className="select2-container">
                            <Label className="control-label">{this.props.t("paddingCharacter")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="PaddingCharacter"
                            >
                              {this.props.t("paddingCharacterInfo")}
                            </UncontrolledTooltip>
                            <Select
                              defaultValue={paddingCharacter[0]}
                              options={paddingCharacter}
                              onChange={this.handleSelectPaddingCharacter}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="PaddingCharacter"
                              ></i></div>
                          </FormGroup>
                        </Col>

                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="secondary"
                          onClick={() => window.location.reload()}
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          disabled={this.state.buttonTimer}
                          color="primary"
                        >
                          {this.props.t("decodeButton")}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

PinBlockGDecode.propTypes = {
  t: PropTypes.any,
}

export default (withTranslation()(PinBlockGDecode));
