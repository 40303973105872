import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom";
import qrcode from "qrcode.react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

//Verification code package
import AuthCode from "react-auth-code-input"
import { apiError, verify2fLogin } from "../../store/actions";

import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

class TwostepVerification extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sixDigit:""
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    this.props.apiError("");
  }

  handleValidSubmit(event, values) {
    const verify = {
      verificationCode: this.state.sixDigit,
      twoFactorSecret: "",
    };
    this.props.verify2fLogin(verify, this.props.history);
  }

  handleOnChange = (res) => {
    this.setState({sixDigit:res});
  };

  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <MetaTags>
          <title>{this.props.t("twoStepVerification")} | Wechip Payment Tools</title>
          </MetaTags>
          <Container>
            
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card>
                  <CardBody>
                    <div className="p-2">
                      <div className="text-center">
                        <div className="avatar-md mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            <i className="bx bxs-key h1 mb-0 text-primary"></i>
                          </div>
                        </div>
                        <div className="p-2 mt-4">
                        <br />
                          <br />
                          <p className="mb-5">
                            <span className="font-weight-semibold">
                              {this.props.t("pleaseEnterThe6DigitCodeSentTo")}{" "}
                            </span>
                          </p>

                          <Form>
                            <Row>
                              <Col xs={12}>
                                <FormGroup className="verification">
                                  <label
                                    htmlFor="digit1-input"
                                    className="sr-only"
                                  >
                                    {this.props.t("digit1")}
                                  </label>
                                  <AuthCode
                                    characters={6}
                                    onChange={this.handleOnChange}
                                    name="sixDigit"
                                    className="form-control form-control-lg text-center"
                                    allowedCharacters="^[0-9]"
                                    value={this.state.sixDigit}
                                    inputStyle={{
                                      width: "40px",
                                      height: "42px",
                                      padding: "8px",
                                      borderRadius: "8px",
                                      fontSize: "16px",
                                      textAlign: "center",
                                      marginRight: "15px",
                                      border: "1px solid #ced4da",
                                      textTransform: "uppercase",
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>

                          <div className="mt-4">
                          <button
                            className="btn btn-primary btn-block"
                            onClick={this.handleValidSubmit}
                          >
                            {this.props.t("confirmButton")}
                          </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  
                  <p>
                    © {new Date().getFullYear()}  Wechip 
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

TwostepVerification.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  verify2fLogin: PropTypes.func,
  t: PropTypes.any
};


 
const mapStateToProps = state => {
  const { error } = state;
  return { error };
};
 
export default withRouter(
  connect(mapStateToProps, { verify2fLogin, apiError })(withTranslation()(TwostepVerification))
);
 
