//LOGIN
export const POST_FAKE_LOGIN = "/Authentication/Login"
export const POST_2F_VERIFY = "/Authentication/Login2f"
export const POST_2F_LOGIN = "/Authentication/VerifyTwoFactorSecret"

//TKBEncode

export const TKBENCODES = "/ThalesKeyblock/Encode"

//TKBDecode

export const TKBDECODES = "/ThalesKeyblock/Decode"

// TEST

export const GET_TEST_DEFINITIONS = "/testdefinition"
export const ADD_NEW_TEST_DEFINITION = "/add/testdefinition"
export const UPDATE_TEST_DEFINITION = "/update/testdefinition"
export const DELETE_TEST_DEFINITION = "/delete/testdefinition"

// MEMBER DEFINITION

export const MEMBER_DEFINITIONS = ""

// PAGE DEFINITION

export const PAGE_DEFINITIONS = ""

// ROLE DEFINITION 

export const ROLE_DEFINITIONS = ""
 
// USER DEFINITION

export const USER_DEFINITIONS = ""