import PropTypes from "prop-types";
import React, { Component } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.refDiv = React.createRef();
  }

  componentDidMount() {
    this.initMenu();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">


              <li className="menu-title">{this.props.t("weChip")}</li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-chip" />
                  <span>{this.props.t("Wechip")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link target="_blank" to={{ pathname: "https://www.wechiptech.com/about.html" }}>
                      {this.props.t("aboutUs")}
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to={{ pathname: "https://www.wechiptech.com/contact.html" }}>
                      {this.props.t("contactUs")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="menu-title">{this.props.t("keys")}</li>

              <li>
                <Link to="/MACDerivation">
                  <i className="bx bx-key" />
                  <span>{this.props.t("macAlgorithms")}</span>
                </Link>
              </li>
              
              <li>
                <Link to="/UDK" >
                  <i className="bx bxs-key" />
                  <span>{this.props.t("iccMasterKey")}</span>
                </Link>
                {/* <ul className="sub-menu" aria-expanded="false">
                  
                  <li>
                    <Link to="/ICCMasterKey">
                      {this.props.t("encode")}
                    </Link>
                  </li> */}
                {/* </ul> */}
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-key" />
                  <span>{this.props.t("thalesKeyBlock")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/TKBEncode">
                      {this.props.t("encode")}
                    </Link>
                  </li>
                  
                  <li>
                    <Link to="/TKBDecode">
                      {this.props.t("decode")}
                    </Link>
                  </li>
                </ul>
              </li>
              

              {/* <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-key" />
                  <span>{this.props.t("tr31KeyBlock")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/TR31KBEncode">
                      {this.props.t("encode")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/TR31KBDecode">
                      {this.props.t("decode")}
                    </Link>
                  </li>
                </ul>
              </li> */}
              <li className="menu-title">{this.props.t("cipher")}</li>

              <li>
                <Link to="/AESEncrypt" >
                  <i className="bx bx-lock" />
                  <span>{this.props.t("aes")}</span>
                </Link>
                {/* <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/AESEncrypt">
                      {this.props.t("operations")}
                    </Link>
                  </li>
                </ul> */}
              </li>

              <li>
                <Link to="/DESEncrypt">
                  <i className="bx bxs-lock" />
                  <span>{this.props.t("des")}</span>
                </Link>
                {/* <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/DESEncrypt">
                      {this.props.t("operations")}
                    </Link>
                  </li>
                </ul> */}
              </li>

               {/* <li> 
                 <Link to="/RSAOpt" >
                  <i className="bx bx-shield-quarter" />
                  <span>{this.props.t("rsa")}</span>
                </Link> 
                 <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/RSAOpt">
                      {this.props.t("operations")}
                    </Link>
                  </li>
                </ul> 
              </li>  */}

              <li className="menu-title">{this.props.t("emv")}</li>

              <li>
                <Link to="/APDUResp">
                  <i className="bx bx-search-alt" />
                  <span>{this.props.t("apduResponseQuery")}</span>
                </Link>
              </li>

              <li>
                <Link to="/applicationID">
                  <i className="bx bx-id-card" />
                  <span>{this.props.t("applicationIdentifiers")}</span>
                </Link>
              </li>

              <li>
                <Link to="/CAPublicKey" >
                  <i className="bx bxs-search" />
                  <span>{this.props.t("caPublicKey")}</span>
                </Link>
              </li>

              <li>
                <Link to="/EMVTagDic">
                  <i className="bx bx-book" />
                  <span>{this.props.t("emvTagDictionary")}</span>
                </Link>
              </li>

              <li>
                <Link to="/TLVParser">
                  <i className="bx bx-file-find" />
                  <span>{this.props.t("tlvParser")}</span>
                </Link>
              </li>
              

              <li className="menu-title">{this.props.t("emv tag decoder")}</li>

              <li>
                <Link to="/AipDecoder" >
                <i className="bx bxs-tag" />
                  <span>{this.props.t("aipDecoder")}</span>

                </Link>
               
              </li>

              <li>
                <Link to="/AppUsageControl" >
                <i className="bx bxs-tag" />
                  <span>{this.props.t("appUsageControlDecoder")}</span>
                </Link>
                
              </li>

              <li>
                <Link to="/CvmDecoder" >
                <i className="bx bxs-tag" />
                  <span>{this.props.t("CVM List (Tag 8E)")}</span>
                </Link>
               
              </li>

              <li>
                <Link to="/CVMResults" >
                <i className="bx bxs-tag" />
                  <span>{this.props.t("cvmResultsDecoder")}</span>
                </Link>
                {/* <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/CVMResults">
                      {this.props.t("Decoder")}
                    </Link>
                  </li>

                </ul> */}
              </li>

              <li>
                <Link to="/CTQDecoder" >
                <i className="bx bxs-tag" />
                  <span>{this.props.t("ctqDecoder")}</span>
                </Link>
                {/* <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/CTQDecoder">
                      {this.props.t("Decoder")}
                    </Link>
                  </li>

                </ul> */}
              </li>

              <li>
                <Link to="/MChipAdvanceCVR" >
                <i className="bx bxs-tag" />
                  <span>{this.props.t("cvrDecoder")}</span>
                </Link>
                {/* <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/MChipAdvanceCVR">
                      {this.props.t("CVR Decoder")}
                    </Link>
                  </li>

                </ul> */}
              </li>

              <li>
                <Link to="/issuerAppData" >
                <i className="bx bxs-tag" />
                  <span>{this.props.t("issuerAppDataTag")}</span>
                </Link>
                
              </li>

              <li>    

                <Link to="/TermCapOperation" >
                <i className="bx bxs-tag" />
                  <span>{this.props.t("termCapDecoder")}</span>
                </Link>
                {/* <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/TermCapOperation">
                      {this.props.t("Decoder")}
                    </Link>
                  </li>

                </ul> */}
              </li>

               <li>
                <Link to="/TTQDecoder" >
                <i className="bx bxs-tag" />
                  <span>{this.props.t("ttqDecoder")}</span>
                </Link>
              </li>

              <li>
                <Link to="/TvrDecoder" >
                <i className="bx bxs-tag" />
                  <span>{this.props.t("tvrDecoder")}</span>
                </Link>
              </li>

            {/* <li>
                <Link to="/Subscribe">
                  <i className="bx bx-file-find" />
                  <span>{this.props.t("susbcribe")}</span>
                </Link>   */}
                {/* <ul className="sub-menu" aria-expanded="false"> */}
                  {/* <li>
                    <Link to="/SubscribeDecoder">
                      {this.props.t("decoder")}
                    </Link>
                  </li> */}

                {/* </ul> */}
              {/* </li> */}

              {/* <li>
                <Link to="/UserVerificationOperation" >
                  <i className="bx bx-file-find" />
                  <span>{this.props.t("userVerification")}</span>
                </Link> */}
                {/* <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/UserVerificationOperation">
                      {this.props.t("decoder")}
                    </Link>
                  </li>

                </ul> */}
              {/* </li> */}


              <li className="menu-title">{this.props.t("pinBlocks")}</li>

              {/* <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-lock-alt" />
                  <span>{this.props.t("pinBlocksAES")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/PinBlockAES">
                      {this.props.t("operations")}
                    </Link>
                  </li>

                </ul>
              </li> */}

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-dialpad" />
                  <span>{this.props.t("pinBlocksGeneral")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/PinBlockGEncode">
                      {this.props.t("encode")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/PinBlockGDecode">
                      {this.props.t("decode")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-calculator" />
                  <span>{this.props.t("pinPVVCalculator")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/PinCalculator">
                      {this.props.t("pinCalculator")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/PvvCalculator">
                      {this.props.t("pvvCalculator")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="menu-title">{this.props.t("cardValidation")}</li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-credit-card" />
                  <span>{this.props.t("cvv")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/CvvGenerate">
                      {this.props.t("generate")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/CvvValidate">
                      {this.props.t("validate")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="menu-title">{this.props.t("generic")}</li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-cut" />
                  <span>{this.props.t("hash")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/Hash">
                      {this.props.t("calculator")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="menu-title">{this.props.t("bitwise")}</li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-plus" />
                  <span>{this.props.t("bitwise")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/Bitwise">
                      {this.props.t("calculator")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/BitShift">
                      {this.props.t("bitShift")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="menu-title">{this.props.t("conversions")}</li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-sync" />
                  <span>{this.props.t("conversions")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/Conversions">
                      {this.props.t("calculator")}
                    </Link>
                  </li>
                </ul>
              </li>

            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
