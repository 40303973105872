import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import "../../pages.scss"

import { getResponse } from "../../../helpers/api_helper"

//i18n
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class BitShift extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bitShifts: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      disthresh1: false,
      disthresh2: false,
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.inputDataChange = this.inputDataChange.bind(this);
    this.handleSelectShift = this.handleSelectShift.bind(this);
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    if (this.state.bitShifts.shifts == null || this.state.bitShifts.shifts == "")
      this.setState({
        bitShifts: ({
          ...this.state.bitShifts,
          shifts: "0"
        })
      })

    const cloudInput = {
      inputData: values.inputData,
      shiftType: values.shiftType,
      inputDataFormat: values.inputDataFormat,
      shifts: this.state.bitShifts.shifts,
    };

    getResponse(cloudInput, "/cloud/v1/Bitwise/BitShift")
      .then((resp) => {
        this.setState({ buttonTimer: false })
        if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
          this.setState({ respData: [resp.resultData] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addSuccessItem();
        }
        else {
          this.setState({ respErrorData: [resp.resultDesc] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addFailItem();
        }
      })
  }

  addSuccessItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("bitShiftOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>

        </Col>
      ]
    }));

    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-success" role="alert">
    //         <h4 className="alert-heading">{this.props.t("bitShiftOperationSuccess")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // )
    // this.setState({ items: [...this.state.items] })

  }
  addFailItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({

      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("bitShiftOprationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>

              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));

    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-danger" role="alert">
    //         <h4 className="alert-heading">{this.props.t("bitShiftOprationFail")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respErrorData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // );
    // this.setState({ items: [...this.state.items] })
  }

  inputDataChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ inputDataChange: event.target.value.length })
  }

  handleSelectShift(e) {
    this.setState({
      bitShifts: ({
        ...this.state.bitShifts,
        shifts: e.value
      })
    })
  }

  render() {
    const shift = [
      { value: "0", label: "0" },
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
      { value: "8", label: "8" },
    ]
    const defaultValues = { bitwiseOperation: "AND" }
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools| {this.props.t("bitShift")}</title>
              <meta name="description" content="Bitshift is a fundamental operation in computer programming used to shift the bits of a binary number to the left or right. Learn about bitwise left shift, bitwise right shift, and their applications in manipulating binary data." />
              <meta name="keywords" content="Bitshift, Bitwise Shift, Shift Operation, Left Shift, Right Shift, Bit Manipulation, Binary Number, Binary Operations, Programming, Computer Science, Logical Shift, Arithmetic Shift" />
            </MetaTags>
          </Helmet>

          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("bitShift")} breadcrumbItem={this.props.t("bitShiftOperation")} />
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit} model={defaultValues}>
                      <Row className="rowAes">
                        <Col>
                          <FormGroup>
                            <AvRadioGroup inline name="inputDataFormat" required errorMessage={this.props.t("pickOne")}>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="InputDataFormat"
                              >
                                {this.props.t("bitShiftInputDataFormatInfo")}
                              </UncontrolledTooltip>
                              <h6>{this.props.t("inputDataFormat")}</h6>
                              <AvRadio label={this.props.t("hexadecimal")} value="Hexadecimal"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ inputDataFormatCont: "Hexadecimal" }) } }}
                              />
                              <AvRadio label={this.props.t("decimal")} value="Decimal"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ inputDataFormatCont: "Decimal" }) } }}
                              />
                              <AvRadio label={this.props.t("binary")} value="Binary"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ inputDataFormatCont: "Binary" }) } }}
                              />
                            </AvRadioGroup>
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="InputDataFormat"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("data")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="InputData"
                            >
                              {this.props.t("bitShiftDataInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="inputData"
                              placeholder={this.props.t("fill")}
                              type="input"
                              value={this.state.bitShifts
                                ? this.state.bitShifts.inputData : ""}
                              onChange={this.inputDataChange}
                              validate={this.state.inputDataFormatCont == "ASCII" ? {
                                required: { value: true },
                              }
                                : this.state.inputDataFormatCont == "Hexadecimal" ? {
                                  required: { value: true },
                                  pattern: { value: /^[A-Fa-f0-9]+$/, errorMessage: this.props.t("dataShouldBeHEXDataFormat") },
                                } : this.state.inputDataFormatCont == "Decimal" ? {
                                  required: { value: true },
                                  pattern: { value: /^[0-9]+$/, errorMessage: this.props.t("dataShouldBeDecimalDataFormat") },
                                } : this.state.inputDataFormatCont == "Binary" ? {
                                  required: { value: true },
                                  pattern: { value: /^[0-1]+$/, errorMessage: this.props.t("dataShouldBeBinaryDataFormat") },
                                } : null
                              }
                            />
                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.inputDataChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="InputData"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <AvRadioGroup inline name="shiftType" required errorMessage={this.props.t("pickOne")}>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="ShiftType"
                              >
                                {this.props.t("shiftTypeInfo")}
                              </UncontrolledTooltip>
                              <h6>{this.props.t("shiftType")}</h6>
                              <AvRadio label="<<" value={1} />
                              <AvRadio label=">>" value={2} />
                            </AvRadioGroup>
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="ShiftType"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className="select2-container">
                            <Label className="control-label">{this.props.t("shift")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Shifts"
                            >
                              {this.props.t("shiftInfo")}
                            </UncontrolledTooltip>
                            <Select
                              defaultValue={shift[0]}
                              options={shift}
                              onChange={this.handleSelectShift}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Shifts"
                              ></i></div>
                          </FormGroup>
                        </Col>

                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="secondary"
                          onClick={() => window.location.reload()}
                          style={{ textAlign: "start" }}
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          disabled={this.state.buttonTimer}
                          color="primary"
                        >
                          {this.props.t("calculateButton")}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

BitShift.propTypes = {
  t: PropTypes.any,
}

export default (withTranslation()(BitShift));
