import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "../../pages.scss"

import { getResponse } from "../../../helpers/api_helper"

//i18n
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class PinBlockAES extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pinBlockAESs: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      disthresh1: false,
      disthresh2: false,
      disthresh3: false,
      ivDsb: false,
      dataDsb: false,
      dataInputDsb: false,
      isEncrypt: false,
      decodeDsb: false,
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.panChange = this.panChange.bind(this);
    this.keyChange = this.keyChange.bind(this)
    this.pinChange = this.pinChange.bind(this)
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    if (this.state.isEncrypt == true) {
      const cloudInput = {
        key: values.key,
        pin: values.pin,
        pan: values.pan
      };

      //onTKBEncode(cloudInput);
      getResponse(cloudInput, "/cloud/v1/.../Encode")
        .then((resp) => {
          if (resp.resultCode !== "-1") {
            this.setState({ respData: [resp.resultData] })
            this.addSuccessItem();
          }
          else {
            this.setState({ respErrorData: [resp.resultDesc] })
            this.addFailItem();
          }
        })

    }
    if (this.state.isEncrypt == false) {
      const cloudInput = {
        key: values.key,
        pin: values.pin,
        pan: values.pan
      };

      getResponse(cloudInput, "/../Decode")
        .then((resp) => {
          this.setState({ buttonTimer: false })
          if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
            this.setState({ respData: [resp.resultData] })
            this.addSuccessItem();
          }
          else {
            this.setState({ respErrorData: [resp.resultDesc] })
            this.addFailItem();
          }
        })

    }
  }


  addSuccessItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("pinBlockAESOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-success" role="alert">
    //         <h4 className="alert-heading">{this.props.t("pinBlockAESOperationSuccess")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // )
    // this.setState({ items: [...this.state.items] })

  }
  addFailItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("pinBlockAESOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-danger" role="alert">
    //         <h4 className="alert-heading">{this.props.t("pinBlockAESOperationFail")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respErrorData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // );
    // this.setState({ items: [...this.state.items] })
  }
  keyChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ keyChange: event.target.value.length })
  }

  pinChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh2: true })
    } else {
      this.setState({ disthresh2: false })
    }
    this.setState({ pinChange: event.target.value.length })
  }

  panChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh3: true })
    } else {
      this.setState({ disthresh3: false })
    }
    this.setState({ panChange: event.target.value.length })
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | {this.props.t("aesPINBlockFormat4")}</title>
              <meta name="description" content="PIN Block AES Encryption Format 4 is a method used to encrypt Personal Identification Numbers (PINs) during financial transactions, utilizing the AES encryption algorithm. Learn about the structure and encryption process of Format 4 PIN blocks, their role in securing PIN data, and their significance in ensuring confidentiality in electronic payment systems." />
              <meta name="keywords" content="PIN Block AES Encryption Format 4, PIN encryption, AES encryption algorithm, financial transactions, PIN security, encryption format, electronic payment systems, data confidentiality" />
            </MetaTags>
          </Helmet>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("aesPINBlockFormat4")} breadcrumbItem={this.props.t("aesPINBlockFormat4Encrypt")} />
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit}>
                      <Row className="rowAes">
                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("key")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Key"
                            >
                              {this.props.t("pinBlockAESEncryptKeyInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="key"
                              placeholder={this.props.t("fill")}
                              type="input"
                              value={this.state.pinBlockAESs
                                ? this.state.pinBlockAESs.key : ""}
                              onChange={this.keyChange}
                              validate={{
                                required: { value: true }
                              }}
                            />
                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.keyChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Key"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="productdesc">
                              {this.props.t("pinPINBlock")}
                            </Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Pin"
                            >
                              {this.props.t("pinPINBlockInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="pin"
                              placeholder={this.props.t("fill")}
                              type="text"
                              value={this.state.pinBlockAESs
                                ? this.state.pinBlockAESs.pin : ""}
                              className="form-control"
                              onChange={this.pinChange}
                              validate={
                                this.state.pinDsb ? {
                                  required: { value: false }
                                } : { required: { value: true } }}
                            />
                            {this.state.disthresh2 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.pinChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Pin"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup mb="2">
                            <Label htmlFor="productname">{this.props.t("panPANBlock")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="PAN"
                            >
                              {this.props.t("panPANBlockInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="pan"
                              placeholder={this.props.t("fill")}
                              type="input"
                              value={this.state.pinBlockAESs
                                ? this.state.pinBlockAESs.pan : ""}
                              onChange={this.panChange}
                              validate={
                                this.state.panDsb ? {
                                  required: { value: false }
                                } : {
                                  required: { value: true }
                                }}
                            />
                            {this.state.disthresh3 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.panChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="PAN"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="secondary"
                          onClick={() => window.location.reload()}
                          style={{ textAlign: "start" }}
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={this.state.buttonTimer}
                          onClick={() => this.setState({ isEncrypt: true })}
                        >
                          {this.props.t("encodeButton")}
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          onClick={() => this.setState({ isEncrypt: false })}
                          disabled={this.state.buttonTimer}
                        >
                          {this.props.t("decodeButton")}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

PinBlockAES.propTypes = {
  t: PropTypes.any,
}

export default (withTranslation()(PinBlockAES));
