import React, { Component } from "react";
import PropTypes from 'prop-types';
 
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
 
// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
 
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";
 
// actions
import { apiError, loginUser } from "../../store/actions";
 
// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";

//i18n
import { withTranslation } from "react-i18next"
 
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
 
    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }
 
  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.props.loginUser(values, this.props.history);
  }
 
  componentDidMount() {
    this.props.apiError("");
    var tempLang = localStorage.getItem("I18N_LANGUAGE");
    var tempLang2 = localStorage.getItem("i18nextLng");
    if(tempLang != null)
      localStorage.setItem("I18N_LANGUAGE", tempLang);
    
    if(tempLang2!=null)
      localStorage.setItem("i18nextLng", tempLang2);
    
    //history.push("/dashboard");
    
  }
 
 
  render() {
    return (
      <React.Fragment>
       
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">{this.props.t("authenticationSystem")}</h5>
                          <p>{this.props.t("signInToAuthenticationSystem")}</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className=""
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {this.props.error && this.props.error ? (
                          <Alert color="danger">{this.props.error}</Alert>
                        ) : null}
 
                        <div className="mb-3">
                          <AvField
                            name="username"
                            label={this.props.t("userName")}
                            value=""
                            className="form-control"
                            placeholder={this.props.t("enteruserName")}
                            type="text"
                            required
                          />
                        </div>
 
                        <div className="mb-3">
                          <AvField
                            name="password"
                            label={this.props.t("password")}
                            value=""
                            type="password"
                            required
                            placeholder={this.props.t("enterPassword")}
                          />
                        </div>
 
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            {this.props.t("rememberMe")}
                          </label>
                        </div>
 
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            {this.props.t("logInButton")}
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  
                  <p>
                    © {new Date().getFullYear()}  Wechip 
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
 
Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  t: PropTypes.any
};
 
const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};
 
export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(withTranslation()(Login))
);
 
