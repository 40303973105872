import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN, VERIFY_2F,VERIFY_2F_LOGIN } from "./actionTypes"
import { loginSuccess, logoutUserSuccess, apiError} from "./actions"

//Include Both Helper File with needed methods

import {  postFakeLogin,postVerify2fSecret,postVerify2fLogin} from "../../../helpers/fakebackend_helper"


function* loginUser({ payload: { user, history } }) {
  
    localStorage.setItem("user", "");
    localStorage.setItem("accessToken", "");
    localStorage.setItem("refreshToken", "");
    localStorage.setItem("username", user.username);
    localStorage.setItem("password", user.password);

    const response =  {
      username: user.username,
      password: user.password,
    };

    const authUser = {
      uid: response.user,
      username:response.user,
      role:"",
      password:"",
      email:"",
    };

    localStorage.setItem("authUser", JSON.stringify(authUser));
    history.push("/Dashboard");

}

function* verify2fCode({ payload: { verify, history } }) {
  try {
    const response = yield call(postVerify2fSecret, {
      username : localStorage.getItem("username"),
      password : localStorage.getItem("password"),
      verificationCode: verify.verificationCode,
      QrString: verify.QrString,
    });
      localStorage.setItem("authUser", JSON.stringify(response.user));
      localStorage.setItem("accessToken", response.accessToken);
      localStorage.setItem("refreshToken", response.refreshToken);
      history.push("/TKBEncode");
  } catch (error) {
    yield put(apiError(error))
  }
}

function* verify2fLogin({ payload: { verify, history } }) {
  try {
    const response = yield call(postVerify2fLogin, {
      username : localStorage.getItem("username"),
      password : localStorage.getItem("password"),
      twoFactorSecret : "",
      verificationCode: verify.verificationCode
    });
      localStorage.setItem("authUser",JSON.stringify(response.user));
      localStorage.setItem("accessToken", response.accessToken);
      localStorage.setItem("refreshToken", response.refreshToken);
      history.push("/TKBEncode")
  } catch (error) {
    yield put(apiError(error))
  }
}


function* logoutUser({ payload: { history } }) {
  try {
    var tempLang = localStorage.getItem("I18N_LANGUAGE");
    var tempLang2 = localStorage.getItem("i18nextLng");
    localStorage.clear();

    localStorage.setItem("I18N_LANGUAGE", tempLang);
    localStorage.setItem("i18nextLng", tempLang2);

    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}



function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(VERIFY_2F, verify2fCode)
  yield takeEvery(VERIFY_2F_LOGIN, verify2fLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
