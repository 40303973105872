import PropTypes from 'prop-types'
import React, { Component } from "react"
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';

import { connect } from "react-redux"
import { Row, Col, Button, Card, CardBody, Alert } from "reactstrap"

import { Link } from "react-router-dom"

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import RightSidebar from '../CommonForBoth/RightSidebar'
import SweetAlert from "react-bootstrap-sweetalert"
import "toastr/build/toastr.min.css";
import toastr from "toastr";

import logo from "../../assets/images/logo.svg"
import logoLightSvg from "../../assets/images/logo-light.svg"

//i18n
import { withTranslation } from "react-i18next"
import "../../pages/pages.scss"

// Redux Store
import { toggleRightSidebar } from "../../store/actions"
import Subscribe from 'components/CommonForBoth/TopbarDropdown/Subscribe';

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSearch: false,
      open: false,
      position: 'right',
      inputItems: [],
    }
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightDrawer = this.toggleRightDrawer.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.downloadTxtFile = this.downloadTxtFile.bind(this);
    this.getFormattedTime = this.getFormattedTime.bind(this)
    this.setLocalStorage = this.setLocalStorage.bind(this)
    this.storageClear = this.storageClear.bind(this)
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback()
  }
  /** 
   * Right sidebar drawer 
  * */

  toggleRightDrawer() {
    this.setState({ position: 'right' });
    this.setState({ open: !this.state.open });
  }
  closeDrawer() {
    this.setState({ open: false });
  }
  onDrawerClose() {
    this.setState({ open: false });
  }


  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar()
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  downloadTxtFile() {
    const element = document.createElement("a");
    const constData = JSON.parse(localStorage.getItem("jsonHistory"));
    if (constData != "") {
      const lastConst = constData.reverse().map((item, idx) => { if (item.resultCode == "0") { return ("\n" + item.resultData) } else if (item.resultCode == "-1") { return ('\n' + item.operationDatetime + '\n' + item.operationName + "\n" + item.resultDesc + "\n") } })
      const file = new Blob([lastConst],
        {
          type: "text/plain"
        });
      element.href = URL.createObjectURL(file)
      element.download = "WeChipCloud-Outputs-" + this.getFormattedTime() + ".txt"
      document.body.appendChild(element)
      element.click()
    }
    else {
      this.showToastDel()
    }
  }


  showToastDel() {
    var toastType
    var positionClass = "toast-bottom-center";
    var progressBar = "progressBar";
    var closeButton = "closeButton";

    toastr.options = {
      positionClass: positionClass,
      progressBar: progressBar,
      closeButton: closeButton,
    };
    var message = this.props.t("historyIsEmptyYouCanNotDownload");
    var title = this.props.t("fail");
    toastr.error(message, title)
    return;
  }

  getFormattedTime() {
    var today = new Date();
    var y = today.getFullYear();
    // JavaScript months are 0-based.
    var m = today.getMonth() + 1;
    var d = today.getDate();
    var h = today.getHours();
    var mi = today.getMinutes();
    var s = today.getSeconds();
    return y + "/" + m + "/" + d + "/" + h + "." + mi + "." + s;
  }


  setLocalStorage() {

    const control = localStorage.getItem("jsonHistory")
    if (control != "") {
      const inputItem = JSON.parse(localStorage.getItem("jsonHistory"))
      if (inputItem != null && inputItem != "" && inputItem != [])
        this.setState({ inputItems: [...inputItem] })
    }
    else {
      localStorage.setItem("jsonHistory", [])
    }
  }

  storageClear() {
    localStorage.setItem("jsonHistory", JSON.stringify("")), this.setState({ inputItems: [] })
  }
  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">

            <div className="d-flex">
              <div className="navbar-brand-box d-lg-none d-md-block">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="22" />
                  </span>
                </Link>
              </div>


              {location.pathname != '/Dashboard' && location.pathname != '/Version' ? (
                <Col id='viewHistoryButton'>
                  <Button style={{ marginRight: 10 }} onClick={() => { this.setState({ open: true }), this.setLocalStorage() }}>
                    {this.props.t("viewHistory")}
                  </Button>
                  <Button style={{ marginRight: 10 }} id="downloadHistoryButton" onClick={this.downloadTxtFile}>
                    {this.props.t("downloadHistory")}
                  </Button>
                  <Button id='clearHistoryButton' onClick={() => this.setState({ confirm_both: true })}>
                    {this.props.t("clearHistory")}
                  </Button>
                </Col>
              ) : ""}
              {this.state.confirm_both ? (
                <SweetAlert
                  title={this.props.t("areYouSure")}
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.storageClear(),
                      this.setState({
                        confirm_both: false,
                        success_dlg: true,
                        dynamic_title: this.props.t("deleted"),
                        dynamic_description: this.props.t("yourHistoryHasBeenDeleted"),
                      })
                  }
                  }
                  onCancel={() =>
                    this.setState({
                      confirm_both: false,
                      error_dlg: true,
                      dynamic_title: this.props.t("cancelled"),
                    })
                  }
                >
                  {this.props.t("youWontBeAbleToRevertThis")}
                </SweetAlert>
              ) : null}
              {this.state.success_dlg ? (
                <SweetAlert
                  title={this.state.dynamic_title}
                  onConfirm={() => this.setState({ success_dlg: false })}
                >
                  {this.state.dynamic_description}
                </SweetAlert>
              ) : null}
              {this.state.error_dlg ? (
                <SweetAlert
                  title={this.state.dynamic_title}
                  onConfirm={() => this.setState({ error_dlg: false })}
                >
                  {this.state.dynamic_description}
                </SweetAlert>
              ) : null}


              <ReactDrawer
                scrollable
                open={this.state.open}
                className="drawer-open"
                position={this.state.position}
                onClose={this.onDrawerClose}>
                {this.state.inputItems.reverse().map((item, idx) => {
                  if (item.resultCode == "0") {
                    return <Col key={idx} >
                      <Card>
                        <CardBody>
                          <Alert className="alert-success" role="alert">
                            <h4 className="alert-heading">{item.operationName} {item.operationDatetime}</h4>
                            <hr />
                            <div className='display-linebreak'>{item.resultData}</div>
                          </Alert>
                        </CardBody>
                      </Card>
                    </Col>
                  }
                  else if (item.resultCode == "-1") {
                    return <Col key={idx}>
                      <Card>
                        <CardBody>
                          <Alert className="alert-danger" role="alert">
                            <h4 className="alert-heading">{item.operationName} {item.operationDatetime}</h4>
                            <hr />
                            <div className="display-linebreak">{item.resultDesc}</div>
                          </Alert>
                        </CardBody>
                      </Card>
                    </Col>
                  }
                })}
              </ReactDrawer>



              <button
                type="button"
                onClick={this.toggleMenu}
                className="btn btn-sm px-3 font-size-16 header-item"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>
            </div>

            {/* TODO : Faz 2 de açılacak. */}
            {/* <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ms-2">
                <button
                  onClick={() => {
                    this.setState({ isSearch: !this.state.isSearch })
                  }}
                  type="button"
                  className="btn header-item noti-icon"
                  id="page-header-search-dropdown"
                >
                  <i className="mdi mdi-magnify"></i>
                </button>
                <div
                  className={
                    this.state.isSearch
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={this.props.t("placeholderSearch")}
                          aria-label={this.props.t("recipientsUsername")}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <Subscribe />

              <LanguageDropdown />



            </div> */}
          </div>
        </header>
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { layoutType } = state.Layout
  return { layoutType }
}

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withTranslation()(Header)
)

Header.propTypes = {
  t: PropTypes.any,
  toggleMenuCallback: PropTypes.any,
  toggleRightSidebar: PropTypes.func
}