
export const GET_TEST_DEFINITIONS = "GET_TEST_DEFINITIONS"
export const GET_TEST_DEFINITIONS_SUCCESS = "GET_TEST_DEFINITIONS_SUCCESS"
export const GET_TEST_DEFINITIONS_FAIL = "GET_TEST_DEFINITIONS_FAIL"

export const ADD_NEW_TEST_DEFINITION = "ADD_NEW_TEST_DEFINITION"
export const ADD_TEST_DEFINITION_SUCCESS = "ADD_TEST_DEFINITION_SUCCESS"
export const ADD_TEST_DEFINITION_FAIL = "ADD_TEST_DEFINITION_FAIL"

export const UPDATE_TEST_DEFINITION = "UPDATE_TEST_DEFINITION"
export const UPDATE_TEST_DEFINITION_SUCCESS = "UPDATE_TEST_DEFINITION_SUCCESS"
export const UPDATE_TEST_DEFINITION_FAIL = "UPDATE_TEST_DEFINITION_FAIL"

export const DELETE_TEST_DEFINITION = "DELETE_TEST_DEFINITION"
export const DELETE_TEST_DEFINITION_SUCCESS = "DELETE_TEST_DEFINITION_SUCCESS"
export const DELETE_TEST_DEFINITION_FAIL = "DELETE_TEST_DEFINITION_FAIL"

// MEMBER DEFINITION

export const GET_MEMBER_DEFINITIONS = "GET_MEMBER_DEFINITIONS"
export const GET_MEMBER_DEFINITIONS_SUCCESS = "GET_MEMBER_DEFINITIONS_SUCCESS"
export const GET_MEMBER_DEFINITIONS_FAIL = "GET_MEMBER_DEFINITIONS_FAIL"

export const ADD_NEW_MEMBER_DEFINITION = "ADD_NEW_MEMBER_DEFINITION"
export const ADD_MEMBER_DEFINITION_SUCCESS = "ADD_MEMBER_DEFINITION_SUCCESS"
export const ADD_MEMBER_DEFINITION_FAIL = "ADD_MEMBER_DEFINITION_FAIL"

export const UPDATE_MEMBER_DEFINITION = "UPDATE_MEMBER_DEFINITION"
export const UPDATE_MEMBER_DEFINITION_SUCCESS = "UPDATE_MEMBER_DEFINITION_SUCCESS"
export const UPDATE_MEMBER_DEFINITION_FAIL = "UPDATE_MEMBER_DEFINITION_FAIL"

export const DELETE_MEMBER_DEFINITION = "DELETE_MEMBER_DEFINITION"
export const DELETE_MEMBER_DEFINITION_SUCCESS = "DELETE_MEMBER_DEFINITION_SUCCESS"
export const DELETE_MEMBER_DEFINITION_FAIL = "DELETE_MEMBER_DEFINITION_FAIL"

// PAGE DEFINITION

export const GET_PAGE_DEFINITIONS = "GET_PAGE_DEFINITIONS"
export const GET_PAGE_DEFINITIONS_SUCCESS = "GET_PAGE_DEFINITIONS_SUCCESS"
export const GET_PAGE_DEFINITIONS_FAIL = "GET_PAGE_DEFINITIONS_FAIL"

export const ADD_NEW_PAGE_DEFINITION = "ADD_NEW_PAGE_DEFINITION"
export const ADD_PAGE_DEFINITION_SUCCESS = "ADD_PAGE_DEFINITION_SUCCESS"
export const ADD_PAGE_DEFINITION_FAIL = "ADD_PAGE_DEFINITION_FAIL"

export const UPDATE_PAGE_DEFINITION = "UPDATE_PAGE_DEFINITION"
export const UPDATE_PAGE_DEFINITION_SUCCESS = "UPDATE_PAGE_DEFINITION_SUCCESS"
export const UPDATE_PAGE_DEFINITION_FAIL = "UPDATE_PAGE_DEFINITION_FAIL"

export const DELETE_PAGE_DEFINITION = "DELETE_PAGE_DEFINITION"
export const DELETE_PAGE_DEFINITION_SUCCESS = "DELETE_PAGE_DEFINITION_SUCCESS"
export const DELETE_PAGE_DEFINITION_FAIL = "DELETE_PAGE_DEFINITION_FAIL"

// ROLE DEFINITION 

export const GET_ROLE_DEFINITIONS = "GET_ROLE_DEFINITIONS"
export const GET_ROLE_DEFINITIONS_SUCCESS = "GET_ROLE_DEFINITIONS_SUCCESS"
export const GET_ROLE_DEFINITIONS_FAIL = "GET_ROLE_DEFINITIONS_FAIL"

export const ADD_NEW_ROLE_DEFINITION = "ADD_NEW_ROLE_DEFINITION"
export const ADD_ROLE_DEFINITION_SUCCESS = "ADD_ROLE_DEFINITION_SUCCESS"
export const ADD_ROLE_DEFINITION_FAIL = "ADD_ROLE_DEFINITION_FAIL"

export const UPDATE_ROLE_DEFINITION = "UPDATE_ROLE_DEFINITION"
export const UPDATE_ROLE_DEFINITION_SUCCESS = "UPDATE_ROLE_DEFINITION_SUCCESS"
export const UPDATE_ROLE_DEFINITION_FAIL = "UPDATE_ROLE_DEFINITION_FAIL"

export const DELETE_ROLE_DEFINITION = "DELETE_ROLE_DEFINITION"
export const DELETE_ROLE_DEFINITION_SUCCESS = "DELETE_ROLE_DEFINITION_SUCCESS"
export const DELETE_ROLE_DEFINITION_FAIL = "DELETE_ROLE_DEFINITION_FAIL"
 
// USER DEFINITION

export const GET_USER_DEFINITIONS = "GET_USER_DEFINITIONS"
export const GET_USER_DEFINITIONS_SUCCESS = "GET_USER_DEFINITIONS_SUCCESS"
export const GET_USER_DEFINITIONS_FAIL = "GET_USER_DEFINITIONS_FAIL"

export const ADD_NEW_USER_DEFINITION = "ADD_NEW_USER_DEFINITION"
export const ADD_USER_DEFINITION_SUCCESS = "ADD_USER_DEFINITION_SUCCESS"
export const ADD_USER_DEFINITION_FAIL = "ADD_USER_DEFINITION_FAIL"

export const UPDATE_USER_DEFINITION = "UPDATE_USER_DEFINITION"
export const UPDATE_USER_DEFINITION_SUCCESS = "UPDATE_USER_DEFINITION_SUCCESS"
export const UPDATE_USER_DEFINITION_FAIL = "UPDATE_USER_DEFINITION_FAIL"

export const DELETE_USER_DEFINITION = "DELETE_USER_DEFINITION"
export const DELETE_USER_DEFINITION_SUCCESS = "DELETE_USER_DEFINITION_SUCCESS"
export const DELETE_USER_DEFINITION_FAIL = "DELETE_USER_DEFINITION_FAIL"
