import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import "../../pages.scss"

import { getResponse } from "../../../helpers/api_helper"

//i18n
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class EMVTagDic extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emvTagDics: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      disthresh1: false,
      disthresh2: false,
      disthresh3: false,
      disthresh4: false,
      tagDsb: false,
      tagDsb2: true,
      valueDsb: true,
      tagDsb3: true,
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.tagChange = this.tagChange.bind(this)
    this.handleRadioController = this.handleRadioController.bind(this)
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    let cloudInput;
    if (this.state.tagDsb == false) {
      cloudInput = {
        searchData: values.tag,
        searchOption: values.searchOption
      };
    } else if (this.state.tagDsb3 === false) {
      cloudInput = {
        searchData: values.tag3,
        searchOption: values.searchOption
      };
    }
    getResponse(cloudInput, "/cloud/v1/EMV/Emv")
      .then((resp) => {
        this.setState({ buttonTimer: false })
        if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
          this.setState({ respData: [resp.resultData] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addSuccessItem();
        }
        else {
          this.setState({ respErrorData: [resp.resultDesc] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addFailItem();
        }
      })
  }


  addSuccessItem = () => {

    this.setState({ items: [] });

    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("emvTAGSearchOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-success" role="alert">
    //         <h4 className="alert-heading">{this.props.t("emvTAGSearchOperationSuccess")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // )
    // this.setState({ items: [...this.state.items] })

  }
  addFailItem = () => {

    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("emvTAGSearchOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-danger" role="alert">
    //         <h4 className="alert-heading">{this.props.t("emvTAGSearchOperationFail")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respErrorData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // );
    // this.setState({ items: [...this.state.items] })
  }

  tagChange = (event) => {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh2: true })
    } else {
      this.setState({ disthresh2: false })
    }
    this.setState({ tagChange: event.target.value.length })
  }

  tag3Change = (event) => {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh4: true })
    } else {
      this.setState({ disthresh4: false })
    }
    this.setState({ tag3Change: event.target.value.length })
  }



  handleRadioController = (e) => {
    if (e.target.value === "0") {
      this.setState({ tagDsb: false, tagDsb2: true, valueDsb: true, tagDsb3: true, disthresh3: false, disthresh1: false, disthresh4: false })
    }
    else if (e.target.value === "1") {
      this.setState({ tagDsb3: false, tagDsb: true, tagDsb2: true, valueDsb: true, disthresh3: false, disthresh1: false, disthresh2: false })
    }


  }

  render() {
    const defaultValues = { searchOption: "0" }
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools| {this.props.t("emvTagDictionary")}</title>
              <meta name="description" content="EMV Tag Dictionary is a comprehensive reference of EMV tags used in chip card transactions. Explore the meanings and purposes of various EMV tags, including tag numbers, data formats, and their significance in EMV payment processing." />
              <meta name="keywords" content="EMV Tag Dictionary, EMV, EMV tags, chip card transactions, payment processing, tag numbers, data formats, EMV specification, chip card technology, transaction data" />

            </MetaTags>
          </Helmet>

          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("emv")} breadcrumbItem={this.props.t("emvTagDictionary")} />
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit} model={defaultValues}>
                      <Row className="rowAes">
                        <Col className="mb-3">
                          <AvRadioGroup inline name="searchOption" required>
                            <AvRadio value="0" label={this.props.t("tagOnly")}
                              onChange={this.handleRadioController}
                            />
                            <AvRadio value="1" label="TLV"
                              onChange={this.handleRadioController}
                            />
                          </AvRadioGroup>
                        </Col>
                        {this.state.tagDsb ? null : (
                          <Col>
                            <FormGroup>
                              <Label htmlFor="productdesc">
                                {this.props.t("tag")}
                              </Label>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="Tag"
                              >
                                {this.props.t("tag")}
                              </UncontrolledTooltip>
                              <AvField
                                name="tag"
                                placeholder={this.props.t("fill")}
                                type="text"
                                value={this.state.emvTagDics
                                  ? this.state.emvTagDics.tag : ""}
                                className="form-control"
                                onChange={this.tagChange}
                                validate={{ required: { value: true } }}
                              />
                              {this.state.disthresh2 ? (
                                <span className="badgecount badge bg-success"
                                  style={{
                                    position: "absolute",
                                    marginTop: 1,
                                    marginLeft: 3
                                  }}
                                >
                                  {this.state.tagChange}{" Length"}
                                </span>
                              ) : null}
                              <div className="infoSymbol">
                                <i
                                  className="dripicons-information "
                                  id="Tag"
                                ></i>
                              </div>
                            </FormGroup>
                          </Col>)}
                        {this.state.tagDsb3 ? null : (
                          <Col>
                            <FormGroup>
                              <Label htmlFor="productdesc">
                                {this.props.t("tlvData")}
                              </Label>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="Tag3"
                              >
                                {this.props.t("tlvData")}
                              </UncontrolledTooltip>
                              <AvField
                                name="tag3"
                                placeholder={this.props.t("fill")}
                                type="textarea"
                                value={this.state.emvTagDics
                                  ? this.state.emvTagDics.tag3 : ""}
                                className="form-control"
                                onChange={this.tag3Change}
                                validate={{ required: { value: true } }}
                              />
                              {this.state.disthresh4 ? (
                                <span className="badgecount badge bg-success"
                                  style={{
                                    position: "absolute",
                                    marginTop: 1,
                                    marginLeft: 3
                                  }}
                                >
                                  {this.state.tag3Change}{" Length"}
                                </span>
                              ) : null}
                              <div className="infoSymbol">
                                <i
                                  className="dripicons-information "
                                  id="Tag3"
                                ></i>
                              </div>
                            </FormGroup>
                          </Col>)}
                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="secondary"
                          onClick={() => window.location.reload()}
                          style={{ textAlign: "start" }}
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          disabled={this.state.buttonTimer}
                          color="primary"
                        >
                          {this.props.t("searchButton")}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

EMVTagDic.propTypes = {
  t: PropTypes.any,
}

export default (withTranslation()(EMVTagDic));
