import { call, put, takeEvery } from "redux-saga/effects"

import {
  TKBENCODE,
  TKBDECODE,
} from "./actionTypes"
import {
  tkbEncodeSuccess,
  tkbEncodeFail,
  tkbDecodeSuccess,
  tkbDecodeFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  newTKBEncode,
  newTKBDecode,
} from "../../helpers/fakebackend_helper"

//TKBEncode

function* onTKBEncode({ payload: tkbEncode }) {
  try {
    const response = yield call(newTKBEncode, tkbEncode)
    yield put(tkbEncodeSuccess(response))
  } catch (error) {

    yield put(tkbEncodeFail(error))
  }
}

//TKBEncode

function* onTKBDecode({payload: tkbDecode}){
  try {
    const response = yield call(newTKBDecode, tkbDecode)
    yield put(tkbDecodeSuccess(response))
  }
  catch(error){
    yield put(tkbDecodeFail(error))
  }
}

function* tkbSaga() {
  yield takeEvery(TKBENCODE, onTKBEncode)
  yield takeEvery(TKBDECODE, onTKBDecode)
}

export default tkbSaga
