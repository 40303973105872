import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "../../pages.scss"
import { getResponse } from "../../../helpers/api_helper"

//i18n
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class PinCalculator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pinCalculators: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      disthresh1: false,
      disthresh2: false,
      disthresh3: false,
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleSelectPVKI = this.handleSelectPVKI.bind(this)
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.panChange = this.panChange.bind(this);
    this.pvvChange = this.pvvChange.bind(this);
    this.pdkChange = this.pdkChange.bind(this);
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    if (this.state.pinCalculators.pvki == null || this.state.pinCalculators.pvki == "")
      this.setState({
        pinCalculators: ({
          ...this.state.pinCalculators,
          pvki: "0"
        })
      })

    const { pinCalculators } = this.state

    const cloudInput = {
      pdk: values.pdk,
      pan: values.pan,
      pvv: values.pvv,
      pvki: pinCalculators.pvki,
    };

    getResponse(cloudInput, "/cloud/v1/PinPvvCalculator/PinPvv/Pin")
      .then((resp) => {
        this.setState({ buttonTimer: false })
        if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
          this.setState({ respData: [resp.resultData] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addSuccessItem();
        }
        else {
          this.setState({ respErrorData: [resp.resultDesc] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addFailItem();
        }
      })

  }


  handleSelectPVKI(e) {
    this.setState({
      pinCalculators: ({
        ...this.state.pinCalculators,
        pvki: e.value
      })
    })
  }

  addSuccessItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("pinCalculatorOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-success" role="alert">
    //         <h4 className="alert-heading">{this.props.t("pinCalculatorOperationSuccess")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // )
    // this.setState({ items: [...this.state.items] })

  }
  addFailItem = () => {

    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("pinCalculatorOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-danger" role="alert">
    //         <h4 className="alert-heading">{this.props.t("pinCalculatorOperationFail")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respErrorData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // );
    // this.setState({ items: [...this.state.items] })
  }
  panChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ panChange: event.target.value.length })
  }

  pvvChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh2: true })
    } else {
      this.setState({ disthresh2: false })
    }
    this.setState({ pvvChange: event.target.value.length })
  }

  pdkChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh3: true })
    } else {
      this.setState({ disthresh3: false })
    }
    this.setState({ pdkChange: event.target.value.length })
  }

  render() {
    const pvki = [
      { value: "0", label: "0" },
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
      { value: "8", label: "8" },
      { value: "9", label: "9" },
    ]
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | {this.props.t("pinCalculator")}</title>
              <meta name="description" content="Pin Calculator is a tool or algorithm used to generate Personal Identification Numbers (PINs) for use in various applications, such as financial transactions, access control, and authentication systems. Explore the principles and techniques behind PIN calculation, its applications in generating secure PIN codes, and its significance in ensuring data security." />
              <meta name="keywords" content="Pin Calculator, PIN generation, Personal Identification Number, PIN security, financial transactions, access control, authentication systems, data security" />
            </MetaTags>
          </Helmet>

          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("pinPVVKCalculator")} breadcrumbItem={this.props.t("pinCalculator")} />
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit}>
                      <Row>
                        <Col sm="12">
                          <FormGroup >
                            <Label htmlFor="productname">{this.props.t("pdk")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="PDK"
                            >
                              {this.props.t("pdkInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="pdk"
                              placeholder={this.props.t("fill")}
                              type="input"
                              value={this.state.pinCalculators
                                ? this.state.pinCalculators.pdk : ""}
                              onChange={this.pdkChange}
                              validate={{
                                required: { value: true },
                                minLength: { value: 32, errorMessage: "PDK has " + this.state.pdkChange + " characters, its size has to be 32 characters." },
                                maxLength: { value: 32, errorMessage: "PDK has " + this.state.pdkChange + " characters, its size has to be 32characters." },
                              }}
                            />
                            {this.state.disthresh3 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.pdkChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="PDK"
                              ></i></div>
                          </FormGroup>
                        </Col>

                        <Col sm="12">
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("pan")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="PAN"
                            >
                              {this.props.t("panInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="pan"
                              type="input"
                              minLength="13"
                              maxLength="19"
                              placeholder={this.props.t("fill")}
                              value={this.state.pinCalculators
                                ? this.state.pinCalculators.pan : ""}
                              onChange={this.panChange}
                              validate={{
                                required: { value: true },
                                minLength: { value: 13, errorMessage: "PAN has " + this.state.panChange + " characters, its size has to be between 13 and 19 characters." },
                                maxLength: { value: 19, errorMessage: "PAN has " + this.state.panChange + " characters, its size has to be between 13 and 19 characters." },
                              }}
                            />
                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.panChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="PAN"
                              ></i></div>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup >
                            <Label htmlFor="productname">{this.props.t("pvv")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="PVV"
                            >
                              {this.props.t("pvvInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="pvv"
                              placeholder={this.props.t("fill")}
                              type="input"
                              value={this.state.pinCalculators
                                ? this.state.pinCalculators.pvv : ""}
                              onChange={this.pvvChange}
                              validate={{
                                required: { value: true },
                                minLength: { value: 4, errorMessage: "PVV has " + this.state.pvvChange + " characters, its size has to be 4 characters." },
                                maxLength: { value: 4, errorMessage: "PVV has " + this.state.pvvChange + " characters, its size has to be 4 characters." },
                              }}
                            />
                            {this.state.disthresh2 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.pvvChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="PVV"
                              ></i></div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup className="select2-container">
                            <Label className="control-label">{this.props.t("pvki")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="PVKI"
                            >
                              {this.props.t("pvkiInfo")}
                            </UncontrolledTooltip>
                            <Select
                              defaultValue={pvki[0]}
                              options={pvki}
                              onChange={this.handleSelectPVKI}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="PVKI"
                              ></i></div>
                          </FormGroup>
                        </Col>

                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="secondary"
                          onClick={() => window.location.reload()}
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          disabled={this.state.buttonTimer}
                          color="primary"
                        >
                          {this.props.t("calculateButton")}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

PinCalculator.propTypes = {
  t: PropTypes.any,
}

export default (withTranslation()(PinCalculator));
