import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "../../pages.scss"
import { getResponse } from "../../../helpers/api_helper"

//i18n
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class APDUResp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      apduResps: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      disthresh1: false,
      disthresh2: false,
      disthresh3: false,
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.dataChange = this.dataChange.bind(this);
    this.dataChange2 = this.dataChange2.bind(this);
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    const cloudInput = {
      sw1: values.sw1,
      sw2: values.sw2,
    };

    getResponse(cloudInput, "/cloud/v1/APDU/APDU")
      .then((resp) => {
        this.setState({ buttonTimer: false })
        if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
          this.setState({ respData: [resp.resultData] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addSuccessItem();
        }
        else {
          this.setState({ respErrorData: [resp.resultDesc] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addFailItem();
        }
      })

  }


  addSuccessItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("apduOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]


    }));


  }
  addFailItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("apduOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>
              </Alert>
            </CardBody>
          </Card>

        </Col>
      ]
    }));

  }

  // dataChange(event) {
  //   const count = event.target.value.maxLength
  //   if (count > 0) {
  //     this.setState({ disthresh1: true })
  //   }
  //   else {
  //     this.setState({ disthresh1: false })
  //   }
  //   this.setState({ dataChange: event.target.value.length })
  // }

  dataChange(event) {
    const count = event.target.value.length;
    if (count > 0) {
      this.setState({ disthresh1: true });
    } else {
      this.setState({ disthresh1: false });
    }
    this.setState({ dataChange: event.target.value.length })
  }


  dataChange2(event) {
    const count = event.target.value.length;
    if (count > 0) {
      this.setState({ disthresh2: true });
    } else {
      this.setState({ disthresh2: false });
    }
    this.setState({ dataChange: event.target.value.length })
  }


  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | {this.props.t("apduResponseQuery")}</title>
              <meta name="description" content="APDU (Application Protocol Data Unit) Response Query is a command-response communication protocol used in smart card technology to exchange data between a smart card and a card reader. Learn about APDU structure, commands, responses, and its usage in smart card transactions." />
              <meta name="keywords" content="APDU, Application Protocol Data Unit, Smart Card, Chip Card, SW1, SW2, Command, Response, Query, Communication Protocol, Transaction, ISO 7816, NFC, Contactless, Payment, Banking, Card Reader, Transaction Processing" />

            </MetaTags>
          </Helmet>

          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("apdu")} breadcrumbItem={this.props.t("apduResponseQuery")} />
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit}>
                      <Row className="rowAes">
                        <Col>
                          <FormGroup>
                            <Label htmlFor="productdesc">
                              {this.props.t("sw1")}
                            </Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Sw1"
                            >
                              {this.props.t("sw1")}
                            </UncontrolledTooltip>
                            <AvField
                              name="sw1"
                              placeholder={this.props.t("fill")}
                              type="text"
                              onChange={this.dataChange}
                              value={this.state.apduResps
                                ? this.state.apduResps.sw1 : ""}
                              className="form-control"
                              validate={{

                                required: { value: true },
                                maxLength: { value: 2 },
                                minLength: { value: 2 }

                              }} />

                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.dataChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Sw1"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <Label htmlFor="productdesc">
                              {this.props.t("sw2")}
                            </Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Sw2"
                            >
                              {this.props.t("sw2")}
                            </UncontrolledTooltip>
                            <AvField
                              name="sw2"
                              placeholder={this.props.t("fill")}
                              type="text"
                              value={this.state.apduResps
                                ? this.state.apduResps.sw2 : ""}
                              className="form-control"
                              onChange={this.dataChange2}
                              validate={{
                                required: { value: true },
                                maxLength: { value: 2 },
                                minLength: { value: 2 }

                              }} />
                            {this.state.disthresh2 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.dataChange}{" Length"}
                              </span>
                            ) : null}

                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Sw2"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="secondary"
                          onClick={() => window.location.reload()}
                          style={{ textAlign: "start" }}
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          disabled={this.state.buttonTimer}
                          color="primary"
                        >
                          {this.props.t("searchButton")}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

APDUResp.propTypes = {
  t: PropTypes.any,
}

export default (withTranslation()(APDUResp));
