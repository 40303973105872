import axios from "axios"
import respData from "../../src/pages/Keys/ThalesKeyBlock/TKBEncode"
//pass new generated access token here
const token = `Bearer ${localStorage.getItem("accessToken")}`;

//apply base url for axios
// export const API_URL = "https://localhost:7266"

export const API_URL = "https://wecloudgateway.azurewebsites.net/"
export const AUTH_URL = "https://wecloudapi.azurewebsites.net"


export const axiosApi = axios.create({
  baseURL: API_URL,
});

export const autAxiosApi = axios.create({
  baseURL: AUTH_URL,
});

autAxiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)


axiosApi.interceptors.response.use(
  response => {
    console.log(response.data.resultData)
  },
  error => {
    return Promise.reject(error)
  }
)

axiosApi.interceptors.request.use(
  (config) => {
    config.headers.Authorization = token;
    return config;
  },
  (error) => Promise.reject(error)
);

autAxiosApi.interceptors.request.use(
  (config) => {
    config.headers.Authorization = token;
    return config;
  },
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config })
    .then(response => response.data)
}

export async function post(url, data, config = {}) {
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => { console.log(response.data.resultData) })
    .catch(errorDB => {
      console.log(errorDB.response.data.ResponseCode, errorDB.response.data.ResponseDescription)
    })
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

export async function getAuth(url, config = {}) {
  return await autAxiosApi.get(url, { ...config }).then(response => response.data)
}

export async function postAuth(url, data, config = {}) {
  return autAxiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}

export async function putAuth(url, data, config = {}) {
  return autAxiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function delAuth(url, config = {}) {
  return await autAxiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}



export async function getResponse(cloudInput, optUrl) {
  return await new Promise(resolve => {
    fetch(API_URL + optUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(cloudInput)

    })
      .then(response => {
        return response.json();
      })
      .then(response => {
        return resolve(response);
      })
      .catch(error => {
        return resolve(error);
      });
  })
}