import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_TEST_DEFINITIONS,
  ADD_NEW_TEST_DEFINITION,
  UPDATE_TEST_DEFINITION,
  DELETE_TEST_DEFINITION,

  GET_MEMBER_DEFINITIONS,
  ADD_NEW_MEMBER_DEFINITION,
  UPDATE_MEMBER_DEFINITION,
  DELETE_MEMBER_DEFINITION,

  GET_PAGE_DEFINITIONS,
  ADD_NEW_PAGE_DEFINITION,
  UPDATE_PAGE_DEFINITION,
  DELETE_PAGE_DEFINITION,

  GET_ROLE_DEFINITIONS,
  ADD_NEW_ROLE_DEFINITION,
  UPDATE_ROLE_DEFINITION,
  DELETE_ROLE_DEFINITION,

  GET_USER_DEFINITIONS,
  ADD_NEW_USER_DEFINITION,
  UPDATE_USER_DEFINITION,
  DELETE_USER_DEFINITION,

} from "./actionTypes"

import {
  getTestDefinitionsSuccess,
  getTestDefinitionsFail,
  addTestDefinitionSuccess,
  addTestDefinitionFail,
  updateTestDefinitionFail,
  updateTestDefinitionSuccess,
  deleteTestDefinitionSuccess,
  deleteTestDefinitionFail,

  getMemberDefinitionsFail,
  getMemberDefinitionsSuccess,
  addMemberDefinitionFail,
  addMemberDefinitionSuccess,
  updateMemberDefinitionFail,
  updateMemberDefinitionSuccess,
  deleteMemberDefinitionFail,
  deleteMemberDefinitionSuccess,

  getPageDefinitionsFail,
  getPageDefinitionsSuccess,
  addPageDefinitionSuccess,
  addPageDefinitionFail,
  updatePageDefinitionFail,
  updatePageDefinitionSuccess,
  deletePageDefinitionFail,
  deletePageDefinitionSuccess,

  getRoleDefinitionsFail,
  getRoleDefinitionsSuccess,
  addRoleDefinitionFail,
  addRoleDefinitionSuccess,
  updateRoleDefinitionFail,
  updateRoleDefinitionSuccess,
  deleteRoleDefinitionFail,
  deleteRoleDefinitionSuccess,

  getUserDefinitionsFail,
  getUserDefinitionsSuccess,
  addUserDefinitionSuccess,
  addUserDefinitionFail,
  updateUserDefinitionFail,
  updateUserDefinitionSuccess,
  deleteUserDefinitionFail,
  deleteUserDefinitionSuccess,

} from "./actions"

import {
  getTestDefinitions,
  addNewTestDefinition,
  updateTestDefinition,
  deleteTestDefinition,

  getMemberDefinitions,
  addNewMemberDefinition,
  updateMemberDefinition,
  deleteMemberDefinition,

  getPageDefinitions,
  addNewPageDefinition,
  updatePageDefinition,
  deletePageDefinition,

  getRoleDefinitions,
  addNewRoleDefinition,
  updateRoleDefinition,
  deleteRoleDefinition,

  getUserDefinitions,
  addNewUserDefinition,
  updateUserDefinition,
  deleteUserDefinition,

} from "../../helpers/fakebackend_helper"


function* fetchTestDefinitions() {
  try {
    const response = yield call(getTestDefinitions)
    yield put(getTestDefinitionsSuccess(response))
  } catch (error) {
    yield put(getTestDefinitionsFail(error))
  }
}

function* onAddNewTestDefinition({ payload: testDefinition }) {
  try {
    const response = yield call(addNewTestDefinition, testDefinition)
    yield put(addTestDefinitionSuccess(response))
  } catch (error) {
    yield put(addTestDefinitionFail(error))
  }
}

function* onUpdateTestDefinition({ payload: testDefinition }) {
  try {
    const response = yield call(updateTestDefinition, testDefinition)
    yield put(updateTestDefinitionSuccess(response))
  } catch (error) {
    yield put(updateTestDefinitionFail(error))
  }
}

function* onDeleteTestDefinition({ payload: testDefinition }) {
  try {
    const response = yield call(deleteTestDefinition, testDefinition)
    yield put(deleteTestDefinitionSuccess(response))
  } catch (error) {
    yield put(deleteTestDefinitionFail(error))
  }
}

// MEMBER DEFINITION

function* fetchMemberDefinitions() {
  try {
    const response = yield call(getMemberDefinitions)
    yield put(getMemberDefinitionsSuccess(response))
  } catch (error) {
    yield put(getMemberDefinitionsFail(error))
  }
}

function* onAddNewMemberDefinition({ payload: memberDefinition }) {
  try {
    const response = yield call(addNewMemberDefinition, memberDefinition)
    yield put(addMemberDefinitionSuccess(response))
  } catch (error) {

    yield put(addMemberDefinitionFail(error))
  }
}

function* onUpdateMemberDefinition({ payload: memberDefinition }) {
  try {
    const response = yield call(updateMemberDefinition, memberDefinition)
    yield put(updateMemberDefinitionSuccess(response))
  } catch (error) {
    yield put(updateMemberDefinitionFail(error))
  }
}

function* onDeleteMemberDefinition({ payload: guid }) {
  try {
    const response = yield call(deleteMemberDefinition, guid)
    yield put(deleteMemberDefinitionSuccess(response))
  } catch (error) {
    yield put(deleteMemberDefinitionFail(error))
  }
}

// PAGE DEFINITION

function* fetchPageDefinitions() {
  try {
    const response = yield call(getPageDefinitions)
    yield put(getPageDefinitionsSuccess(response))
  } catch (error) {
    yield put(getPageDefinitionsFail(error))
  }
}

function* onAddNewPageDefinition({ payload: pageDefinition }) {
  try {
    const response = yield call(addNewPageDefinition, pageDefinition)
    yield put(addPageDefinitionSuccess(response))
  } catch (error) {

    yield put(addPageDefinitionFail(error))
  }
}

function* onUpdatePageDefinition({ payload: pageDefinition }) {
  try {
    const response = yield call(updatePageDefinition, pageDefinition)
    yield put(updatePageDefinitionSuccess(response))
  } catch (error) {
    yield put(updatePageDefinitionFail(error))
  }
}

function* onDeletePageDefinition({ payload: guid }) {
  try {
    const response = yield call(deletePageDefinition, guid)
    yield put(deletePageDefinitionSuccess(response))
  } catch (error) {
    yield put(deletePageDefinitionFail(error))
  }
}

// ROLE DEFINITION 

function* fetchRoleDefinitions() {
  try {
    const response = yield call(getRoleDefinitions)
    yield put(getRoleDefinitionsSuccess(response))
  } catch (error) {
    yield put(getRoleDefinitionsFail(error))
  }
}

function* onAddNewRoleDefinition({ payload: roleDefinition }) {
  try {
    const response = yield call(addNewRoleDefinition, roleDefinition)
    yield put(addRoleDefinitionSuccess(response))
  } catch (error) {

    yield put(addRoleDefinitionFail(error))
  }
}

function* onUpdateRoleDefinition({ payload: roleDefinition }) {
  try {
    const response = yield call(updateRoleDefinition, roleDefinition)
    yield put(updateRoleDefinitionSuccess(response))
  } catch (error) {
    yield put(updateRoleDefinitionFail(error))
  }
}

function* onDeleteRoleDefinition({ payload: guid }) {
  try {
    const response = yield call(deleteRoleDefinition, guid)
    yield put(deleteRoleDefinitionSuccess(response))
  } catch (error) {
    yield put(deleteRoleDefinitionFail(error))
  }
}

// USER DEFINITION

function* fetchUserDefinitions() {
  try {
    const response = yield call(getUserDefinitions)
    yield put(getUserDefinitionsSuccess(response))
  } catch (error) {
    yield put(getUserDefinitionsFail(error))
  }
}

function* onAddNewUserDefinition({ payload: userDefinition }) {
  try {
    const response = yield call(addNewUserDefinition, userDefinition)
    yield put(addUserDefinitionSuccess(response))
  } catch (error) {

    yield put(addUserDefinitionFail(error))
  }
}

function* onUpdateUserDefinition({ payload: userDefinition }) {
  try {
    const response = yield call(updateUserDefinition, userDefinition)
    yield put(updateUserDefinitionSuccess(response))
  } catch (error) {
    yield put(updateUserDefinitionFail(error))
  }
}

function* onDeleteUserDefinition({ payload: guid }) {
  try {
    const response = yield call(deleteUserDefinition, guid)
    yield put(deleteUserDefinitionSuccess(response))
  } catch (error) {
    yield put(deleteUserDefinitionFail(error))
  }
}

function* testDefinitionSaga() {
  yield takeEvery(GET_TEST_DEFINITIONS, fetchTestDefinitions)
  yield takeEvery(ADD_NEW_TEST_DEFINITION, onAddNewTestDefinition)
  yield takeEvery(UPDATE_TEST_DEFINITION, onUpdateTestDefinition)
  yield takeEvery(DELETE_TEST_DEFINITION, onDeleteTestDefinition)

  yield takeEvery(GET_MEMBER_DEFINITIONS,fetchMemberDefinitions)
  yield takeEvery(ADD_NEW_MEMBER_DEFINITION,onAddNewMemberDefinition)
  yield takeEvery(UPDATE_MEMBER_DEFINITION,onUpdateMemberDefinition)
  yield takeEvery(DELETE_MEMBER_DEFINITION,onDeleteMemberDefinition)

  yield takeEvery(GET_PAGE_DEFINITIONS,fetchPageDefinitions)
  yield takeEvery(ADD_NEW_PAGE_DEFINITION,onAddNewPageDefinition)
  yield takeEvery(UPDATE_PAGE_DEFINITION,onUpdatePageDefinition)
  yield takeEvery(DELETE_PAGE_DEFINITION,onDeletePageDefinition)

  yield takeEvery(GET_ROLE_DEFINITIONS,fetchRoleDefinitions)
  yield takeEvery(ADD_NEW_ROLE_DEFINITION,onAddNewRoleDefinition)
  yield takeEvery(UPDATE_ROLE_DEFINITION,onUpdateRoleDefinition)
  yield takeEvery(DELETE_ROLE_DEFINITION,onDeleteRoleDefinition)

  yield takeEvery(GET_USER_DEFINITIONS,fetchUserDefinitions)
  yield takeEvery(ADD_NEW_USER_DEFINITION,onAddNewUserDefinition)
  yield takeEvery(UPDATE_USER_DEFINITION,onUpdateUserDefinition)
  yield takeEvery(DELETE_USER_DEFINITION,onDeleteUserDefinition)
}

export default testDefinitionSaga