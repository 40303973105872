import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "../../pages.scss"

import { getResponse } from "../../../helpers/api_helper"
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";


class TTQDecoder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tlvParsers: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      histjson: [],
      items: [],
      isCustomTlv: false,
      disthresh1: false,
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.dataChange = this.dataChange.bind(this)
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    const cloudInput = {
      data: values.data,
    }
    getResponse(cloudInput, "/cloud/v1/TTQ/TTQDecode")
      .then((resp) => {
        this.setState({ buttonTimer: false })
        if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
          this.setState({ respData: [resp.resultData] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addSuccessItem();
        }
        else {
          this.setState({ respErrorData: [resp.resultDesc] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addFailItem();
        }
      })
  }

  addSuccessItem = () => {

    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("ttqDecoderOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>

      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-success" role="alert">
    //         <h4 className="alert-heading">TTQ decode operation finished with success</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // )
    // this.setState({ items: [...this.state.items] })
  }

  addFailItem = () => {

    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("ttqDecoderOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>

              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-danger" role="alert">
    //         <h4 className="alert-heading">TTQ decode operation finished with fail</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respErrorData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // );
    // this.setState({ items: [...this.state.items] })
  }

  dataChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ dataChange: event.target.value.length })
  }



  render() {
    const defaultValues = { searchOption: true }
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | TTQ Decoder</title>
              <meta name="description" content="TERMINAL TRANSACTION QUALIFIERS (EMV Tag 9F66) indicates the reader capabilities, requirements, and preferences to the card during a transaction. Learn about the structure and significance of TERMINAL TRANSACTION QUALIFIERS in EMV chip card transactions, its role in conveying reader preferences to the card, and its importance in facilitating secure and efficient payment processing." />
              <meta name="keywords" content="TERMINAL TRANSACTION QUALIFIERS, EMV Tag 9F66, reader capabilities, transaction requirements, reader preferences, chip card transactions, payment processing, secure transactions, transaction security" />
            </MetaTags>
          </Helmet>

          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="TTQ (Tag 9F66) " breadcrumbItem="Terminal Transaction Qualifiers" />
            <div>
              Indicates reader capabilities, requirements, and preferences to the card.
              <br />
              <br />
              <br />
            </div>
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit} >

                      <Row className="rowAes">
                        <Col>
                          <FormGroup>
                            <Label htmlFor="productdesc">
                              {this.props.t("TTQ")}
                            </Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Data"
                            >Data: TTQ data to decode.
                            </UncontrolledTooltip>
                            <AvField
                              style={{ height: 30 }}
                              name="data"
                              placeholder={this.props.t("8 hex digits")}
                              type="textarea"
                              className="form-control"
                              onChange={this.dataChange}
                              validate={{
                                pattern: { value: /^[0-9A-Fa-f]+$/ },
                                required: { value: true },
                                maxLength: { value: 8 },
                                minLength: { value: 8 }
                              }} />
                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.dataChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Data"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          onClick={() => window.location.reload()}
                          color="secondary"
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          disabled={this.state.buttonTimer}
                          color="primary"
                        >
                          {this.props.t("decodeButton")}
                        </Button>
                      </div>

                    </AvForm>
                  </CardBody>
                </Card>
              </Col>

              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
TTQDecoder.propTypes = {
  t: PropTypes.any
}
export default (withTranslation()(TTQDecoder));