import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import "../../pages.scss"

import { getResponse } from "../../../helpers/api_helper"

//i18n
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class Hash extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hashs: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      disthresh1: false,
      disthresh2: false,
      disthresh3: false,
      hashType: []
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.inputDataChange = this.inputDataChange.bind(this);
    this.handleSelectHashType = this.handleSelectHashType.bind(this)
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    const cloudInput = {
      data: values.inputData,
      hashType: this.state.hashs.hashType,
      dataType: values.dataInput,
    };

    getResponse(cloudInput, "/cloud/v1/Hash/Hash")
      .then((resp) => {
        this.setState({ buttonTimer: false })
        if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
          this.setState({ respData: [resp.resultData] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addSuccessItem();
        }
        else {
          this.setState({ respErrorData: [resp.resultDesc] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addFailItem();
        }
      })
  }

  addSuccessItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("hashOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-success" role="alert">
    //         <h4 className="alert-heading">{this.props.t("hashOperationSuccess")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // )
    // this.setState({ items: [...this.state.items] })

  }
  addFailItem = () => {

    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("hashOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-danger" role="alert">
    //         <h4 className="alert-heading">{this.props.t("hashOperationFail")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respErrorData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // );
    // this.setState({ items: [...this.state.items] })
  }

  inputDataChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ inputDataChange: event.target.value.length })
  }

  handleSelectHashType(e) {
    this.setState({
      hashs: ({
        ...this.state.hashs,
        hashType: e.value
      })
    })
  }

  render() {
    const hashTypes = [
      { value: "MD5", label: this.props.t("md5") },
      { value: "SHA1", label: this.props.t("sha1") },
      { value: "SHA256", label: this.props.t("sha256") },
      { value: "SHA384", label: this.props.t("sha384") },
      { value: "SHA512", label: this.props.t("sha512") },
    ]
    const defaultValues = { dataInput: "Hexadecimal" }

    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | {this.props.t("hash")}</title>
              <meta name="description" content="Hashing is a cryptographic technique used to convert data into a fixed-size string of characters, which is typically a unique representation of the original data. Learn about hash functions, their applications in data integrity verification, password storage, and digital signatures." />
              <meta name="keywords" content="Hashing, hash function, cryptographic hash, data integrity, password security, digital signatures, cryptographic techniques, hashing algorithms" />
            </MetaTags>
          </Helmet>

          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("hash")} breadcrumbItem={this.props.t("hashOperation")} />
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit} model={defaultValues}>
                      <Row className="rowAes">
                        <Col sm="12">
                          <FormGroup className="select2-container">
                            <Label className="control-label">{this.props.t("hashType")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="HashType"
                            >
                              {this.props.t("hashTypeInfo")}
                            </UncontrolledTooltip>
                            <Select
                              options={hashTypes}
                              onChange={this.handleSelectHashType}
                              placeholder={this.props.t("select")}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="HashType"
                              ></i></div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("inputData")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="InputData"
                            >
                              {this.props.t("inputDataInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="inputData"
                              placeholder={this.props.t("fill")}
                              type="input"
                              value={this.state.hashs
                                ? this.state.hashs.inputData : ""}
                              onChange={this.inputDataChange}
                              validate={{
                                required: { value: true }
                              }}
                            />
                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.inputDataChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="InputData"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="DataInput"
                            >
                              {this.props.t("inputDataTypeInfo")}
                            </UncontrolledTooltip>
                            <AvRadioGroup inline name="dataInput" errorMessage={this.props.t("pickOne")} required>
                              <h6>{this.props.t("inputDataType")}</h6>
                              <AvRadio label={this.props.t("ascii")} value="ASCII" />
                              <AvRadio label={this.props.t("hexadecimal")} value="Hexadecimal" />
                            </AvRadioGroup>
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="DataInput"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="secondary"
                          onClick={() => window.location.reload()}
                          style={{ textAlign: "start" }}
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          disabled={this.state.buttonTimer}
                          type="submit"
                          color="primary"
                        >
                          {this.props.t("hashButton")}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Hash.propTypes = {
  t: PropTypes.any,
}

export default (withTranslation()(Hash));
