import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { getResponse } from "../../../helpers/api_helper"
import "../../pages.scss"

//i18n
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class TR31KBDecode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tr31KBDecodes: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      disthresh1: false,
      disthresh2: false,
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleResetForm = this.handleResetForm.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.kbpkChange = this.kbpkChange.bind(this);
    this.keyBlockChange = this.keyBlockChange.bind(this)
  }

  handleValidSubmit = (e, values) => {
    const { tr31KBDecodes } = this.state;

    const cloudInput = {
      kbpk: values.kbpk,
      keyBlock: values.keyBlock,
    };
    (cloudInput)
    // onTKBDecode(cloudInput)
    getResponse(cloudInput, "/cloud/v1/ThalesKeyblock/Decode")
      .then((resp) => {
        if (resp.resultCode !== "-1") {
          this.setState({ respData: [resp.resultData] })
          this.addSuccessItem();
        }
        else {
          this.setState({ respErrorData: [resp.resultDesc] })
          this.addFailItem();
        }
      })

  }

  handleResetForm = (e, f) => {
    this.form && this.form.reset()
  }

  addSuccessItem = () => {
    this.state.items.push(<Col key={Date.now()} >
      <Card>
        <CardBody>
          <Alert className="alert-success" role="alert">
            <h4 className="alert-heading">Key block encrypt operation finished with success</h4>
            <hr />
            <div className="display-linebreak">{this.state.respData}</div>
          </Alert>
        </CardBody>
      </Card>
    </Col>
    )
    this.setState({ items: [...this.state.items] })
  }
  addFailItem = () => {
    this.state.items.push(<Col key={Date.now()} >
      <Card>
        <CardBody>
          <Alert className="alert-danger" role="alert">
            <h4 className="alert-heading">Key block encrypt operation finished with fail</h4>
            <hr />
            <div className="display-linebreak">{this.state.respErrorData}</div>
          </Alert>
        </CardBody>
      </Card>
    </Col>
    );
    this.setState({ items: [...this.state.items] })
  }

  kbpkChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ kbpkChange: event.target.value.length })
  }

  keyBlockChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh2: true })
    } else {
      this.setState({ disthresh2: false })
    }
    this.setState({ keyBlockChange: event.target.value.length })
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | {this.props.t("tr31KeyBlock")} {this.props.t("decode")}</title>
              <meta name="description" content="TR-31 Key Block Decode refers to the process of decrypting key blocks encoded according to the TR-31 (Technical Report 31) standard, which specifies a method for securely exchanging cryptographic keys between devices such as point-of-sale (POS) terminals and key injection facilities. Explore the principles and techniques behind TR-31 key block decoding, its applications in secure key management, and its significance in ensuring data confidentiality and integrity in payment systems." />
              <meta name="keywords" content="TR-31 Key Block Decode, TR-31, Technical Report 31, key block decryption, key management, secure key exchange, POS terminals, key injection, data confidentiality, data integrity, payment systems" />
            </MetaTags>
          </Helmet>
          
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("tr31KeyBlock")} breadcrumbItem={this.props.t("decode")} />

            <Row>
              <Col xs="6">
                <Card>
                  <CardBody>
                    <p className="card-title-desc">{this.props.t("fillAllInformationBelow")}</p>
                    <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("kbpk")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Kbpk"
                            >
                              {this.props.t("kbpkInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="kbpk"
                              type="input"
                              placeholder={this.props.t("fill")}
                              value={this.state.tr31KBDecodes.kbpk}
                              onChange={this.kbpkChange}
                              validate={{
                                pattern: { value: /^[A-Fa-f0-9]+$/ },
                                required: { value: true }
                              }}
                            />
                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.kbpkChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Kbpk"
                              ></i></div>
                          </FormGroup>
                          <FormGroup>
                            <Label htmlFor="productdesc">
                              {this.props.t("keyBlock")}
                            </Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="KeyBlock"
                            >
                              {this.props.t("keyBlockInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="keyBlock"
                              placeholder={this.props.t("fill")}
                              type="textarea"
                              value={this.state.tr31KBDecodes.keyBlock}
                              onChange={this.keyBlockChange}
                              className="form-control"
                              validate={{
                                required: { value: true }
                              }}
                              id="keyBlock"
                            />
                            {this.state.disthresh2 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.keyBlockChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="KeyBlock"
                              ></i></div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2"
                        style={{
                          paddingTop: 6,
                        }}
                      >
                        <Button
                          onClick={() => window.location.reload()}
                          color="secondary"
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={this.state.buttonTimer}
                        >
                          {this.props.t("decodeButton")}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
TR31KBDecode.propTypes = {
  t: PropTypes.any,
}


export default (withTranslation()(TR31KBDecode));
