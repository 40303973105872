import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

import TwostepVerification from "../pages/Authentication/auth-two-step-verification";
import TwostepReadQr from "../pages/Authentication/auth-two-step-readqr";
import LockScreen from "../pages/Authentication/auth-lock-screen";


//Integration related pages
import TKBEncode from "../pages/Keys/ThalesKeyBlock/TKBEncode";
import TKBDecode from "pages/Keys/ThalesKeyBlock/TKBDecode";
import Dashboard from "pages/Dashboard";
import TR31KBDecode from "pages/Keys/TR-31KeyBlock/TR31KBDecode"
import TR31KBEncode from "pages/Keys/TR-31KeyBlock/TR31KBEncode"
import AESEncrypt from "pages/Keys/AES/AESEncrypt"
import DESEncrypt from "pages/Keys/DES/DESEncrypt"
import ICCMasterKey from "pages/Keys/ICCMasterKey/ICCMasterKey";
import MACDerivation from "pages/Keys/MACDerivation/MACDerivation";
import RSAOpt from "pages/Keys/RSA/RSAOpt"
import TLVParser from "pages/Keys/TLVParser/TLVParser";
import EMVTagDic from "pages/Keys/EMVTagDictionary/EMVTagDictionary";
import APDUResp from "pages/Keys/APDUResponseQuery/APDUResponseQuery";
import PinBlockAES from "pages/Keys/PinBlocksAES/PinBlockAESEncrypt";
import PinBlockGEncode from "pages/Keys/PinBlocksGeneral/PinBlockGEncode";
import PinBlockGDecode from "pages/Keys/PinBlocksGeneral/PinBlockGDecode";
import CvvGenerate from "pages/Keys/CVV/CvvGenerate"
import CvvValidate from "pages/Keys/CVV/CvvValidate"
import Hash from "pages/Keys/Hash/Hash"
import Bitwise from "pages/Keys/Bitwise/Bitwise";
import BitShift from "pages/Keys/Bitwise/BitShift";
import Conversions from "pages/Keys/Conversions/Conversions";
import PinCalculator from "pages/Keys/PinPvvCalculator/PinCalculator";
import PvvCalculator from "pages/Keys/PinPvvCalculator/PvvCalculator";
import Version from "pages/Version";
import CAPublicKey from "pages/Keys/CAPublicKey/CAPublicKey";
import AID from "pages/Keys/AID/AID";
import TVRDecoder from "pages/Keys/TVRDecoder/TVRDecoder";
import AipDecoder from "pages/Keys/AIP/AipDecoder";
import CVMDecoder from "pages/Keys/CVMDecoder/CVMDecoder";
import TermCap from "pages/Keys/TermCapOperation/TermCap";
import CVMResults from "pages/Keys/CVMResultsOperation/CVMResults";
import CtqDecoder from "pages/Keys/CTQ/CtqDecoder";
import TTQDecoder from "pages/Keys/TTQ/TTQDecoder";
import AppUsageControlDecoder from "pages/Keys/AppUsageControl/AppUsageControlDecoder";
import MChipAdvanceCVR from "pages/Keys/CVR/MChipAdvanceCVR";
import SubscribeOperation from "pages/Keys/Subscribe/SubscribeOperation";
import UserVerificationOperation from "pages/Keys/UserVerification/UserVerificationOperation";


import IssuerAppData from "pages/Keys/IssuerAppData/IssuerAD";




const authProtectedRoutes = [
  { path: "/TKBEncode", component: TKBEncode },
  { path: "/TKBDecode", component: TKBDecode },
  { path: "/Dashboard", component: Dashboard },
  { path: "/MACDerivation", component: MACDerivation },
  { path: "/TR31KBEncode", component: TR31KBEncode },
  { path: "/UDK", component: ICCMasterKey },
  { path: "/MACDerivation", component: MACDerivation },
  { path: "/TR31KBDecode", component: TR31KBDecode },
  { path: "/AESEncrypt", component: AESEncrypt },
  { path: "/DESEncrypt", component: DESEncrypt },
  { path: "/RSAOpt", component: RSAOpt },
  { path: "/TLVParser", component: TLVParser },
  { path: "/EMVTagDic", component: EMVTagDic },
  { path: "/APDUResp", component: APDUResp },
  { path: "/PinBlockAES", component: PinBlockAES },
  { path: "/PinBlockGEncode", component: PinBlockGEncode },
  { path: "/PinBlockGDecode", component: PinBlockGDecode },
  { path: "/CvvGenerate", component: CvvGenerate },
  { path: "/CvvValidate", component: CvvValidate },
  { path: "/CAPublicKey", component: CAPublicKey },
  { path: "/Hash", component: Hash },
  { path: "/Bitwise", component: Bitwise },
  { path: "/BitShift", component: BitShift },
  { path: "/Conversions", component: Conversions },
  { path: "/PinCalculator", component: PinCalculator},
  { path: "/PvvCalculator", component: PvvCalculator},
  { path : "/applicationID" , component: AID},
  { path: "/TvrDecoder", component: TVRDecoder},
  { path: "/CvmDecoder", component: CVMDecoder},
  { path: "/AipDecoder", component: AipDecoder},
  { path: "/TermCapOperation", component: TermCap},
  { path: "/CVMResults", component: CVMResults},
  { path: "/CTQDecoder", component: CtqDecoder},
  { path: "/TTQDecoder", component: TTQDecoder},
  { path: "/AppUsageControl", component: AppUsageControlDecoder},
  { path: "/MChipAdvanceCVR", component: MChipAdvanceCVR},
  { path: "/SubscribeOperation", component: SubscribeOperation},
  { path: "/UserVerificationOperation", component:UserVerificationOperation },
  
  { path: "/IssuerAppData", component: IssuerAppData},


  
 


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/Dashboard" /> },
  { path: "/Version", component: Version}
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-readqr", component: TwostepReadQr },
  { path: "/auth-lock-screen", component: LockScreen },

];

export { authProtectedRoutes, publicRoutes };
