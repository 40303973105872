import {
  TKBENCODE_SUCCESS,
  TKBENCODE_FAIL,
  TKBDECODE_FAIL,
  TKBDECODE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  tkbEncodes: [],
  tkbDecodes:[],
  error: {},
}


const TKB = (state = INIT_STATE, action) => {
  switch (action.type) {

    case TKBENCODE_SUCCESS:
      return {
        ...state,
        tkbEncodes: [...state.tkbEncodes, action.payload],
      }

    case TKBENCODE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      
      case TKBDECODE_SUCCESS:
        return {
          ...state,
          tkbDecodes: [...state.tkbDecodes, action.payload],
        }
  
      case TKBDECODE_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
    default:

      return state
  }
}

export default TKB;
