import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import "../../pages.scss"

import { getResponse } from "../../../helpers/api_helper"

//i18n
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class RSAOpt extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rsaOpts: [],
      activeTab: "1",
      isMenu: false,
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      disthresh1: false,
      disthresh2: false,
      disthresh3: false,
      disthresh4: false,
      disthresh5: false,
      disthresh6: false,
      disthresh7: false,
      disthresh8: false,
      disthresh9: false,
      publicExpDsb: true,
      modulusDsb: true,
      privateExpDsb: true,
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.dataEncChange = this.dataEncChange.bind(this)
    this.dataDecChange = this.dataDecChange.bind(this)
    this.dataSignChange = this.dataSignChange.bind(this)
    this.dataVerifyChange = this.dataVerifyChange.bind(this)
    this.hashVerifyChange = this.hashVerifyChange.bind(this)
    this.toggleTab = this.toggleTab.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.keyLengthChange = this.keyLengthChange.bind(this)
    this.privateExpChange = this.privateExpChange.bind(this)
    this.publicExpChange = this.publicExpChange.bind(this)
    this.modulusChange = this.modulusChange.bind(this)
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    let cloudInput;
    if (this.state.activeTab == "1") {
      cloudInput = {
        keyLength: values.keyLength,
        optType: 1,
      }

    }
    else if (this.state.activeTab == "2") {
      cloudInput = {
        encodingMethod: values.encodingMethod,
        inputDataFormat: values.inputDataFormat,
        paddingMethod: values.paddingMethod,
        dataEnc: values.dataEnc,
        modulus: this.state.rsaOpts.modulus,
        privateExp: this.state.rsaOpts.privateExp,
        publicExp: this.state.rsaOpts.publicExp,
        optType: 2,
      }
    }
    else if (this.state.activeTab == "3") {
      cloudInput = {
        decodingMethod: values.decodingMethod,
        paddingMethod: values.paddingMethodDec,
        dataDec: values.dataDec,
        modulus: this.state.rsaOpts.modulus,
        privateExp: this.state.rsaOpts.privateExp,
        publicExp: this.state.rsaOpts.publicExp,
        optType: 3,
      }
    }
    else if (this.state.activeTab == "4") {
      cloudInput = {
        inputDataFormat: values.inputDataFormatSign,
        dataSign: values.dataSign,
        modulus: this.state.rsaOpts.modulus,
        privateExp: this.state.rsaOpts.privateExp,
        publicExp: this.state.rsaOpts.publicExp,
        optType: 4,
      }
    }
    else if (this.state.activeTab == "5") {
      cloudInput = {
        hashVerify: values.hashVerify,
        dataVerify: values.dataVerify,
        modulus: this.state.rsaOpts.modulus,
        privateExp: this.state.rsaOpts.privateExp,
        publicExp: this.state.rsaOpts.publicExp,
        optType: 5,
      }
    }
    getResponse(cloudInput, "/cloud/v1/RsaCryptoUtil/RsaOperations")
      .then((resp) => {
        this.setState({ buttonTimer: false })
        if (resp.cloudResponse.resultCode !== "-1" && resp.cloudResponse.resultCode !== undefined) {
          this.setState({ respData: [resp.cloudResponse.resultData] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp.cloudResponse)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp.cloudResponse]));
          }
          this.addSuccessItem();
          if (this.state.activeTab == "1") {
            this.setState({ rsaOpts: { privateExp: resp.privateExp, modulus: resp.modulus, publicExp: resp.publicExp } })
          }
        }
        else {
          this.setState({ respErrorData: [resp.cloudResponse.resultDesc] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp.cloudResponse)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp.cloudResponse]));
          }
          this.addFailItem()
        }
      })

  }


  addSuccessItem = () => {
    this.state.items.push(<Col key={Date.now()} >
      <Card>
        <CardBody>
          <Alert className="alert-success" role="alert">
            <h4 className="alert-heading">{this.props.t("rsaOperationSuccess")}</h4>
            <hr />
            <div className="display-linebreak">{this.state.respData}</div>
          </Alert>
        </CardBody>
      </Card>
    </Col>
    )
    this.setState({ items: [...this.state.items] })

  }
  addFailItem = () => {
    this.state.items.push(<Col key={Date.now()} >
      <Card>
        <CardBody>
          <Alert className="alert-danger" role="alert">
            <h4 className="alert-heading">{this.props.t("rsaOperationFail")}</h4>
            <hr />
            <div className="display-linebreak">{this.state.respErrorData}</div>
          </Alert>
        </CardBody>
      </Card>
    </Col>
    );
    this.setState({ items: [...this.state.items] })
  }

  dataEncChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ dataEncChange: event.target.value.length })
  }

  dataDecChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh2: true })
    } else {
      this.setState({ disthresh2: false })
    }
    this.setState({ dataDecChange: event.target.value.length })
  }

  dataSignChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh3: true })
    } else {
      this.setState({ disthresh3: false })
    }
    this.setState({ dataSignChange: event.target.value.length })
  }

  hashVerifyChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh4: true })
    } else {
      this.setState({ disthresh4: false })
    }
    this.setState({ hashVerifyChange: event.target.value.length })
  }

  dataVerifyChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh5: true })
    } else {
      this.setState({ disthresh5: false })
    }
    this.setState({ dataVerifyChange: event.target.value.length })
  }

  modulusChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh6: true })
    } else {
      this.setState({ disthresh6: false })
    }
    this.setState({ modulusChange: event.target.value.length, rsaOpts: ({ ...this.state.rsaOpts, modulus: event.target.value }) })
  }

  publicExpChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh7: true })
    } else {
      this.setState({ disthresh7: false })
    }
    this.setState({ publicExpChange: event.target.value.length, rsaOpts: ({ ...this.state.rsaOpts, publicExp: event.target.value }) })
  }

  privateExpChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh8: true })
    } else {
      this.setState({ disthresh8: false })
    }
    this.setState({ privateExpChange: event.target.value.length, rsaOpts: ({ ...this.state.rsaOpts, privateExp: event.target.value }) })
  }

  keyLengthChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh9: true })
    } else {
      this.setState({ disthresh9: false })
    }
    this.setState({ keyLengthChange: event.target.value.length })
  }

  toggleTab(tab) {
    if (this.state.rsaOpts.publicExp != null && this.state.rsaOpts.publicExp != "" && this.state.rsaOpts.privateExp != null && this.state.rsaOpts.privateExp != "" && this.state.rsaOpts.modulus != null && this.state.rsaOpts.modulus != "") {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab,
        })
      }
    }
    else {
      this.state.items.push(
        <Card key={Date.now()} >
          <CardBody>
            <Alert className="alert-danger" role="alert">
              <h4 className="alert-heading">{this.props.t("youHaveToGenerateAKey")}</h4>
              <div className="display-linebreak">{this.state.respErrorData}</div>
            </Alert>
          </CardBody>
        </Card>
      );
      this.setState({ items: [...this.state.items] })
    }
  }

  toggleMenu() {
    this.setState(prevState => ({
      isMenu: !prevState.isMenu,
    }))
  }

  render() {
    const defaultValues = { encodingMethod: "Private", inputDataFormat: "2", paddingMethod: "PKCS1", decodingMethod: "Private", paddingMethodDec: "PKCS1", inputDataFormatSign: "2" }

    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | {this.props.t("rsa")}</title>
              <meta name="description" content="RSA (Rivest-Shamir-Adleman) is a widely used asymmetric cryptographic algorithm for secure data transmission and encryption. Explore the principles and techniques behind RSA encryption and decryption, its applications in digital signatures, key exchange, and secure communication, and its significance in ensuring data confidentiality and integrity." />
              <meta name="keywords" content="RSA, Rivest-Shamir-Adleman, asymmetric encryption, cryptographic algorithm, digital signatures, key exchange, secure communication, data confidentiality, data integrity" />
            </MetaTags>
          </Helmet>

          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("rsa")} breadcrumbItem={this.props.t("rsaOperations")} />
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <Row className="rowAes">
                      <Col lg="12">
                        <Card>
                          <div className="crypto-buy-sell-nav">
                            <Nav tabs className="nav-tabs-custom" role="tablist">
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: this.state.activeTab === "1",
                                  })}
                                  onClick={() => {
                                    this.toggleTab("1")
                                  }}
                                >
                                  {this.props.t("Keys")}
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: this.state.activeTab === "2",
                                  })}
                                  onClick={() => {
                                    this.toggleTab("2")
                                  }}
                                >
                                  {this.props.t("encrypt")}
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: this.state.activeTab === "3",
                                  })}
                                  onClick={() => {
                                    this.toggleTab("3")
                                  }}
                                >
                                  {this.props.t("decrypt")}
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: this.state.activeTab === "4",
                                  })}
                                  onClick={() => {
                                    this.toggleTab("4")
                                  }}
                                >
                                  {this.props.t("sign")}
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: this.state.activeTab === "5",
                                  })}
                                  onClick={() => {
                                    this.toggleTab("5")
                                  }}
                                >
                                  {this.props.t("verify")}
                                </NavLink>
                              </NavItem>

                            </Nav>

                            <TabContent
                              activeTab={this.state.activeTab}
                              className="crypto-buy-sell-nav-content p-4"
                            >
                              <TabPane tabId="1" id="keys">
                                <AvForm onValidSubmit={this.handleValidSubmit} model={defaultValues}>
                                  <Col>
                                    <FormGroup>
                                      <Label htmlFor="productdesc">
                                        {this.props.t("modulusN")}
                                      </Label>
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target="Modulus"
                                      >{this.props.t("modulusNInfo")}</UncontrolledTooltip>
                                      <AvField
                                        style={{ height: 100 }}
                                        name="modulus"
                                        placeholder={this.props.t("fill")}
                                        type="textarea"
                                        value={this.state.rsaOpts
                                          ? this.state.rsaOpts.modulus : ""}
                                        className="form-control"
                                        onChange={this.modulusChange}
                                        validate={{ required: { value: false } }} />
                                      {this.state.disthresh6 ? (
                                        <span className="badgecount badge bg-success"
                                          style={{
                                            position: "absolute",
                                            marginTop: 1,
                                            marginLeft: 3
                                          }}
                                        >
                                          {this.state.modulusChange}{" Length"}
                                        </span>
                                      ) : null}
                                      <div className="infoSymbol">
                                        <i
                                          className="dripicons-information "
                                          id="Modulus"
                                        ></i>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label htmlFor="productdesc">
                                        {this.props.t("publicExponentE")}
                                      </Label>
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target="PublicExp"
                                      >
                                        {this.props.t("publicExponentEInfo")}
                                      </UncontrolledTooltip>
                                      <AvField
                                        name="publicExp"
                                        placeholder={this.props.t("fill")}
                                        type="text"
                                        value={this.state.rsaOpts
                                          ? this.state.rsaOpts.publicExp : ""}
                                        className="form-control"
                                        onChange={this.publicExpChange}
                                        validate={{ required: { value: false } }} />
                                      {this.state.disthresh7 ? (
                                        <span className="badgecount badge bg-success"
                                          style={{
                                            position: "absolute",
                                            marginTop: 1,
                                            marginLeft: 3
                                          }}
                                        >
                                          {this.state.publicExpChange}{" Length"}
                                        </span>
                                      ) : null}
                                      <div className="infoSymbol">
                                        <i
                                          className="dripicons-information "
                                          id="PublicExp"
                                        ></i>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label htmlFor="productdesc">
                                        {this.props.t("privateExponentD")}
                                      </Label>
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target="PrivateExp"
                                      >
                                        {this.props.t("privateExponentDInfo")}
                                      </UncontrolledTooltip>
                                      <AvField
                                        style={{ height: 100 }}
                                        name="privateExp"
                                        placeholder={this.props.t("fill")}
                                        type="textarea"
                                        value={this.state.rsaOpts
                                          ? this.state.rsaOpts.privateExp : ""}
                                        className="form-control"
                                        onChange={this.privateExpChange}
                                        validate={{ required: { value: false } }} />
                                      {this.state.disthresh8 ? (
                                        <span className="badgecount badge bg-success"
                                          style={{
                                            position: "absolute",
                                            marginTop: 1,
                                            marginLeft: 3
                                          }}
                                        >
                                          {this.state.privateExpChange}{" Length"}
                                        </span>
                                      ) : null}
                                      <div className="infoSymbol">
                                        <i
                                          className="dripicons-information "
                                          id="PrivateExp"
                                        ></i>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label htmlFor="productdesc">
                                        {this.props.t("keyLength")}
                                      </Label>
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target="KeyLength"
                                      >
                                        {this.props.t("keyLengthInfo")}
                                      </UncontrolledTooltip>
                                      <AvField
                                        name="keyLength"
                                        placeholder={this.props.t("fill")}
                                        type="number"
                                        value={this.state.rsaOpts
                                          ? this.state.rsaOpts.keyLength : ""}
                                        className="form-control"
                                        onChange={this.keyLengthChange}
                                        validate={{ required: { value: true } }} />
                                      {this.state.disthresh9 ? (
                                        <span className="badgecount badge bg-success"
                                          style={{
                                            position: "absolute",
                                            marginTop: 1,
                                            marginLeft: 3
                                          }}
                                        >
                                          {this.state.keyLengthChange}{" Length"}
                                        </span>
                                      ) : null}
                                      <div className="infoSymbol">
                                        <i
                                          className="dripicons-information "
                                          id="KeyLength"
                                        ></i>
                                      </div>
                                    </FormGroup>
                                    <div className="d-flex flex-wrap gap-2">
                                      <Button
                                        color="secondary"
                                        onClick={() => window.location.reload()}
                                        style={{ textAlign: "start" }}
                                        className="buttonDecode"
                                      >
                                        {this.props.t("clearButton")}
                                      </Button>
                                      <Button
                                        type="submit"
                                        color="primary"
                                      >
                                        {this.props.t("generateKeyButton")}
                                      </Button>
                                    </div>
                                  </Col>
                                </AvForm>
                              </TabPane>


                              <TabPane tabId="2" id="encrypt">
                                <AvForm onValidSubmit={this.handleValidSubmit} model={defaultValues}>
                                  <Col>
                                    <FormGroup>
                                      <AvRadioGroup inline name="inputDataFormat" required errorMessage={this.props.t("pickOne")}>
                                        <UncontrolledTooltip
                                          placement="bottom"
                                          target="InputDataFormat"
                                        >
                                          {this.props.t("inputDataFormatInfo")}
                                        </UncontrolledTooltip>
                                        <h6>{this.props.t("inputDataFormat")}</h6>
                                        <AvRadio label={this.props.t("ascii")} value="1" />
                                        <AvRadio label={this.props.t("hexadecimal")} value="2" />
                                      </AvRadioGroup>
                                      <div className="infoSymbol">
                                        <i
                                          className="dripicons-information "
                                          id="InputDataFormat"
                                        ></i></div>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label htmlFor="productdesc">
                                        {this.props.t("encryptData")}
                                      </Label>
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target="DataEnc"
                                      >
                                        {this.props.t("encryptDataInfo")}
                                      </UncontrolledTooltip>
                                      <AvField
                                        style={{ height: 100 }}
                                        name="dataEnc"
                                        placeholder={this.props.t("fill")}
                                        type="textarea"
                                        value={this.state.rsaOpts
                                          ? this.state.rsaOpts.dataEnc : ""}
                                        className="form-control"
                                        onChange={this.dataEncChange}
                                        validate={{ required: { value: true } }} />
                                      {this.state.disthresh1 ? (
                                        <span className="badgecount badge bg-success"
                                          style={{
                                            position: "absolute",
                                            marginTop: 1,
                                            marginLeft: 3
                                          }}
                                        >
                                          {this.state.dataEncChange}{" Length"}
                                        </span>
                                      ) : null}
                                      <div className="infoSymbol">
                                        <i
                                          className="dripicons-information "
                                          id="DataEnc"
                                        ></i>
                                      </div>
                                    </FormGroup>
                                  </Col>

                                  <Col>
                                    <FormGroup>
                                      <AvRadioGroup inline name="encodingMethod" required errorMessage={this.props.t("pickOne")}>
                                        <UncontrolledTooltip
                                          placement="bottom"
                                          target="EncodingMethod"
                                        >
                                          {this.props.t("encodingMethodInfo")}
                                        </UncontrolledTooltip>
                                        <h6>{this.props.t("encodingMethod")}</h6>
                                        <AvRadio label={this.props.t("private")} value="Private" />
                                        <AvRadio label={this.props.t("public")} value="Public" />
                                      </AvRadioGroup>
                                      <div className="infoSymbol">
                                        <i
                                          className="dripicons-information "
                                          id="EncodingMethod"
                                        ></i>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target="PaddingMethod"
                                      >
                                        {this.props.t("paddingMethodInfo")}
                                      </UncontrolledTooltip>
                                      <AvRadioGroup inline name="paddingMethod" errorMessage={this.props.t("pickOne")} required>
                                        <h6>{this.props.t("paddingMethod")}</h6>
                                        <AvRadio label={this.props.t("pkcs1")} value="PKCS1" />
                                        <AvRadio label={this.props.t("noPadding")} value="No Padding" />
                                      </AvRadioGroup>
                                      <div className="infoSymbol">
                                        <i
                                          className="dripicons-information "
                                          id="PaddingMethod"
                                        ></i>
                                      </div>
                                    </FormGroup>
                                    <div className="d-flex flex-wrap gap-2">
                                      <Button
                                        color="secondary"
                                        onClick={() => window.location.reload()}
                                        style={{ textAlign: "start" }}
                                        className="buttonDecode"
                                      >
                                        {this.props.t("clearButton")}
                                      </Button>
                                      <Button
                                        type="submit"
                                        color="primary"
                                      >
                                        {this.props.t("encodeButton")}
                                      </Button>
                                    </div>
                                  </Col>
                                </AvForm>
                              </TabPane>

                              <TabPane tabId="3" id="decrypt">
                                <AvForm onValidSubmit={this.handleValidSubmit} model={defaultValues}>
                                  <Col>
                                    <FormGroup>
                                      <Label htmlFor="productdesc">
                                        {this.props.t("decryptData")}
                                      </Label>
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target="DataDec"
                                      >
                                        {this.props.t("decryptDataInfo")}
                                      </UncontrolledTooltip>
                                      <AvField
                                        style={{ height: 100 }}
                                        name="dataDec"
                                        placeholder={this.props.t("fill")}
                                        type="textarea"
                                        value={this.state.rsaOpts
                                          ? this.state.rsaOpts.dataDec : ""}
                                        className="form-control"
                                        onChange={this.dataDecChange}
                                        validate={{ required: { value: true } }} />
                                      {this.state.disthresh2 ? (
                                        <span className="badgecount badge bg-success"
                                          style={{
                                            position: "absolute",
                                            marginTop: 1,
                                            marginLeft: 3
                                          }}
                                        >
                                          {this.state.dataDecChange}{" Length"}
                                        </span>
                                      ) : null}
                                      <div className="infoSymbol">
                                        <i
                                          className="dripicons-information "
                                          id="DataDec"
                                        ></i>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <AvRadioGroup inline name="decodingMethod" required errorMessage={this.props.t("pickOne")}>
                                        <UncontrolledTooltip
                                          placement="bottom"
                                          target="DecodingMethod"
                                        >
                                          {this.props.t("decodingMethodInfo")}
                                        </UncontrolledTooltip>
                                        <h6>{this.props.t("decodingMethod")}</h6>
                                        <AvRadio label="Private" value="Private" />
                                        <AvRadio label="Public" value="Public" />
                                      </AvRadioGroup>
                                      <div className="infoSymbol">
                                        <i
                                          className="dripicons-information "
                                          id="DecodingMethod"
                                        ></i>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target="PaddingMethodDec"
                                      >
                                        {this.props.t("decodingMethodInfo")}
                                      </UncontrolledTooltip>
                                      <AvRadioGroup inline name="paddingMethodDec" errorMessage={this.props.t("pickOne")} required>
                                        <h6>{this.props.t("paddingMethod")}</h6>
                                        <AvRadio label="PKCS1" value="PKCS1" />
                                        <AvRadio label="No Padding" value="No Padding" />
                                      </AvRadioGroup>
                                      <div className="infoSymbol">
                                        <i
                                          className="dripicons-information "
                                          id="PaddingMethodDec"
                                        ></i>
                                      </div>
                                    </FormGroup>
                                    <div className="d-flex flex-wrap gap-2">
                                      <Button
                                        color="secondary"
                                        onClick={() => window.location.reload()}
                                        style={{ textAlign: "start" }}
                                        className="buttonDecode"
                                      >
                                        {this.props.t("clearButton")}
                                      </Button>
                                      <Button
                                        type="submit"
                                        color="primary"
                                      >
                                        {this.props.t("decodeButton")}
                                      </Button>
                                    </div>

                                  </Col>
                                </AvForm>

                              </TabPane>

                              <TabPane tabId="4" id="sign">
                                <AvForm onValidSubmit={this.handleValidSubmit} model={defaultValues}>
                                  <Col>
                                    <FormGroup>
                                      <AvRadioGroup inline name="inputDataFormatSign" required errorMessage={this.props.t("pickOne")}>
                                        <UncontrolledTooltip
                                          placement="bottom"
                                          target="InputDataFormatSign"
                                        >
                                          {this.props.t("inputDataFormatInfo")}
                                        </UncontrolledTooltip>
                                        <h6>{this.props.t("inputDataFormat")}</h6>
                                        <AvRadio label={this.props.t("ascii")} value="1" />
                                        <AvRadio label={this.props.t("hexadecimal")} value="2" />
                                      </AvRadioGroup>
                                      <div className="infoSymbol">
                                        <i
                                          className="dripicons-information "
                                          id="InputDataFormatSign"
                                        ></i>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label htmlFor="productdesc">
                                        {this.props.t("signData")}
                                      </Label>
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target="DataSign"
                                      >
                                        {this.props.t("signDataInfo")}
                                      </UncontrolledTooltip>
                                      <AvField
                                        style={{ height: 100 }}
                                        name="dataSign"
                                        placeholder={this.props.t("fill")}
                                        type="textarea"
                                        value={this.state.rsaOpts
                                          ? this.state.rsaOpts.dataSign : ""}
                                        className="form-control"
                                        onChange={this.dataSignChange}
                                        validate={{ required: { value: true } }} />
                                      {this.state.disthresh3 ? (
                                        <span className="badgecount badge bg-success"
                                          style={{
                                            position: "absolute",
                                            marginTop: 1,
                                            marginLeft: 3
                                          }}
                                        >
                                          {this.state.dataSignChange}{" Length"}
                                        </span>
                                      ) : null}
                                      <div className="infoSymbol">
                                        <i
                                          className="dripicons-information "
                                          id="DataSign"
                                        ></i>
                                      </div>
                                    </FormGroup>
                                  </Col>

                                  <Col>
                                    <div className="d-flex flex-wrap gap-2">
                                      <Button
                                        color="secondary"
                                        onClick={() => window.location.reload()}
                                        style={{ textAlign: "start" }}
                                        className="buttonDecode"
                                      >
                                        {this.props.t("clearButton")}
                                      </Button>
                                      <Button
                                        type="submit"
                                        color="primary"
                                      >
                                        {this.props.t("sign")}
                                      </Button>
                                    </div>
                                  </Col>
                                </AvForm>

                              </TabPane>
                              <TabPane tabId="5" id="verify">
                                <AvForm onValidSubmit={this.handleValidSubmit} >

                                  <Col>
                                    <FormGroup>
                                      <Label htmlFor="productdesc">
                                        {this.props.t("hash")}
                                      </Label>
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target="HashVerify"
                                      >
                                        {this.props.t("hashInfo")}
                                      </UncontrolledTooltip>
                                      <AvField
                                        style={{ height: 100 }}
                                        name="hashVerify"
                                        placeholder={this.props.t("fill")}
                                        type="textarea"
                                        value={this.state.rsaOpts
                                          ? this.state.rsaOpts.hashVerify : ""}
                                        className="form-control"
                                        onChange={this.hashVerifyChange}
                                        validate={{ required: { value: true } }} />
                                      {this.state.disthresh4 ? (
                                        <span className="badgecount badge bg-success"
                                          style={{
                                            position: "absolute",
                                            marginTop: 1,
                                            marginLeft: 3
                                          }}
                                        >
                                          {this.state.hashVerifyChange}{" Length"}
                                        </span>
                                      ) : null}
                                      <div className="infoSymbol">
                                        <i
                                          className="dripicons-information "
                                          id="HashVerify"
                                        ></i>
                                      </div>
                                    </FormGroup>
                                  </Col>

                                  <Col>
                                    <FormGroup>
                                      <Label htmlFor="productdesc">
                                        {this.props.t("signature")}
                                      </Label>
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target="DataVerify"
                                      >
                                        {this.props.t("signatureInfo")}
                                      </UncontrolledTooltip>
                                      <AvField
                                        style={{ height: 150 }}
                                        name="dataVerify"
                                        placeholder={this.props.t("fill")}
                                        type="textarea"
                                        value={this.state.rsaOpts
                                          ? this.state.rsaOpts.dataVerify : ""}
                                        className="form-control"
                                        onChange={this.dataVerifyChange}
                                        validate={{ required: { value: true } }} />
                                      {this.state.disthresh5 ? (
                                        <span className="badgecount badge bg-success"
                                          style={{
                                            position: "absolute",
                                            marginTop: 1,
                                            marginLeft: 3
                                          }}
                                        >
                                          {this.state.dataVerifyChange}{" Length"}
                                        </span>
                                      ) : null}
                                      <div className="infoSymbol">
                                        <i
                                          className="dripicons-information "
                                          id="DataVerify"
                                        ></i>
                                      </div>
                                    </FormGroup>
                                    <div className="d-flex flex-wrap gap-2">
                                      <Button
                                        color="secondary"
                                        onClick={() => window.location.reload()}
                                        style={{ textAlign: "start" }}
                                        className="buttonDecode"
                                      >
                                        {this.props.t("clearButton")}
                                      </Button>
                                      <Button
                                        disabled={this.state.buttonTimer}
                                        type="submit"
                                        color="primary"
                                      >
                                        {this.props.t("verifyButton")}
                                      </Button>
                                    </div>
                                  </Col>
                                </AvForm>
                              </TabPane>
                            </TabContent>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment >
    )
  }
}

RSAOpt.propTypes = {
  t: PropTypes.any,
}

export default (withTranslation()(RSAOpt));
