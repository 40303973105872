import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import "../../pages.scss"
import { getResponse } from "../../../helpers/api_helper"
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class IssuerAD extends Component {
  constructor(props) {
    super(props)
    this.state = {
      issuerAppData: [],
      brandsMode: [],
      cardType: [],
      iadMode: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      buttonTimer: false,
      disthresh1: false,
      disthresh2: false,
      disthresh3: false,
      visaFormat: false,
      mastercardFormat: false,
      troyFormat: false,
      contact: false,
      contactless: false,


    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.dataChange = this.dataChange.bind(this);
    this.dataChange2 = this.dataChange2.bind(this);

  }

  dataChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ dataChange: event.target.value.length })
  }

  dataChange2(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh2: true })
    } else {
      this.setState({ disthresh2: false })
    }
    this.setState({ dataChange2: event.target.value.length })
  }




  handleValidSubmit = (e, values) => {


    values.troyfail = false;

    this.setState({ buttonTimer: true })
    const d0Tag = values.tag.toUpperCase();

    const tagListD0TContact = ['C248', 'C305', 'C405', 'C502', 'C602', 'C702', 'C806', 'C906', 'CA06', 'CB01', 'CC01', 'CD01', 'CE06', 'D106', '']
    const tagListD0TContactLess = ['C806', 'C906', 'CA06', 'CB01', 'CC01', 'CD01', 'CE06', 'D406', 'D901', 'D106', '']

    const firstContactTroyD0Tag = d0Tag.substring(0, 4);
    const secondContactTroyD0Tag = d0Tag.substring(4, 8);


    if (this.state.troyFormat) {
      if (values.brands == 3 && this.state.contact) {
        if (tagListD0TContact.indexOf(firstContactTroyD0Tag) == -1 || tagListD0TContact.indexOf(secondContactTroyD0Tag) == -1) {
          this.setState({ buttonTimer: false })
          this.setState({ respErrorData: "Please enter correct format of D0 tag value !" })
          this.addFailItem();
          values.troyfail = true
        }
      }
      if (values.brands == 3 && this.state.contactless) {
        if (tagListD0TContactLess.indexOf(firstContactTroyD0Tag) == -1 || tagListD0TContactLess.indexOf(secondContactTroyD0Tag) == -1) {
          this.setState({ buttonTimer: false })
          this.setState({ respErrorData: "Please enter correct format of D0 tag value !" })
          this.addFailItem();
          values.troyfail = true
        }
      }
      if (this.state.troyFormat && !values.troyfail) {
        getResponse(values, "/cloud/v1/IAD/IssuerAppDataTroy")
          .then((resp) => {
            this.setState({ buttonTimer: false })
            if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
              this.setState({ respData: [resp.resultData] })
              const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
              if (dnm != null && dnm != 0) {
                this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
                const a = [...this.state.histjson]
                a.push(resp)
                localStorage.setItem("jsonHistory", JSON.stringify(a));
              } else {
                localStorage.setItem("jsonHistory", JSON.stringify([resp]));
              }
              this.addSuccessItem();
            }
            else {
              this.setState({ respErrorData: [resp.resultDesc] })
              const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
              if (dnm != null && dnm != 0) {
                this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
                const a = [...this.state.histjson]
                a.push(resp)
                localStorage.setItem("jsonHistory", JSON.stringify(a));
              } else {
                localStorage.setItem("jsonHistory", JSON.stringify([resp]));
              }
              this.addFailItem();
            }
          })
      }
    }
    else {
      getResponse(values, "/cloud/v1/IAD/IssuerAppData")
        .then((resp) => {
          this.setState({ buttonTimer: false })

          if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
            this.setState({ respData: [resp.resultData] })

            const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
            if (dnm != null && dnm != 0) {
              this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
              const a = [...this.state.histjson]
              a.push(resp)
              localStorage.setItem("jsonHistory", JSON.stringify(a));
            } else {
              localStorage.setItem("jsonHistory", JSON.stringify([resp]));
            }
            this.addSuccessItem();
          }
          else {
            this.setState({ respErrorData: [resp.resultDesc] })

            const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
            if (dnm != null && dnm != 0) {
              this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
              const a = [...this.state.histjson]
              a.push(resp)
              localStorage.setItem("jsonHistory", JSON.stringify(a));
            } else {
              localStorage.setItem("jsonHistory", JSON.stringify([resp]));
            }
            this.addFailItem();
          }
        })
    }

  }

  addSuccessItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("issuerAppDataOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
  }

  addFailItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("issuerAppDataOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
  }
  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | {this.props.t("IAD")}</title>
              <meta name="description" content="Issuer Application Data (IAD) is an EMV tag (Tag 9F10) that contains data provided by the card issuer for various purposes, such as transaction authorization and cardholder verification. Explore the structure and significance of IAD in EMV chip card transactions and its role in ensuring secure and authorized transactions." />
              <meta name="keywords" content="Issuer Application Data, IAD, EMV Tag 9F10, EMV chip card, transaction authorization, cardholder verification, card issuer, payment processing, secure transactions" />
            </MetaTags>
          </Helmet>
          
          <Container fluid>
            <Breadcrumbs title={this.props.t("IAD (Tag 9F10)")} breadcrumbItem={this.props.t("Issuer Application Data")} />
            {this.props.t("Contains proprietary application data for transmission to the issuer in an online transaction.")}
            <div>
              <br />
              <br />
            </div>
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit}>
                      <Row className="rowIad">
                        <Col>
                          <FormGroup>
                            <AvRadioGroup inline name="brands" >
                              <Label htmlFor="productname">{this.props.t("Brands")}</Label>
                              <br />

                              <AvRadio
                                name="VISA VSDC"
                                label={this.props.t("visa")}
                                value="1"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    this.setState({
                                      brandsMode: "VISA VSDC",
                                      visaFormat: true,
                                      mastercardFormat: false,
                                      troyFormat: false
                                    });

                                  };
                                }}
                              />

                              <AvRadio
                                name="Mastercard M/Chip Advance"
                                label={this.props.t("mastercard")}
                                value="2"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    this.setState({
                                      brandsMode: "Mastercard M/Chip Advance",
                                      mastercardFormat: true,
                                      visaFormat: false,
                                      troyFormat: false
                                    });
                                  };
                                }}
                              />
                              <AvRadio
                                name="TROY"
                                label={this.props.t("troy")}
                                value="3"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    this.setState({
                                      brandsMode: "TROY",
                                      mastercardFormat: false,
                                      visaFormat: false,
                                      troyFormat: true
                                    });

                                  };
                                }}
                              />


                            </AvRadioGroup>



                          </FormGroup>

                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} style={{
                          display: this.state.troyFormat == true
                            ? "block"
                            : "none"
                        }}
                        >
                          <FormGroup >
                            <AvRadioGroup inline name="cardType">
                              <br />
                              <AvRadio
                                name="contact"
                                label={this.props.t("contactCardTroy")}
                                value="3"
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({ contact: true, contactless: false });
                                  };
                                }}
                              />

                              <AvRadio
                                name="contactless"
                                label={this.props.t("contactlessCardTroy")}
                                value="4"
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({ contact: false, contactless: true });
                                  };
                                }}
                              />


                            </AvRadioGroup>


                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("IAD")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="iad"
                            >Data: IAD data to decode.
                            </UncontrolledTooltip>

                            <AvField
                              name="iad"
                              placeholder={this.props.t(this.state.troyFormat ? "enterHexadecimalDigits" : "between14and64HexDigits")}
                              type="input"
                              onChange={this.dataChange2}
                              validate={{
                                pattern: { value: /^[A-Fa-f0-9]+$/ },
                                required: { value: true },
                                maxLength: this.state.visaFormat ? { value: 64 } : this.state.mastercardFormat ? { value: 56 } : this.state.contact ? { value: 256 } : { value: 256 },
                                minLength: this.state.contact ? { value: 22 } : this.state.contactless ? { value: 26 } : { value: 14 },
                              }}
                            />
                            <br />

                            {this.state.disthresh2 ? (
                              <span
                                className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3,
                                }}
                              >
                                {this.state.dataChange2}{" Length"}
                              </span>
                            ) : null}

                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="iad"
                              ></i>
                            </div>

                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} style={{
                          display: this.state.troyFormat == true
                            ? "block"
                            : "none"
                        }}>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("d0Tag")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="tag"
                            >D0 Tag: This field is optional.
                            </UncontrolledTooltip>

                            <AvField
                              //  style={{ marginBottom: 30 }}
                              name="tag"
                              placeholder={this.props.t("Enter 8 hex digits")}
                              type="input"
                              onChange={this.dataChange}
                              validate={{
                                pattern: { value: /^[A-Fa-f0-9]+$/ },
                                required: { value: false },
                                maxLength: { value: 12 },
                                minLength: { value: 8 },
                              }}
                            />

                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.dataChange}{" Length"}
                              </span>
                            ) : null}

                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="tag"
                              ></i>
                            </div>

                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          onClick={() => window.location.reload()}
                          color="secondary"
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          disabled={this.state.buttonTimer}
                          color="primary"
                        >
                          {this.props.t("decodeButton")}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

IssuerAD.propTypes = {
  t: PropTypes.any,
}

export default (withTranslation()(IssuerAD));
