export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const VERIFY_2F_LOGIN = "VERIFY_2F_LOGIN"
export const VERIFY_2F_LOGIN_SUCCESS = "VERIFY_2F_LOGIN_SUCCESS"

export const VERIFY_2F = "VERIFY_2F"
export const VERIFY_2F_SUCCESS = "VERIFY_2F_SUCCESS"


export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"
