import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "../../pages.scss"

import { getResponse } from "../../../helpers/api_helper"
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";


class TermCap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tlvParsers: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      histjson: [],
      items: [],
      isCustomTlv: false,
      disthresh1: false,
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.dataChange = this.dataChange.bind(this)
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    const cloudInput = {
      data: values.data,
    }
    getResponse(cloudInput, "/cloud/v1/TermCap/TermCapOperation")
      .then((resp) => {
        this.setState({ buttonTimer: false })
        if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
          this.setState({ respData: [resp.resultData] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addSuccessItem();
        }
        else {
          this.setState({ respErrorData: [resp.resultDesc] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addFailItem();
        }
      })
  }

  addSuccessItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("termCapOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-success" role="alert">
    //         <h4 className="alert-heading">TermCap operation finished with success</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // )
    // this.setState({ items: [...this.state.items] })
  }

  addFailItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [

        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("termCapOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-danger" role="alert">
    //         <h4 className="alert-heading">Term Cap operation finished with fail</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respErrorData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // );
    // this.setState({ items: [...this.state.items] })
  }

  dataChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ dataChange: event.target.value.length })
  }



  render() {
    const defaultValues = { searchOption: true }
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | TermCap</title>
              <meta name="description" content="TERMINAL CAPABILITIES (EMV Tag 9F33) indicates the card data input, Cardholder Verification Method (CVM), and security capabilities of the terminal. Learn about the structure and significance of TERMINAL CAPABILITIES in EMV chip card transactions, its role in determining terminal behavior, and its importance in ensuring secure and efficient payment processing." />
              <meta name="keywords" content="TERMINAL CAPABILITIES, EMV Tag 9F33, EMV chip card transactions, card data input, Cardholder Verification Method, CVM, terminal security, payment processing, terminal behavior, transaction security" />
            </MetaTags>
          </Helmet>
          
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="TermCap (Tag 9F33)" breadcrumbItem="Terminal Capabilities " />
            <div>
              Indicates the card data input, CVM, and security capabilities of the terminal
              <br />
              <br />
              <br />
            </div>
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit} >

                      <Row className="rowAes">
                        <Col>
                          <FormGroup>
                            <Label htmlFor="productdesc">
                              {this.props.t("TERMCAP")}
                            </Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Data"
                            >Data: TermCap data to decode.
                            </UncontrolledTooltip>
                            <AvField
                              style={{ height: 30 }}
                              name="data"
                              placeholder={this.props.t("6 hex digits")}
                              type="textarea"
                              className="form-control"
                              onChange={this.dataChange}
                              validate={{
                                pattern: { value: /^[0-9A-Fa-f]+$/ },
                                required: { value: true },
                                maxLength: { value: 6 },
                                minLength: { value: 6 }
                              }} />
                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.dataChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Data"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          onClick={() => window.location.reload()}
                          color="secondary"
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          disabled={this.state.buttonTimer}
                          color="primary"
                        >
                          {this.props.t("decodeButton")}
                        </Button>
                      </div>

                    </AvForm>
                  </CardBody>
                </Card>
              </Col>

              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
TermCap.propTypes = {
  t: PropTypes.any
}
export default (withTranslation()(TermCap));