import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "../../pages.scss"

import { getResponse } from "../../../helpers/api_helper"

//i18n
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class Conversions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      conversionss: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      // type: [],
      disthresh1: false,
      disthresh2: false,
      disthresh3: false,
      ivDsb: true,
      dataDsb: false,
      dataInputDsb: false,
      isEncrypt: false,
      decodeDsb: false,
      buttonTimer: false,
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.dataChange = this.dataChange.bind(this)
    this.handleSelectType = this.handleSelectType.bind(this)
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    if (this.state.conversionss.type == null || this.state.conversionss.type == "")
      this.setState({
        conversionss: ({
          ...this.state.conversionss,
          type: "1"
        })
      })
    const cloudInput = {
      data: values.data,
      type: this.state.conversionss.type,
    };

    var a = null
    if (this.state.conversionss.type == "1")
      a = "/cloud/v1/LibraryExtentions/LibToAsciiString"
    else if (this.state.conversionss.type == "2")
      a = "/cloud/v1/LibraryExtentions/LibConvertHexToInt"
    else if (this.state.conversionss.type == "3")
      a = "/cloud/v1/LibraryExtentions/LibConvertHexToLong"
    else if (this.state.conversionss.type == "4")
      a = "/cloud/v1/LibraryExtentions/LibConvertHexToShort"
    else if (this.state.conversionss.type == "5")
      a = "/cloud/v1/LibraryExtentions/LibAsciiToByteArray"
    else if (this.state.conversionss.type == "6")
      a = "/cloud/v1/LibraryExtentions/LibConvertToLong"
    else if (this.state.conversionss.type == "7")
      a = "/cloud/v1/LibraryExtentions/LibConvertToInt"
    else if (this.state.conversionss.type == "8")
      a = "/cloud/v1/LibraryExtentions/LibConvertToShort"
    getResponse(cloudInput, a)
      .then((resp) => {
        this.setState({ buttonTimer: false })
        if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
          this.setState({ respData: [resp.resultData] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addSuccessItem();
        }
        else {
          this.setState({ respErrorData: [resp.resultDesc] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addFailItem();
        }
      })


  }


  addSuccessItem = () => {


    this.setState({ items: [] });


    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("conversionsSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-success" role="alert">
    //         <h4 className="alert-heading">{this.props.t("conversionsSuccess")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // )
    // this.setState({ items: [...this.state.items] })

  }
  addFailItem = () => {
    // Mevcut öğeleri temizle
    this.setState({ items: [] });

    // Yeni öğeyi ekle
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("conversionsFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-danger" role="alert">
    //         <h4 className="alert-heading">{this.props.t("conversionsFail")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respErrorData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // );
    // this.setState({ items: [...this.state.items] })
  }
  dataChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh2: true })
    } else {
      this.setState({ disthresh2: false })
    }
    this.setState({ dataChange: event.target.value.length })
  }

  handleSelectType(e) {
    this.setState({
      conversionss: ({
        ...this.state.conversionss,
        type: e.value
      })
    })
  }

  render() {
    const conv = [
      { value: "1", label: this.props.t("asciiToString") },
      { value: "2", label: this.props.t("hexToInt") },
      { value: "3", label: this.props.t("hexToLong") },
      { value: "4", label: this.props.t("hexToShort") },
      { value: "6", label: this.props.t("toLong") },
      { value: "7", label: this.props.t("toInt") },
      { value: "8", label: this.props.t("toShort") },
    ]
    const defaultValues = { conv: { value: "1" } }
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools| {this.props.t("conversions")}</title>
              <meta name="description" content="Conversions refer to the process of converting data from one form to another. Learn about different types of conversions such as data type conversion, unit conversion, encoding conversion, and their applications in programming, mathematics, and engineering." />
              <meta name="keywords" content="Conversions, Data Conversion, Type Conversion, Unit Conversion, Encoding Conversion, Programming, Mathematics, Engineering, Data Format, Conversion Algorithms" />
            </MetaTags>
          </Helmet>

          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("conversions")} breadcrumbItem={this.props.t("conversions")} />
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit} model={defaultValues}>
                      <Row className="rowAes">
                        <Col>
                          <FormGroup>
                            <Label htmlFor="productdesc">
                              {this.props.t("data")}
                            </Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Data"
                            >
                              {this.props.t("conversionsDataInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              style={{ height: 100 }}
                              name="data"
                              placeholder={this.props.t("fill")}
                              type="textarea"
                              disabled={this.state.dataDsb ? true : false}
                              value={this.state.aesEncrypts
                                ? this.state.aesEncrypts.data : ""}
                              className="form-control"
                              onChange={this.dataChange}
                              validate={
                                this.state.dataDsb ? {
                                  required: { value: false }
                                } : { required: { value: true } }}
                            />
                            {this.state.disthresh2 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.dataChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Data"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className="select2-container">
                            <Label className="control-label">{this.props.t("type")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Type"
                            >
                              {this.props.t("typeInfo")}
                            </UncontrolledTooltip>
                            <Select
                              defaultValue={conv[0]}
                              options={conv}
                              onChange={this.handleSelectType}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Type"
                              ></i></div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="secondary"
                          onClick={() => window.location.reload()}
                          style={{ textAlign: "start" }}
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={this.state.buttonTimer}
                        >
                          {this.props.t("convertButton")}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Conversions.propTypes = {
  t: PropTypes.any,
}

export default (withTranslation()(Conversions));
