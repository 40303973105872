import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "../../pages.scss"

import { getResponse } from "../../../helpers/api_helper"
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";


class MChipAdvanceCVR extends Component {
  constructor(props) {
    super(props)
    this.state = {
      CVRValues: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      histjson: [],
      items: [],
      isCustomTlv: false,
      disthresh1: false,
      placeholderText: this.props.t("data"),
      dataLength: 0
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.dataChange = this.dataChange.bind(this);
    this.handleSelectType = this.handleSelectType.bind(this);
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    const cloudInput = {
      data: values.data,
      CVRValues: this.state.CVRValues.type
    }
    if (cloudInput.CVRValues == 1) {
      getResponse(cloudInput, "/cloud/v1/CVR/MChipAdvanceCVR")
        .then((resp) => {
          this.setState({ buttonTimer: false })
          if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
            this.setState({ respData: [resp.resultData] })
            const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
            if (dnm != null && dnm != 0) {
              this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
              const a = [...this.state.histjson]
              a.push(resp)
              localStorage.setItem("jsonHistory", JSON.stringify(a));
            } else {
              localStorage.setItem("jsonHistory", JSON.stringify([resp]));
            }
            this.addSuccessItem();
          }
          else {
            this.setState({ respErrorData: [resp.resultDesc] })
            const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
            if (dnm != null && dnm != 0) {
              this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
              const a = [...this.state.histjson]
              a.push(resp)
              localStorage.setItem("jsonHistory", JSON.stringify(a));
            } else {
              localStorage.setItem("jsonHistory", JSON.stringify([resp]));
            }
            this.addFailItem();
          }
        })
    }




    if (cloudInput.CVRValues == 2) {
      getResponse(cloudInput, "/cloud/v1/CVR/VSDCFormatCVR")
        .then((resp) => {
          this.setState({ buttonTimer: false })
          if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
            this.setState({ respData: [resp.resultData] })
            const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
            if (dnm != null && dnm != 0) {
              this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
              const a = [...this.state.histjson]
              a.push(resp)
              localStorage.setItem("jsonHistory", JSON.stringify(a));
            } else {
              localStorage.setItem("jsonHistory", JSON.stringify([resp]));
            }
            this.addSuccessItem();
          }
          else {
            this.setState({ respErrorData: [resp.resultDesc] })
            const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
            if (dnm != null && dnm != 0) {
              this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
              const a = [...this.state.histjson]
              a.push(resp)
              localStorage.setItem("jsonHistory", JSON.stringify(a));
            } else {
              localStorage.setItem("jsonHistory", JSON.stringify([resp]));
            }
            this.addFailItem();
          }
        })
    }


    if (cloudInput.CVRValues == 3) {
      getResponse(cloudInput, "/cloud/v1/CVR/VSDCFormat2CVR")
        .then((resp) => {
          this.setState({ buttonTimer: false })
          if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
            this.setState({ respData: [resp.resultData] })
            const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
            if (dnm != null && dnm != 0) {
              this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
              const a = [...this.state.histjson]
              a.push(resp)
              localStorage.setItem("jsonHistory", JSON.stringify(a));
            } else {
              localStorage.setItem("jsonHistory", JSON.stringify([resp]));
            }
            this.addSuccessItem();
          }
          else {
            this.setState({ respErrorData: [resp.resultDesc] })
            const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
            if (dnm != null && dnm != 0) {
              this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
              const a = [...this.state.histjson]
              a.push(resp)
              localStorage.setItem("jsonHistory", JSON.stringify(a));
            } else {
              localStorage.setItem("jsonHistory", JSON.stringify([resp]));
            }
            this.addFailItem();
          }
        })
    }

    if (cloudInput.CVRValues == 4) {
      getResponse(cloudInput, "/cloud/v1/CVR/TroyContactCardCvr")
        .then((resp) => {
          this.setState({ buttonTimer: false })
          if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
            this.setState({ respData: [resp.resultData] })
            const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
            if (dnm != null && dnm != 0) {
              this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
              const a = [...this.state.histjson]
              a.push(resp)
              localStorage.setItem("jsonHistory", JSON.stringify(a));
            } else {
              localStorage.setItem("jsonHistory", JSON.stringify([resp]));
            }
            this.addSuccessItem();
          }
          else {
            this.setState({ respErrorData: [resp.resultDesc] })
            const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
            if (dnm != null && dnm != 0) {
              this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
              const a = [...this.state.histjson]
              a.push(resp)
              localStorage.setItem("jsonHistory", JSON.stringify(a));
            } else {
              localStorage.setItem("jsonHistory", JSON.stringify([resp]));
            }
            this.addFailItem();
          }
        })
    }

    if (cloudInput.CVRValues == 5) {
      getResponse(cloudInput, "/cloud/v1/CVR/TroyContactlessCardCvr")
        .then((resp) => {
          this.setState({ buttonTimer: false })
          if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
            this.setState({ respData: [resp.resultData] })
            const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
            if (dnm != null && dnm != 0) {
              this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
              const a = [...this.state.histjson]
              a.push(resp)
              localStorage.setItem("jsonHistory", JSON.stringify(a));
            } else {
              localStorage.setItem("jsonHistory", JSON.stringify([resp]));
            }
            this.addSuccessItem();
          }
          else {
            this.setState({ respErrorData: [resp.resultDesc] })
            const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
            if (dnm != null && dnm != 0) {
              this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
              const a = [...this.state.histjson]
              a.push(resp)
              localStorage.setItem("jsonHistory", JSON.stringify(a));
            } else {
              localStorage.setItem("jsonHistory", JSON.stringify([resp]));
            }
            this.addFailItem();
          }
        })
    }

  }

  addSuccessItem = () => {

    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("cvrOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }))
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-success" role="alert">
    //         <h4 className="alert-heading">CVR decode operation finished with success</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // )
    // this.setState({ items: [...this.state.items] })
  }

  addFailItem = () => {

    this.state({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("cvrOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.setState({items:[]});
    // this.setState(prevState=>({
    //   items:[
    //     ...prevState.items,
    //     <Col key={Date.now()}>
    //       <Card>
    //         <CardBody>
    //           <Alert className="alert-danger" role="alert">
    //             <h4 className="alert-heading">{this.props.t("cvrOperationFail")}</h4>
    //             <hr/>
    //             <div className="display-linebreak">{this.state.respErrorData}</div>
    //           </Alert>
    //         </CardBody>
    //       </Card>
    //     </Col>
    //   ]
    // }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-danger" role="alert">
    //         <h4 className="alert-heading">CVR decode operation finished with fail</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respErrorData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // );
    // this.setState({ items: [...this.state.items] })
  }

  dataChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ dataChange: event.target.value.length })
  }

  handleSelectType(e) {

    const selectedType = e.value;
    let newPlaceholderText;

    if (selectedType === "1") {
      newPlaceholderText = this.props.t("enter12HexDigits");
      this.setState({ placeholderText: newPlaceholderText, dataLength: 12 })


    } else if (selectedType === "2") {


      newPlaceholderText = this.props.t("enter6HexDigits");
      this.setState({ placeholderText: newPlaceholderText, dataLength: 6 })

    } else if (selectedType === "3") {

      newPlaceholderText = this.props.t("enter10HexDigits");
      this.setState({ placeholderText: newPlaceholderText, dataLength: 10 })

    }

    else if (selectedType === "4") {
      newPlaceholderText = this.props.t("enter12HexDigits");
      this.setState({ placeholderText: newPlaceholderText, dataLength: 12 })
    }

    else if (selectedType === "5") {
      newPlaceholderText = this.props.t("enter16HexDigits");
      this.setState({ placeholderText: newPlaceholderText, dataLength: 16 })
    }



    this.setState({
      CVRValues: ({
        ...this.state.CVRValues,
        type: e.value
      })
    })
  }



  render() {

    const type = [
      { value: "1", label: this.props.t("mChipAdvance") },
      { value: "2", label: this.props.t("vsdcIadFormat") },
      { value: "3", label: this.props.t("vsdcIadFormat2") },
      { value: "4", label: this.props.t("contactCardTroy") },
      { value: "5", label: this.props.t("contactlessCardTroy") }


    ]


    const defaultValues = { searchOption: true }
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip Wechip Payment Tools | MChipAdvanceCVR Decoder</title>
              <meta name="description" content="MChip Advance CARD VERIFICATION RESULTS (Part of EMV Tag 9F10) contains data sent to the issuer indicating exception conditions that occurred during the current and previous transactions. This information is transmitted to the terminal in the Issuer Application. Learn about the format of Card Verification Results, its significance in EMV chip card transactions, and how it aids in the communication between the terminal and the issuer." />
              <meta name="keywords" content="CARD VERIFICATION RESULTS, Part of EMV Tag 9F10, Mchip Advance, EMV Chip Card, Payment Authentication, Exception Conditions, Issuer Application, Transaction Data, Terminal Communication" />
            </MetaTags>
          </Helmet>
          
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="CVR (Part of Tag 9F10)" breadcrumbItem="Card Verification Results" />
            <div>
              Contains data sent to the issuer indicating exception conditions that occurred during the
              <br />
              current and previous transactions. Transmitted to the terminal in Issuer Application.
              <br />
              <br />
            </div>
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit} >

                      <Row className="rowAes">
                        <Col>
                          <FormGroup>

                            <Label htmlFor="productdesc">
                              {this.props.t("type")}
                            </Label>

                            <Select

                              validate={{
                                required: { value: true }
                              }}
                              options={type}
                              onChange={this.handleSelectType}
                            />

                            <UncontrolledTooltip
                              placement="bottom"
                              target="Type"
                            >Type: Select type to decode.
                            </UncontrolledTooltip>

                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Type"
                              ></i></div>



                          </FormGroup>
                        </Col>

                        <Col md={12}>
                          <FormGroup>

                            <Label htmlFor="productdesc">
                              {this.props.t("CVR")}
                            </Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Data"
                            >Data: CVR data to decode.
                            </UncontrolledTooltip>


                            <AvField
                              style={{ height: 35 }}
                              name="data"
                              placeholder={this.state.placeholderText}
                              type="text"
                              className="form-control"
                              onChange={this.dataChange}
                              validate={{
                                pattern: { value: /^[0-9A-Fa-f]+$/ },
                                required: { value: true },
                                maxLength: { value: this.state.dataLength },
                                minLength: { value: this.state.dataLength }
                              }} />

                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.dataChange}{" Length"}
                              </span>
                            ) : null}


                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Data"
                              ></i>
                            </div>

                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          onClick={() => window.location.reload()}
                          color="secondary"
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          disabled={this.state.buttonTimer}
                          color="primary"
                        >
                          {this.props.t("decodeButton")}
                        </Button>
                      </div>

                    </AvForm>
                  </CardBody>
                </Card>
              </Col>

              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
MChipAdvanceCVR.propTypes = {
  t: PropTypes.any
}
export default (withTranslation()(MChipAdvanceCVR));