import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import "../../pages.scss"

import { getResponse } from "../../../helpers/api_helper"

//i18n
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class CvvGenerate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cvvGenerates: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      disthresh1: false,
      disthresh2: false,
      disthresh3: false,
      disthresh4: false,
      disthresh5: false,
      mdkChange: false,
      serviceCodeDsb: false,
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.cvkChange = this.cvkChange.bind(this)
    this.panChange = this.panChange.bind(this)
    this.expDateChange = this.expDateChange.bind(this)
    this.serviceCodeChange = this.serviceCodeChange.bind(this)
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    if (this.state.cvvGenerates.verificationValueType == null || this.state.cvvGenerates.verificationValueType == "")
      this.setState({
        cvvGenerates: ({
          ...this.state.cvvGenerates,
          verificationValueType: "CVV/CVC"
        })
      })
    const cloudInput = {
      verificationValueType: this.state.cvvGenerates.verificationValueType,
      cvk: values.cvk,
      pan: values.pan,
      expDate: values.expDate,
      serviceCode: values.serviceCode,
    };

    getResponse(cloudInput, "/cloud/v1/CVV/CvvGenerate")
      .then((resp) => {
        this.setState({ buttonTimer: false })
        if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
          this.setState({ respData: [resp.resultData] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addSuccessItem();
        }
        else {
          this.setState({ respErrorData: [resp.resultDesc] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addFailItem();
        }
      })

  }


  addSuccessItem = () => {

    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("cvvGenerateOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-success" role="alert">
    //         <h4 className="alert-heading">{this.props.t("cvvGenerateOperationSuccess")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // )
    // this.setState({ items: [...this.state.items] })

  }
  addFailItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("cvvGenerateOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-danger" role="alert">
    //         <h4 className="alert-heading">{this.props.t("cvvGenerateOperationFail")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respErrorData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // );
    // this.setState({ items: [...this.state.items] })
  }

  cvkChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ cvkChange: event.target.value.length })
  }

  panChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh2: true })
    } else {
      this.setState({ disthresh2: false })
    }
    this.setState({ panChange: event.target.value.length })
  }

  expDateChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh3: true })
    } else {
      this.setState({ disthresh3: false })
    }
    this.setState({ expDateChange: event.target.value.length })
  }

  serviceCodeChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh4: true })
    } else {
      this.setState({ disthresh4: false })
    }
    this.setState({ serviceCodeChange: event.target.value.length })
  }


  render() {
    const defaultValues = { verificationValueType: "CVV/CVC" }
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | {this.props.t("cvv")}</title>
              <meta name="description" content="CVV generation is the process of generating the Card Verification Value (CVV), a security feature used in credit and debit card transactions to verify card authenticity. Learn about the methods and algorithms used to generate CVV numbers and their significance in online payment security." />
              <meta name="keywords" content="CVV generation, Card Verification Value, CVV algorithm, credit card security, debit card security, online payment security, CVV number, CVV generation methods" />
            </MetaTags>
          </Helmet>

          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("cvv")} breadcrumbItem={this.props.t("cvvGenerate")} />
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit} model={defaultValues}>
                      <Row className="rowAes">
                        <Col>
                          <FormGroup>
                            <Label htmlFor="productdesc">
                              {this.state.mdkChange ? this.props.t("mdk") : this.props.t("cvk")}
                            </Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Cvk"
                            >
                              {this.props.t("cvkInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="cvk"
                              placeholder={this.props.t("32 hex digits")}
                              type="text"
                              value={this.state.cvvGenerates
                                ? this.state.cvvGenerates.cvk : ""}
                              className="form-control"
                              onChange={this.cvkChange}
                              validate={{
                                pattern: { value: /^[A-Fa-f0-9]+$/ },
                                required: { value: false },
                                minLength: { value: 32 },
                                maxLength: { value: 32 },
                              }} />
                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.cvkChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Cvk"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <Label htmlFor="productdesc">
                              {this.props.t("pan")}
                            </Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Pan"
                            >
                              {this.props.t("panInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="pan"
                              placeholder={this.props.t("13-19 hex digits")}
                              type="text"
                              value={this.state.cvvGenerates
                                ? this.state.cvvGenerates.pan : ""}
                              className="form-control"
                              onChange={this.panChange}
                              validate={{
                                pattern: { value: /^[A-Fa-f0-9]+$/ },
                                required: { value: false },
                                minLength: { value: 13 },
                                maxLength: { value: 19 },
                              }} />
                            {this.state.disthresh2 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.panChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Pan"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>


                        <Col>
                          <FormGroup>
                            <Label htmlFor="productdesc">
                              {this.props.t("expDate")}
                            </Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="ExpDate"
                            >
                              {this.props.t("expDateInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="expDate"
                              placeholder={this.props.t("4 hex digits")}
                              type="text"
                              value={this.state.cvvGenerates
                                ? this.state.cvvGenerates.expDate : ""}
                              className="form-control"
                              onChange={this.expDateChange}
                              validate={{
                                pattern: { value: /^[A-Fa-f0-9]+$/ },
                                required: { value: false },
                                minLength: { value: 4 },
                                maxLength: { value: 4 },
                              }} />
                            {this.state.disthresh3 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.expDateChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="ExpDate"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <Label htmlFor="productdesc">
                              {this.props.t("serviceCode")}
                            </Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="ServiceCode"
                            >
                              {this.props.t("serviceCodeInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="serviceCode"
                              placeholder={this.props.t("3 hex digits")}
                              type="text"
                              disabled={this.state.serviceCodeDsb}
                              value={this.state.cvvGenerates
                                ? this.state.cvvGenerates.serviceCode : ""}
                              className="form-control"
                              onChange={this.serviceCodeChange}
                              validate={{
                                pattern: { value: /^[A-Fa-f0-9]+$/ },
                                required: { value: false },
                                minLength: { value: 3 },
                                maxLength: { value: 3 },
                              }} />
                            {this.state.disthresh4 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.serviceCodeChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="ServiceCode"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="VerificationValueType"
                            >
                              {this.props.t("verificationValueTypeInfo")}
                            </UncontrolledTooltip>
                            <AvRadioGroup inline name="verificationValueType" errorMessage={this.props.t("pickOne")}
                              required>
                              <h6>{this.props.t("verificationValueType")}</h6>
                              <AvRadio label={this.props.t("cvvCVC")} value="CVV/CVC"
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({
                                      mdkChange: false, serviceCodeDsb: false, cvvGenerates: ({
                                        ...this.state.cvvGenerates,
                                        serviceCode: ""
                                      })
                                    })
                                  }
                                }}
                              />
                              <AvRadio label={this.props.t("iCVV")} value="iCVV"
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({
                                      mdkChange: false, serviceCodeDsb: true, cvvGenerates: ({
                                        ...this.state.cvvGenerates,
                                        serviceCode: "999"
                                      })
                                    })
                                  }
                                }}
                              />
                              <AvRadio label={this.props.t("cvv2CVC2")} value="CVV2/CVC2"
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({
                                      mdkChange: false, serviceCodeDsb: true, cvvGenerates: ({
                                        ...this.state.cvvGenerates,
                                        serviceCode: "000"
                                      })
                                    })
                                  }
                                }}
                              />
                              {/* <AvRadio label={this.props.t("dCVV")} value="dCVV" disabled
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ mdkChange: true }) } }}
                              /> */}
                            </AvRadioGroup>
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="VerificationValueType"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="secondary"
                          onClick={() => window.location.reload()}
                          style={{ textAlign: "start" }}
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          disabled={this.state.buttonTimer}
                          color="primary"
                        >
                          {this.props.t("generateButton")}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CvvGenerate.propTypes = {
  t: PropTypes.any,
}

export default (withTranslation()(CvvGenerate));
