import React, { Component, useState } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
  Row,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import "../../pages.scss"

import { getResponse } from "../../../helpers/api_helper"
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";


class UserVerificationOperation extends Component {
  constructor(props) {
    super(props)
    this.state = {

      respData: [],
      respErrorData: [],
      successAlert: [],
      histjson: [],
      items: [],


    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    //this.dataChange = this.dataChange.bind(this)
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    const { location, history } = this.props

    if (location.state != undefined && location.state.length != 0) {
      if (location.state.Email.length != 0) {
        values.Email = location.state.Email
      }
    }

    const cloudInput = { verificationUserDto: values }
    console.log(cloudInput);
    getResponse(values, "/cloud/v1/UserVerification")
      .then((resp) => {
        this.setState({ buttonTimer: false })
        if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
          this.setState({ respData: [resp.resultData] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addSuccessItem();
          history.replace()
        }
        else {
          this.setState({ respErrorData: [resp.resultDesc] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addFailItem();
        }
      })
    console.log(this.state.respData)
  }

  addSuccessItem = () => {
    this.state.items.push(<Col key={Date.now()} >
      <Card>
        <CardBody>
          <Alert className="alert-success" role="alert">
            <h4 className="alert-heading">{this.props.t("userVerificationOperationFinishedSuccess")}</h4>
            <hr />
            <div className="display-linebreak">{this.state.respData}</div>
          </Alert>
        </CardBody>
      </Card>
    </Col>
    )
    this.setState({ items: [...this.state.items] })
  }

  addFailItem = () => {
    this.state.items.push(<Col key={Date.now()} >
      <Card>
        <CardBody>
          <Alert className="alert-danger" role="alert">
            <h4 className="alert-heading">{this.props.t("userVerificationOperationFinishedError")}</h4>
            <hr />
            <div className="display-linebreak">{this.state.respErrorData}</div>
          </Alert>
        </CardBody>
      </Card>
    </Col>
    );
    this.setState({ items: [...this.state.items] })
  }

  render() {
    const defaultValues = { searchOption: true }

    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | Verification Page  </title>
              <meta name="description" content="User Verification Page is a web page or interface designed to verify the identity of users through various authentication methods such as passwords, biometrics, or two-factor authentication. Explore the principles and techniques behind user verification pages, their role in ensuring secure access to sensitive information or services, and their significance in safeguarding user accounts from unauthorized access." />
              <meta name="keywords" content="User Verification Page, user authentication, password verification, biometric verification, two-factor authentication, security, sensitive information, access control, user accounts" />
            </MetaTags>
          </Helmet>

          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Verification " breadcrumbItem="Verification Page" style={{ textAlign: 'center' }} />
            <Row>
              <Col md={4}>
                <Card>
                  <CardBody>
                    <p className="card-title-desc" style={{ marginBottom: 2, textAlign: 'center', color: 'blue', fontSize: 15, fontFamily: '' }}>{this.props.t("hello")}</p>
                    <p className="card-title-desc" style={{ textAlign: 'center', color: 'blue', fontSize: 15 }}>{this.props.t("verificationCodeHere")} </p>
                    <AvForm onValidSubmit={this.handleValidSubmit} >

                      <Row className="rowAes">
                        <Col sm="12">
                          <FormGroup>
                            <Label className="control-label"
                              style={{ marginLeft: 180 }}>
                              {this.props.t("verificationCode")}
                            </Label>
                            <AvField
                              style={{ height: 45, width: 300, marginBottom: 20, marginLeft: 90 }}
                              name="VerificationCode"
                              placeholder={this.props.t("enterenterVerificationCode")}
                              type="text"
                              className="form-control"
                              validate={{
                                pattern: { value: "[0-9]*" },
                                required: { value: true },
                                maxLength: { value: 6 },

                              }} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          style={{ marginLeft: 320 }}
                          type="submit"
                          disabled={this.state.buttonTimer}
                          color="primary"
                        >
                          VERIFY
                        </Button>
                      </div>

                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col md={2}>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
UserVerificationOperation.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  location: PropTypes.any
}
export default (withTranslation()(UserVerificationOperation));