import axios from "axios"
import { del, get, post, put, postAuth, delAuth, putAuth, getAuth, respoFunc } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}


// Login Method
const postFakeLogin = data => postAuth(url.POST_FAKE_LOGIN, data)
const postVerify2fSecret = data => postAuth(url.POST_2F_VERIFY, data)
const postVerify2fLogin = data => postAuth(url.POST_2F_LOGIN, data)


//TKBEncode

export const newTKBEncode = tkbEncode => post(url.TKBENCODES, tkbEncode)
export const newTKBDecode = tkbDecode => post(url.TKBDECODES, tkbDecode)
// TEST 

export const getTestDefinitions = () => get(url.GET_TEST_DEFINITIONS)
export const addNewTestDefinition = testDefinition => post(url.ADD_NEW_TEST_DEFINITION, testDefinition)
export const updateTestDefinition = testDefinition => post(url.UPDATE_TEST_DEFINITION, testDefinition)
export const deleteTestDefinition = testDefinition => del(url.DELETE_TEST_DEFINITION, { headers: { testDefinition } })

// MEMBER DEFINITION

export const getMemberDefinitions = () => get(url.MEMBER_DEFINITIONS)
export const addNewMemberDefinition = memberDefinition => post(url.MEMBER_DEFINITIONS, memberDefinition)
export const updateMemberDefinition = memberDefinition => post(url.MEMBER_DEFINITIONS, memberDefinition)
export const deleteMemberDefinition = guid => del(`${url.MEMBER_DEFINITIONS}/${guid}`, { params: { guid } })

// PAGE DEFINITION

export const getPageDefinitions = () => get(url.PAGE_DEFINITIONS)
export const addNewPageDefinition = pageDefinition => post(url.PAGE_DEFINITIONS, pageDefinition)
export const updatePageDefinition = pageDefinition => post(url.PAGE_DEFINITIONS, pageDefinition)
export const deletePageDefinition = guid => del(`${url.PAGE_DEFINITIONS}/${guid}`, { params: { guid } })

// ROLE DEFINITION 

export const getRoleDefinitions = () => get(url.ROLE_DEFINITIONS)
export const addNewRoleDefinition = roleDefinition => post(url.ROLE_DEFINITIONS, roleDefinition)
export const updateRoleDefinition = roleDefinition => post(url.ROLE_DEFINITIONS, roleDefinition)
export const deleteRoleDefinition = guid => del(`${url.ROLE_DEFINITIONS}/${guid}`, { params: { guid } })

// USER DEFINITION

export const getUserDefinitions = () => get(url.USER_DEFINITIONS)
export const addNewUserDefinition = userDefinition => post(url.USER_DEFINITIONS, userDefinition)
export const updateUserDefinition = userDefinition => post(url.USER_DEFINITIONS, userDefinition)
export const deleteUserDefinition = guid => del(`${url.USER_DEFINITIONS}/${guid}`, { params: { guid } })

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeLogin,
  postVerify2fSecret,
  postVerify2fLogin
}
