import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Profile from "./auth/profile/reducer"

import tkbEncode from "./ThalesKeyBlock/reducer"
import tkbDecode from "./ThalesKeyBlock/reducer"
import testDefinition from "./SystemGeneral/reducer"
import memberDefinition from "./SystemGeneral/reducer"
import pageDefinition from "./SystemGeneral/reducer"
import roleDefinition from "./SystemGeneral/reducer"
import userDefinition from "./SystemGeneral/reducer"


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Profile,
  tkbEncode,
  tkbDecode,
  testDefinition,
  memberDefinition,
  pageDefinition,
  roleDefinition,
  userDefinition,
})

export default rootReducer
