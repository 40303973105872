import React, { Component, useEffect, useRef } from "react"


import { Link } from "react-router-dom"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
  Row,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import "pages/pages.scss"

//i18n
import { withTranslation } from "react-i18next";


class Version extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
          <title>Wechip - Wechip Payment Tools</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="WeChip" breadcrumbItem={this.props.t("version")} />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="pt-3">
                      <div className="row justify-content-center">
                        <div className="col-xl-8">
                          <div>
                            <div className="text-center">
                              <div className="mb-4">
                                <h2>
                                  {this.props.t("version")}
                                </h2>
                                <hr></hr>
                                <div className="mt-4">
                                  <div className="text-muted font-size-14">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Version.propTypes = {
  t: PropTypes.any,
}


export default (withTranslation()(Version));
