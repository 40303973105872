import React, { Component } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvForm, AvField } from "availity-reactstrap-validation";
import "../../pages.scss"
import { getResponse } from "../../../helpers/api_helper"
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class AID extends Component {
  constructor(props) {
    super(props)
    this.state = {
      aid: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      vendorMode: [],
      disthresh1: false,
      disthresh2: false,
      disthresh3: false,
      ivDsb: true,
      dataDsb: false,
      dataInputDsb: false,
      isEncrypt: false,
      decodeDsb: false,
      buttonTimer: false,
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.handleSelectVendor = this.handleSelectVendor.bind(this)
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    if ((!values.aid || values.aid === "") && (!this.state.aid.vendorMode || this.state.aid.vendorMode === "")) {
      this.setState({ respErrorData: [this.props.t("aidVendorRequired")] });
      this.addFailItem();
      this.setState({ buttonTimer: false });
      return;
    }
    const cloudInput = {
      vendor: this.state.aid.vendorMode,
      aid: values.aid,
    };

    getResponse(cloudInput, "/cloud/v1/AID/applicationID")
      .then((resp) => {
        this.setState({ buttonTimer: false });
        if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
          this.setState({ respData: [resp.resultData] });
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"));
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) });
            const a = [...this.state.histjson];
            a.push(resp);
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addSuccessItem();
        } else {
          this.setState({ respErrorData: [resp.resultDesc] });
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"));
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) });
            const a = [...this.state.histjson];
            a.push(resp);
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addFailItem();
        }
      });
  };

  addSuccessItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("aidOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-success" role="alert">
    //         <h4 className="alert-heading">{this.props.t("aidOperationSuccess")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // )
    // this.setState({ items: [...this.state.items] })

  }
  addFailItem = () => {

    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("aidOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-danger" role="alert">
    //         <h4 className="alert-heading">{this.props.t("aidOperationFail")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respErrorData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // );
    // this.setState({ items: [...this.state.items] })
  }
  dataChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh2: true })
    } else {
      this.setState({ disthresh2: false })
    }
    this.setState({ dataChange: event.target.value.length })
  }


  handleSelectVendor(e) {
    this.setState({
      aid: ({
        ...this.state.aid,
        vendorMode: e.value
      })
    })
  }



  render() {
    const vendor = [
      { value: "", label: this.props.t("none") },
      { value: "Visa International", label: this.props.t("visaInternational") },
      { value: "DeviceFidelity", label: this.props.t("deviceFidelity") },
      { value: "PBS Danmønt A/S", label: this.props.t("pbsDanmøntAS") },
      { value: "Mondex International Ltd.", label: this.props.t("mondexInternationalLtd") },
      { value: "Mastercard International", label: this.props.t("mastercardInternational") },
      { value: "Switch Card Services Ltd.", label: this.props.t("switchCardServicesLtd") },
      { value: "ETSI", label: this.props.t("etsi") },
      { value: "Europay International", label: this.props.t("europayInternational") },
      { value: "GEMPLUS", label: this.props.t("gemplus") },
      { value: "Midland Bank Plc", label: this.props.t("midlandBankPlc") },
      { value: "American Express", label: this.props.t("americanExpress") },
      { value: "LINK Interchange Network Ltd", label: this.props.t("linkInterchangeNetworkLtd") },
      { value: "Schlumberger Industries Identif d’Encarteur PR050", label: this.props.t("schlumbergerIndustriesIdentif") },
      { value: "Groupement des Cartes Bancaires “CB”", label: this.props.t("groupementDesCartesBancaires") },
      { value: "Zentraler Kreditausschuss (ZKA)", label: this.props.t("zentralerKreditausschussZKA") },
      { value: "RSA Laboratories", label: this.props.t("rsaLaboratories") },
      { value: "JCB CO., LTD.", label: this.props.t("jcbCOLTD") },
      { value: "Société Européenne de Monnaie Electronique SEME", label: this.props.t("sociétéEuropéenneDeMonnaieElectroniqueSEME") },
      { value: "Oberthur Technologies", label: this.props.t("oberthurTechnologies") },
      { value: "Activcard Europe S.A.", label: this.props.t("activcardEuropeSA") },
      { value: "Third Generation Partnership Project (3GPP)", label: this.props.t("thirdGenerationPartnershipProject3GPP") },
      { value: "Buypass AS", label: this.props.t("buypassAS") },
      { value: "Sa Proton World International N.V.", label: this.props.t("saProtonWorldInternationalNV") },
      { value: "Visa USA", label: this.props.t("visaUSA") },
      { value: "Die Post Postfinance", label: this.props.t("diePostPostfinance") },
      { value: "GSA – TFCS", label: this.props.t("gsaTFCS") },
      { value: "Austria Card", label: this.props.t("austriaCard") },
      { value: "PBS Danmark A/S", label: this.props.t("pbsDanmarkAS") },
      { value: "Java Card Forum", label: this.props.t("javaCardForum") },
      { value: "TDS TODOS DATA SYSTEM AB", label: this.props.t("tdsTodosDataSystemAB") },
      { value: "Associazione Bancaria Italiana", label: this.props.t("associazioneBancariaItaliana") },
      { value: "GlobalPlatform, Inc.", label: this.props.t("globalPlatformInc") },
      { value: "Diners Club International Ltd.", label: this.props.t("dinersClubInternationalLtd") },
      { value: "Banrisul – Banco do Estado do Rio Grande do SUL – S.A.", label: this.props.t("banrisulBancoDoEstadoDoRioGrandeDoSUL") },
      { value: "Zühlke Engineering AG", label: this.props.t("zühleEngineeringAG") },
      { value: "IBM", label: this.props.t("ibm") },
      { value: "Financial Information Service Co. Ltd.", label: this.props.t("financialInformationServiceCoLtd") },
      { value: "Ministère de L’Intérieur", label: this.props.t("ministèreDeLIntèrieur") },
      { value: "Post Office Limited", label: this.props.t("postOfficeLimited") },
      { value: "Diners Club Switzerland Ltd", label: this.props.t("dinersClubSwiterlandLtd") },
      { value: "Association for Payment Clearing Services", label: this.props.t("associationForPaymentClearingService") },
      { value: "Saudi Arabian Monetary Agency (SAMA)", label: this.props.t("saudiArabianMonetaryAgencySAMA") },
      { value: "ISO JTC1/SC17/WG3", label: this.props.t("isoJTC1SC17WG3") },
      { value: "Interac Association", label: this.props.t("interacAssociation") },
      { value: "PS/SC Workgroup", label: this.props.t("psSCWorkgroup") },
      { value: "National Institute of Standards and Technology", label: this.props.t("nationalInstituteOfStandardsAndTechnology") },
      { value: "Currence Holding/PIN BV", label: this.props.t("currenceHoldingPINBV") },
      { value: "Identity Alliance", label: this.props.t("identityAllience") },
      { value: "Discover Financial Services LLC", label: this.props.t("discoverFinancialServicesLtd") },
      { value: "China Unionpay Co. Ltd", label: this.props.t("chinaUnionpayCoLtd") },
      { value: "Groupment Interbancaire Monétique de l’ UEMOA", label: this.props.t("groupmentInterbancaireMonétiqueDeLUEMOA") },
      { value: "Euro Alliance of Payment Schemes s.c.r.l. – EAPS", label: this.props.t("euroAllienceOfPaymentSchemesSCRLEAPS") },
      { value: "Poste Italiane S.P.A", label: this.props.t("posteItalianeSPA") },
      { value: "Verve", label: this.props.t("verve") },
      { value: "eftpos, Australian Payments Clearing Association Ltd", label: this.props.t("eftposAustralianPaymentsClearingAssociationLtd") },
      { value: "NXP Semiconductors Germany GmbH", label: this.props.t("nxpSemicondutorsGermanyGMBH") },
      { value: "Microsoft Corporation", label: this.props.t("microsoftCorporation") },
      { value: "Unibanco (Hipercard)", label: this.props.t("unibancoHipercard") },
      { value: "ПРО100", label: this.props.t("ПРО100") },
      { value: "Edenred", label: this.props.t("edenred") },
      { value: "ACCEL/Exchange", label: this.props.t("accelExchange") },
      { value: "eTranzact", label: this.props.t("eTranzact") },
      { value: "Google", label: this.props.t("google") },
      { value: "JVL Ventures, LLC (Softcard)", label: this.props.t("jvlVenturesLCCSoftcard") },
      { value: "RuPay", label: this.props.t("ruPay") },
      { value: "Yubico", label: this.props.t("yubico") },
      { value: "GSMA (GSM Association)", label: this.props.t("gsmaGSMAssociation") },
      { value: "Fidesmo", label: this.props.t("fidesmo") },
      { value: "Debit Network Alliance (DNA)", label: this.props.t("debitNetworkAllianceDNA") },
      { value: "SwissPass", label: this.props.t("swissPass") },
      { value: "Bancnet", label: this.props.t("banchnet") },
      { value: "MIR", label: this.props.t("mir") },
      { value: "TROY", label: this.props.t("troy") },
      { value: "SALTO JustIN Mobile", label: this.props.t("saltoJustINMobile") },
      { value: "Indian Oil Corporation Limited", label: this.props.t("indianOilCorporationLimited") },
      { value: "Zimswitch", label: this.props.t("zimswitch") },
      { value: "VSDC RF", label: this.props.t("vsdcRF") },
      { value: "OPBytes", label: this.props.t("opBytes") },
      { value: "Paylife", label: this.props.t("paylife") },
      { value: "Ministry of Finance of Georgia", label: this.props.t("ministryOfFinanceOfGeorgia") },
      { value: "Giesecke&Devrient", label: this.props.t("gieseckeDevrient") },
      { value: "IBM Laboratories", label: this.props.t("ibmLaboratories") },
      { value: "ZKA", label: this.props.t("zka") },
      { value: "Wolfgang Rankl", label: this.props.t("wolfgangRankl") },
      { value: "BAPT/BSI", label: this.props.t("baptBSI") },
      { value: "NXP Semiconductors / NFC Forum", label: this.props.t("nxpSemiconductorsNFCForum") },
      { value: "Giesecke&Devrient Java Card Telecommunikation", label: this.props.t("gieseckeDevrientJavaCardTelecommunikation") },
      { value: "fsfEurope", label: this.props.t("fsfEurope") },
      { value: "gematik", label: this.props.t("gematik") },
      { value: "KVK (Dt. Krankenversichertenkarte)", label: this.props.t("kvk") },
      { value: "KAuth", label: this.props.t("KAuth") },
      { value: "KCert", label: this.props.t("kCert") },
      { value: "Bankaxept", label: this.props.t("bankaxept") },
      { value: "Swiss Travel Fund (Reka)", label: this.props.t("swissTravelFundReka") },
      { value: "Migros (FCM, GE Money Bank and MasterCard)", label: this.props.t("migros") },
      { value: "PROSTIR", label: this.props.t("prostir") },
      { value: "nPA,German eID", label: this.props.t("nPAGermanEID") },
      { value: "AlphaCard application", label: this.props.t("alphaCardApplication") },
      { value: "Carte vitale", label: this.props.t("carteVitale") },
      { value: "BRADESCO", label: this.props.t("bradesco") },
      { value: "KTF", label: this.props.t("ktf") }

    ];

    const defaultValues = {}
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | {this.props.t("aid")}</title>
              <meta name="description" content="Application Identifiers (AID) are numeric codes used in smart cards and other devices to identify applications or functions. Learn about AID format, usage, and common application identifiers in smart card technology. EMV Tag '4F'. " />
              <meta name="keywords" content="AID, Application Identifiers, Smart Card, EMV Tag 4F, Vendor, ISO 7816, NFC, Contactless, Payment, Banking, Identification, Authentication, Format, Usage, Smart Card Technology" />

            </MetaTags>
          </Helmet>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("aid")} breadcrumbItem={this.props.t("applicationIdentifiers")} />
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit} model={defaultValues}>
                      <Row className="rowAid">
                        <Col>
                          <FormGroup className="select2-container">
                            <Label className="control-label">{this.props.t("vendor")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Vendor"
                            >
                              {this.props.t("vendorInfo")}
                            </UncontrolledTooltip>
                            <Select
                              defaultValue={vendor[0]}
                              options={vendor}
                              onChange={this.handleSelectVendor}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Vendor"
                              ></i></div>
                          </FormGroup>

                          <FormGroup>
                            <Label >
                              {this.props.t("AID")}
                            </Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="aid"
                            >
                              {this.props.t("Application Identifiers")}
                            </UncontrolledTooltip>
                            <AvField
                              name="aid"
                              placeholder={this.props.t("")}
                              type="text"
                              value={this.state.aid.aidMode}
                              className="form-control"
                              onChange={this.handleValidAid}
                              validate={{ required: { value: false } }}
                            />
                            {this.state.disthresh2 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.aidMode}{"Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="aid"
                              ></i>
                            </div>
                          </FormGroup>


                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="secondary"
                          onClick={() => window.location.reload()}
                          style={{ textAlign: "start" }}
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          onClick={() => this.setState({ isEncrypt: true })}
                          disabled={this.state.buttonTimer}
                        >
                          {this.props.t("searchButton")}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

AID.propTypes = {
  t: PropTypes.any,
}

export default (withTranslation()(AID));
