import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb";
import Select from "react-select"
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import "../../pages.scss"
import { getResponse } from "../../../helpers/api_helper"
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class ICCMasterKey extends Component {
  constructor(props) {
    super(props)
    this.state = {
      option: "",
      iccMasterKey: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      parityPreferenceMode: [],
      buttonTimer: false,
      disthresh1: false,
      disthresh2: false,
      disthresh3: false,
      imkChange: "",
      panNumberChange: "",
      panSeqNumberChange: "",
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.handleSelectParityPreference = this.handleSelectParityPreference.bind(this)
    this.imkChange = this.imkChange.bind(this);
    this.panNumberChange = this.panNumberChange.bind(this);
    this.panSeqNumberChange = this.panSeqNumberChange.bind(this);
  }

  imkChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ imkChange: event.target.value.length })
  }

  panNumberChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh2: true })
    } else {
      this.setState({ disthresh2: false })
    }
    this.setState({ panNumberChange: event.target.value.length })
  }

  panSeqNumberChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh3: true })
    } else {
      this.setState({ disthresh3: false })
    }
    this.setState({ panSeqNumberChange: event.target.value.length })
  }

  handleSelectParityPreference(e) {
    this.setState({
      iccMasterKey: ({
        ...this.state.iccMasterKey,
        parityPreferenceMode: e.value
      })
    })
  }


  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })

    const cloudInput = {
      option: this.state.option,
      imk: values.imk,
      panNumber: values.panNumber,
      panSeqNumber: values.panSeqNumber,
      parityPreference: this.state.iccMasterKey.parityPreferenceMode
    };


    getResponse(cloudInput, "/cloud/v1/ICCMasterKey/Encode")
      .then((resp) => {
        this.setState({ buttonTimer: false })

        // response successfully fetched
        if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
          this.setState({ respData: [resp.resultData] })

          // history işlemleri için
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addSuccessItem();
        }
        // response could not be fetched
        else {
          this.setState({ respErrorData: [resp.resultDesc] })

          // history işlemleri için
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addFailItem();
        }
      })
  }


  addSuccessItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("iccMasterKeyEncodingOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
  }
  addFailItem = () => {

    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("iccMasterKeyEncodingOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));

  }


  render() {

    const parityPreference = [
      { value: "None", label: this.props.t("No Parity") },
      { value: "Odd", label: this.props.t("Odd") },
      { value: "Even", label: this.props.t("Even") }
    ]


    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | {this.props.t("iccMasterKey")}</title>
              <meta name="description" content="UNIQUE DERIVATION KEY (UDK) is a cryptographic key used in secure communication protocols and cryptographic systems. Learn about the principles of UDK, its role in key derivation processes, and its significance in ensuring data confidentiality and integrity." />
              <meta name="keywords" content="UNIQUE DERIVATION KEY, UDK, cryptographic key, key derivation, secure communication, data confidentiality, data integrity, cryptographic systems" />
            </MetaTags>
          </Helmet>

          <Container fluid>
            <Breadcrumbs title={this.props.t("iccMasterKey")} breadcrumbItem={this.props.t("iccMasterKey")} />
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit}>
                      <Row className="rowAes">

                        <Col>
                          <FormGroup>
                            <AvRadioGroup inline name="option" required errorMessage="Pick one!">
                              <Label htmlFor="productname">{this.props.t("option")}</Label>
                              <br />
                              <UncontrolledTooltip
                                placement="bottom"
                                target="Option"
                              >
                                {this.props.t("optionInfo")}
                              </UncontrolledTooltip>
                              <AvRadio name="option" label={this.props.t("optionA")} value="A"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ option: "A" }) } }}
                              />
                              <AvRadio name="option" label={this.props.t("optionB")} value="B"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ option: "B" }) } }}
                              />
                            </AvRadioGroup>
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Option"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup className="select2-container">
                            <Label className="control-label">{this.props.t("UDK Parity")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="parityPreference"
                            >
                              {this.props.t("UDK Parity")}
                            </UncontrolledTooltip>
                            <Select
                              defaultValue={parityPreference[0]}
                              options={parityPreference}
                              onChange={this.handleSelectParityPreference}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="parityPreference"
                              ></i></div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("issuerMasterKey")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="IssuerMasterKey"
                            >
                              {this.props.t("issuerMasterKeyInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="imk"
                              placeholder={this.props.t("fill")}
                              type="input"
                              onChange={this.imkChange}
                              validate={{
                                pattern: { value: /^[A-Fa-f0-9]+$/ },
                                required: { value: true },
                                maxLength: { value: 32 },
                                minLength: { value: 32 }
                              }}
                            />
                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.imkChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="IssuerMasterKey"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("panNumber")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="PanNumber"
                            >
                              {this.props.t("panNumberInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="panNumber"
                              placeholder={this.props.t("fill")}
                              type="input"
                              onChange={this.panNumberChange}
                              validate={{
                                pattern: { value: /^[0-9]+$/ },
                                required: { value: true },
                                maxLength: { value: 19 },
                                minLength: { value: 14 }
                              }}
                            />
                            {this.state.disthresh2 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.panNumberChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="PanNumber"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("panSequenceNumber")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="PanSeqNumber"
                            >
                              {this.props.t("panSequenceNumberInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="panSeqNumber"
                              placeholder={this.props.t("fill")}
                              type="input"
                              onChange={this.panSeqNumberChange}
                              validate={{
                                pattern: { value: /^[0-9]+$/ },
                                required: { value: false },
                                maxLength: { value: 2 },
                                minLength: { value: 2 }
                              }}
                            />
                            {this.state.disthresh3 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.panSeqNumberChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="PanSeqNumber"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          onClick={() => window.location.reload()}
                          color="secondary"
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          disabled={this.state.buttonTimer}
                          color="primary"
                        >
                          {this.props.t("encodeButton")}
                        </Button>
                      </div>
                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ICCMasterKey.propTypes = {
  t: PropTypes.any,
}

export default (withTranslation()(ICCMasterKey));
