import {
  TKBENCODE,
  TKBENCODE_SUCCESS,
  TKBENCODE_FAIL,

  TKBDECODE,
  TKBDECODE_FAIL,
  TKBDECODE_SUCCESS
} from "./actionTypes"


//Encode
export const newTkbEncode = tkbEncode => ({
  type: TKBENCODE,
  payload: tkbEncode,
})

export const tkbEncodeSuccess = tkbEncode => ({
  type: TKBENCODE_SUCCESS,
  payload: tkbEncode,
})

export const tkbEncodeFail = error => ({
  type: TKBENCODE_FAIL,
  payload: error,
})

//Decode
export const newTKBDecode = tkbDecode => ({
  type: TKBDECODE,
  payload: tkbDecode,
})

export const tkbDecodeSuccess = tkbDecode => ({
  type: TKBDECODE_SUCCESS,
  payload: tkbDecode,
})

export const tkbDecodeFail = error => ({
  type: TKBDECODE_FAIL,
  payload: error,
})


