import {
  GET_TEST_DEFINITIONS_SUCCESS,
  GET_TEST_DEFINITIONS_FAIL,
  ADD_TEST_DEFINITION_SUCCESS,
  ADD_TEST_DEFINITION_FAIL,
  UPDATE_TEST_DEFINITION_SUCCESS,
  UPDATE_TEST_DEFINITION_FAIL,
  DELETE_TEST_DEFINITION_SUCCESS,
  DELETE_TEST_DEFINITION_FAIL,

  GET_MEMBER_DEFINITIONS_SUCCESS,
  GET_MEMBER_DEFINITIONS_FAIL,
  ADD_MEMBER_DEFINITION_SUCCESS,
  ADD_MEMBER_DEFINITION_FAIL,
  UPDATE_MEMBER_DEFINITION_SUCCESS,
  UPDATE_MEMBER_DEFINITION_FAIL,
  DELETE_MEMBER_DEFINITION_SUCCESS,
  DELETE_MEMBER_DEFINITION_FAIL,

  GET_PAGE_DEFINITIONS_SUCCESS,
  GET_PAGE_DEFINITIONS_FAIL,
  ADD_PAGE_DEFINITION_SUCCESS,
  ADD_PAGE_DEFINITION_FAIL,
  UPDATE_PAGE_DEFINITION_SUCCESS,
  UPDATE_PAGE_DEFINITION_FAIL,
  DELETE_PAGE_DEFINITION_SUCCESS,
  DELETE_PAGE_DEFINITION_FAIL,

  GET_ROLE_DEFINITIONS_SUCCESS,
  GET_ROLE_DEFINITIONS_FAIL,
  ADD_ROLE_DEFINITION_SUCCESS,
  ADD_ROLE_DEFINITION_FAIL,
  UPDATE_ROLE_DEFINITION_SUCCESS,
  UPDATE_ROLE_DEFINITION_FAIL,
  DELETE_ROLE_DEFINITION_SUCCESS,
  DELETE_ROLE_DEFINITION_FAIL,

  GET_USER_DEFINITIONS_SUCCESS,
  GET_USER_DEFINITIONS_FAIL,
  ADD_USER_DEFINITION_SUCCESS,
  ADD_USER_DEFINITION_FAIL,
  UPDATE_USER_DEFINITION_SUCCESS,
  UPDATE_USER_DEFINITION_FAIL,
  DELETE_USER_DEFINITION_SUCCESS,
  DELETE_USER_DEFINITION_FAIL,


} from "./actionTypes"

const INIT_STATE = {
  testDefinitions: [],
  memberDefinitions: [],
  pageDefinitions: [],
  roleDefinitions: [],
  userDefinitions: [],
  error: {},
}

const TestDefinition = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_TEST_DEFINITIONS_SUCCESS:
      return {
        ...state,
        testDefinitions: action.payload,
      }

    case GET_TEST_DEFINITIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_TEST_DEFINITION_SUCCESS:
      return {
        ...state,
        testDefinitions: [...state.testDefinitions, action.payload],
      }

    case ADD_TEST_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_TEST_DEFINITION_SUCCESS:
      return {
        ...state,
        testDefinitions: state.testDefinitions.map(testDefinition =>
          testDefinition.guid.toString() === action.payload.guid.toString()
            ? { testDefinition, ...action.payload }
            : testDefinition
        ),
      }

    case UPDATE_TEST_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_TEST_DEFINITION_SUCCESS:
      return {
        ...state,
        testDefinitions: state.testDefinitions.filter(
          testDefinition => testDefinition.guid.toString() !== action.payload.guid.toString()
        ),
      }

    case DELETE_TEST_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // MEMBER DEFINITION

    case GET_MEMBER_DEFINITIONS_SUCCESS:
      return {
        ...state,
        memberDefinitions: action.payload,
      }

    case GET_MEMBER_DEFINITIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_MEMBER_DEFINITION_SUCCESS:
      return {
        ...state,
        memberDefinitions: [...state.memberDefinitions, action.payload],
      }

    case ADD_MEMBER_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_MEMBER_DEFINITION_SUCCESS:
      return {
        ...state,
        memberDefinitions: state.memberDefinitions.map(memberDefinition =>
          memberDefinition.guid.toString() === action.payload.guid.toString()
            ? { memberDefinition, ...action.payload }
            : memberDefinition
        ),
      }

    case UPDATE_MEMBER_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_MEMBER_DEFINITION_SUCCESS:
      return {
        ...state,
        memberDefinitions: state.memberDefinitions.filter(
          memberDefinition => memberDefinition.guid.toString() !== action.payload.guid.toString()
        ),
      }

    case DELETE_MEMBER_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // PAGE DEFINITION

    case GET_PAGE_DEFINITIONS_SUCCESS:
      return {
        ...state,
        pageDefinitions: action.payload,
      }

    case GET_PAGE_DEFINITIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PAGE_DEFINITION_SUCCESS:
      return {
        ...state,
        pageDefinitions: [...state.pageDefinitions, action.payload],
      }

    case ADD_PAGE_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_PAGE_DEFINITION_SUCCESS:
      return {
        ...state,
        pageDefinitions: state.pageDefinitions.map(pageDefinition =>
          pageDefinition.guid.toString() === action.payload.guid.toString()
            ? { pageDefinition, ...action.payload }
            : pageDefinition
        ),
      }

    case UPDATE_PAGE_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PAGE_DEFINITION_SUCCESS:
      return {
        ...state,
        pageDefinitions: state.pageDefinitions.filter(
          pageDefinition => pageDefinition.guid.toString() !== action.payload.guid.toString()
        ),
      }

    case DELETE_PAGE_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // ROLE DEFINITION 

    case GET_ROLE_DEFINITIONS_SUCCESS:
      return {
        ...state,
        roleDefinitions: action.payload,
      }

    case GET_ROLE_DEFINITIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ROLE_DEFINITION_SUCCESS:
      return {
        ...state,
        roleDefinitions: [...state.roleDefinitions, action.payload],
      }

    case ADD_ROLE_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_ROLE_DEFINITION_SUCCESS:
      return {
        ...state,
        roleDefinitions: state.roleDefinitions.map(roleDefinition =>
          roleDefinition.guid.toString() === action.payload.guid.toString()
            ? { roleDefinition, ...action.payload }
            : roleDefinition
        ),
      }

    case UPDATE_ROLE_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ROLE_DEFINITION_SUCCESS:
      return {
        ...state,
        roleDefinitions: state.roleDefinitions.filter(
          roleDefinition => roleDefinition.guid.toString() !== action.payload.guid.toString()
        ),
      }

    case DELETE_ROLE_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // USER DEFINITION

    case GET_USER_DEFINITIONS_SUCCESS:
      return {
        ...state,
        userDefinitions: action.payload,
      }

    case GET_USER_DEFINITIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_USER_DEFINITION_SUCCESS:
      return {
        ...state,
        userDefinitions: [...state.userDefinition, action.payload],
      }

    case ADD_USER_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_USER_DEFINITION_SUCCESS:
      return {
        ...state,
        userDefinitions: state.userDefinitions.map(userDefinition =>
          userDefinition.guid.toString() === action.payload.guid.toString()
            ? { userDefinition, ...action.payload }
            : userDefinition
        ),
      }

    case UPDATE_USER_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_USER_DEFINITION_SUCCESS:
      return {
        ...state,
        userDefinitions: state.userDefinitions.filter(
          userDefinition => userDefinition.guid.toString() !== action.payload.guid.toString()
        ),
      }

    case DELETE_USER_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:

      return state

  }
}

export default TestDefinition