import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "../../pages.scss"
import { getResponse } from "../../../helpers/api_helper"
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";


class AppUsageControlDecoder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tlvParsers: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      histjson: [],
      items: [],
      isCustomTlv: false,
      disthresh1: false,
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.dataChange = this.dataChange.bind(this)
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    const cloudInput = {
      data: values.data,
    }
    getResponse(cloudInput, "/cloud/v1/AppUsageControl/AppUsageControl")
      .then((resp) => {
        this.setState({ buttonTimer: false })
        if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
          this.setState({ respData: [resp.resultData] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addSuccessItem();
        }
        else {
          this.setState({ respErrorData: [resp.resultDesc] })
          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addFailItem();
        }
      })
  }

  addSuccessItem = () => {
    this.setState({ items: [] });

    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("appUssageControlOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>

      ]

    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-success" role="alert">
    //         <h4 className="alert-heading">AppUsageControl decode operation finished with success</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // )
    // this.setState({ items: [...this.state.items] })
  }

  addFailItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("appUssageControlOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }))

    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-danger" role="alert">
    //         <h4 className="alert-heading">AppUsageControl decode operation finished with fail</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respErrorData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // );
    // this.setState({ items: [...this.state.items] })
  }

  dataChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ dataChange: event.target.value.length })
  }



  render() {
    const defaultValues = { searchOption: true }
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | App Usage Control </title>
              <meta name="description" content="App Usage Control (AUC) is a security feature used in mobile devices and applications to control access to specific features or functionalities based on predefined rules or permissions. Indicates issuer-specified restrictions on the geographic usage and services allowed for the card application. Learn about AUC implementation, benefits, and its role in enhancing mobile app security." />
              <meta name="keywords" content="AUC, App Usage Control, EMV Tag 9F07, Mobile Security, Access Control, Permissions, Mobile Applications, Security Feature, Authentication, Authorization, Usage Restrictions, Mobile Device Management, MDM" />

            </MetaTags>
          </Helmet>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("appUsageControlDecoder")} breadcrumbItem="Application Usage Control" />
            <div>
              Indicates issuer-specified restrictions on the geographic usage and services allowed for the card application.
              <br />
              <br />
              <br />
            </div>
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit} >

                      <Row className="rowAes">
                        <Col>
                          <FormGroup>
                            <Label htmlFor="productdesc">
                              {this.props.t("AUC")}
                            </Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Data"
                            >{this.props.t("aucDataInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              style={{ height: 30 }}
                              name="data"
                              placeholder={this.props.t("4 hex digits")}
                              type="textarea"
                              className="form-control"
                              onChange={this.dataChange}
                              validate={{
                                pattern: { value: /^[0-9A-Fa-f]+$/ },
                                required: { value: true },
                                maxLength: { value: 4 },
                                minLength: { value: 4 }
                              }} />
                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.dataChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Data"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          onClick={() => window.location.reload()}
                          color="secondary"
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          disabled={this.state.buttonTimer}
                          color="primary"
                        >
                          {this.props.t("decodeButton")}
                        </Button>
                      </div>

                    </AvForm>
                  </CardBody>
                </Card>
              </Col>

              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
AppUsageControlDecoder.propTypes = {
  t: PropTypes.any
}
export default (withTranslation()(AppUsageControlDecoder));