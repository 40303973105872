import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "../../pages.scss"
import { getResponse } from "../../../helpers/api_helper"
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class MACDerivation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      MACvalues: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      buttonTimer: false,
      disthresh1: false,
      disthresh2: false,
      disthresh3: false,
      disthresh4: false,
      disthresh5: false,
      disthresh6: false,
      disthresh7: false,
      disthresh8: false,
      key1Change: "",
      key2Change: "",
      key3Change: "",
      secondKey1Change: "",
      secondKey2Change: "",
      secondKey3Change: "",
      dataChange: "",
      truncationChange: "",
    }
    this.key1Change = this.key1Change.bind(this);
    this.key2Change = this.key2Change.bind(this);
    this.key3Change = this.key3Change.bind(this);
    this.secondKey1Change = this.secondKey1Change.bind(this);
    this.secondKey2Change = this.secondKey2Change.bind(this);
    this.secondKey3Change = this.secondKey3Change.bind(this);
    this.dataChange = this.dataChange.bind(this);
    this.truncationChange = this.truncationChange.bind(this);
    this.handleSelectAlgorithm = this.handleSelectAlgorithm.bind(this);
    this.handleSelectPadding = this.handleSelectPadding.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);

  }

  key1Change(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ key1Change: event.target.value.length })
  }

  key2Change(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh2: true })
    } else {
      this.setState({ disthresh2: false })
    }
    this.setState({ key2Change: event.target.value.length })
  }

  key3Change(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh3: true })
    } else {
      this.setState({ disthresh3: false })
    }
    this.setState({ key3Change: event.target.value.length })
  }

  secondKey1Change(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh4: true })
    } else {
      this.setState({ disthresh4: false })
    }
    this.setState({ secondKey1Change: event.target.value.length })
  }

  secondKey2Change(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh5: true })
    } else {
      this.setState({ disthresh5: false })
    }
    this.setState({ secondKey2Change: event.target.value.length })
  }

  secondKey3Change(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh6: true })
    } else {
      this.setState({ disthresh6: false })
    }
    this.setState({ secondKey3Change: event.target.value.length })
  }

  dataChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh7: true })
    } else {
      this.setState({ disthresh7: false })
    }
    this.setState({ dataChange: event.target.value.length })
  }

  truncationChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh8: true })
    } else {
      this.setState({ disthresh8: false })
    }
    this.setState({ truncationChange: event.target.value.length })
  }

  handleSelectAlgorithm(e) {
    this.setState({
      MACvalues: ({
        ...this.state.MACvalues,
        algorithm: e.value
      })
    })
  }

  handleSelectPadding(e) {
    this.setState({
      MACvalues: ({
        ...this.state.MACvalues,
        paddingMethod: e.value
      })
    })
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    const { MACvalues } = this.state

    const cloudInput = {
      algorithm: MACvalues.algorithm,
      paddingMethod: MACvalues.paddingMethod,
      key: values.key1,
      key2: values.key2,
      key3: values.key3,
      secondKey: values.secondKey,
      secondKey2: values.secondKey2,
      secondKey3: values.secondKey3,
      data: values.data,
      truncation: values.truncation
    };


    getResponse(cloudInput, "/cloud/v1/Mac/Derive")
      .then((resp) => {
        this.setState({ buttonTimer: false })

        if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
          this.setState({ respData: [resp.resultData] })

          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addSuccessItem();
        }
        else {
          this.setState({ respErrorData: [resp.resultDesc] })

          const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
          if (dnm != null && dnm != 0) {
            this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
            const a = [...this.state.histjson]
            a.push(resp)
            localStorage.setItem("jsonHistory", JSON.stringify(a));
          } else {
            localStorage.setItem("jsonHistory", JSON.stringify([resp]));
          }
          this.addFailItem();
        }
      })
  }

  addSuccessItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("macDerivationOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-success" role="alert">
    //         <h4 className="alert-heading">{this.props.t("macDerivationOperationSuccess")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // )
    // this.setState({ items: [...this.state.items] })
  }

  addFailItem = () => {
    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("macDerivationOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-danger" role="alert">
    //         <h4 className="alert-heading">{this.props.t("macDerivationOperationFail")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respErrorData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // );
    // this.setState({ items: [...this.state.items] })
  }

  render() {
    const algorithm = [
      { value: "1", label: this.props.t("macAlgorithm1") },
      { value: "2", label: this.props.t("macAlgorithm2") },
      { value: "3", label: this.props.t("macAlgorithm3") },
      // { value: "4", label: this.props.t("macAlgorithm4") },
      // { value: "5", label: this.props.t("macAlgorithm5") },
      // { value: "6", label: this.props.t("macAlgorithm6") },
    ]

    const padding = [
      { value: "1", label: this.props.t("paddingMethod1") },
      { value: "2", label: this.props.t("paddingMethod2") },
      { value: "3", label: this.props.t("paddingMethod3") },
    ]

    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | {this.props.t("macDerivation")}</title>
              <meta name="description" content="MAC Derivation (Message Authentication Code) is a process used in cryptography to generate a unique code that authenticates the integrity and origin of a message. Explore the principles and techniques behind MAC derivation, its applications in ensuring data integrity and authenticity, and its significance in secure communication protocols." />
              <meta name="keywords" content="MAC Derivation, Message Authentication Code, cryptography, data integrity, message authentication, secure communication, cryptographic techniques, authentication code" />
            </MetaTags>
          </Helmet>

          <Container fluid>

            <Breadcrumbs title={this.props.t("mac")} breadcrumbItem={this.props.t("macDerivation")} />

            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit}>
                      <Row className="rowAes">

                        <Col sm="12">
                          <FormGroup id="tooltipshowtest" className="select-container">
                            <Label className="control-label">{this.props.t("macAlgorithm")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Algorithm"
                            >
                              {this.props.t("macAlgorithmInfo")}
                            </UncontrolledTooltip>

                            <Select
                              validate={{
                                required: { value: true }
                              }}
                              options={algorithm}
                              onChange={this.handleSelectAlgorithm}
                            />

                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Algorithm"
                              ></i></div>
                          </FormGroup>
                        </Col>

                        <Col sm="12">
                          <FormGroup id="tooltipshowtest" className="select-container">
                            <Label className="control-label">{this.props.t("peddingMethod")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="PaddingMethod"
                            >
                              {this.props.t("macPaddingMethodInfo")}
                            </UncontrolledTooltip>

                            <Select
                              options={padding}
                              validate={{
                                required: { value: true }
                              }}
                              onChange={this.handleSelectPadding}
                            />

                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="PaddingMethod"
                              ></i></div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("key")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Key1"
                            >
                              {this.props.t("keyInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="key1"
                              placeholder={this.props.t("fill")}
                              type="input"
                              onChange={this.key1Change}
                              validate={{
                                maxLength: { value: 16 },
                                minLength: { value: 16 },
                                pattern: { value: /^[A-Fa-f0-9]+$/ },
                                required: { value: true }
                              }}
                            />
                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.key1Change}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Key1"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("key2")}</Label>
                            <UncontrolledTooltip
                              pCollacement="bottom"
                              target="Key2"
                            >
                              {this.props.t("key2Info")}
                            </UncontrolledTooltip>
                            {this.state.MACvalues.algorithm === "3" || this.state.MACvalues.algorithm === "2" ?
                              <AvField
                                name="key2"
                                placeholder={this.props.t("fill")}
                                type="input"
                                onChange={this.key2Change}
                                validate={{
                                  maxLength: { value: 16 },
                                  minLength: { value: 16 },
                                  pattern: { value: /^[0-9A-F]+$/ },
                                  required: { value: true }
                                }}
                              /> :
                              <AvField
                                name="key2"
                                placeholder={this.props.t("fill")}
                                type="input"
                                onChange={this.key2Change}
                                disabled={this.state.MACvalues.algorithm === "1"} // Eğer algorithm 1 ise devre dışı bırak
                                validate={{
                                  maxLength: { value: 16 },
                                  minLength: { value: 16 },
                                  pattern: { value: /^[0-9A-F]+$/ },
                                  required: { value: false }
                                }}
                              />}
                            {this.state.disthresh2 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.key2Change}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Key2"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                        {
                          // waiting for api for this section to be added
                        }
                        {/* <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("key3")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Key3"
                            >
                              {this.props.t("key3Info")}
                            </UncontrolledTooltip>
                            
                            <AvField
                              name="key3"
                              placeholder={this.props.t("fill")}
                              type="input"
                              onChange={this.key3Change}
                              validate={{
                                maxLength:{value:16},
                                minLength:{value:16},
                                pattern: { value: /^[0-9A-F]+$/ },
                                required: { value: false }
                              }}
                            />
                            {this.state.disthresh3 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.key3Change}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Key3"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("secondInstanceKey")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="SecondInstanceKey1"
                            >
                              {this.props.t("secondInstanceKeyInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="secondInstanceKey1"
                              placeholder={this.props.t("fill")}
                              type="input"
                              onChange={this.secondKey1Change}
                              validate={{
                                maxLength:{value:16},
                                minLength:{value:16},
                                pattern: { value: /^[0-9A-F]+$/ },
                                required: { value: false }
                              }}
                            />
                            {this.state.disthresh4 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.secondKey1Change}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="SecondInstanceKey1"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("secondInstanceKey2")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="SecondInstanceKey2"
                            >
                              {this.props.t("secondInstanceKey2Info")}
                            </UncontrolledTooltip>
                            <AvField
                              name="secondInstanceKey2"
                              placeholder={this.props.t("fill")}
                              type="input"
                              onChange={this.secondKey2Change}
                              validate={{
                                maxLength:{value:16},
                                minLength:{value:16},
                                pattern: { value: /^[0-9A-F]+$/ },
                                required: { value: false }
                              }}
                            />
                            {this.state.disthresh5 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.secondKey2Change}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="SecondInstanceKey2"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("secondInstanceKey3")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="SecondInstanceKey3"
                            >
                              {this.props.t("secondInstanceKey3Info")}
                            </UncontrolledTooltip>
                            <AvField
                              name="secondInstanceKey3"
                              placeholder={this.props.t("fill")}
                              type="input"
                              onChange={this.secondKey3Change}
                              validate={{
                                pattern: { value: /^[0-9A-F]+$/ },
                                required: { value: false },
                                maxLength:{value:16},
                                minLength:{value:16}
                              }}
                            />
                            {this.state.disthresh6 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.secondKey3Change}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="SecondInstanceKey3"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col> */}

                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("data")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Data"
                            >
                              {this.props.t("macDerivationDataInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="data"
                              placeholder={this.props.t("fill")}
                              type="input"
                              onChange={this.dataChange}
                              validate={{
                                pattern: { value: /^[0-9A-F]+$/ },
                                required: { value: true }
                              }}
                            />
                            {this.state.disthresh7 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.dataChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Data"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("truncation")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Truncation"
                            >
                              {this.props.t("truncationInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="truncation"
                              placeholder={this.props.t("fill")}
                              type="input"
                              onChange={this.truncationChange}
                              validate={{
                                maxLength: { value: 1 },
                                pattern: { value: /^[0-9]+$/ },
                                required: { value: true }
                              }}
                            />
                            {this.state.disthresh8 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.truncationChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Truncation"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          onClick={() => window.location.reload()}
                          color="secondary"
                          className="buttonDecode"
                        >
                          Clear
                        </Button>
                        <Button
                          type="submit"
                          disabled={this.state.buttonTimer}
                          color="primary"
                        >
                          Encode
                        </Button>
                      </div>
                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

MACDerivation.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(MACDerivation)
