import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "../../pages.scss"
import { getResponse } from "../../../helpers/api_helper"

//i18n
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class TR31KBEncode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tr31KBEncodes: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      disthresh1: false,
      disthresh2: false,
      disthresh3: false,
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleSelectExportability = this.handleSelectExportability.bind(this);
    this.handleSelectModeOfUse = this.handleSelectModeOfUse.bind(this)
    this.handleSelectVersionId = this.handleSelectVersionId.bind(this)
    this.handleSelectKeyUsage = this.handleSelectKeyUsage.bind(this)
    this.handleSelectAlgorithm = this.handleSelectAlgorithm.bind(this)
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.kbpkChange = this.kbpkChange.bind(this);
    this.plainKeyChange = this.plainKeyChange.bind(this)
    this.paddingChange = this.paddingChange.bind(this)
  }

  handleValidSubmit = (e, values) => {
    const { tr31KBEncodes } = this.state

    const cloudInput = {
      kbpk: values.kbpk,
      plainKey: values.plainKey,
      padding: values.padding,
      versionId: tr31KBEncodes.versionId,
      keyUsage: tr31KBEncodes.keyUsage,
      algorithm: tr31KBEncodes.algorithm,
      modeOfUse: tr31KBEncodes.modeOfUse,
      keyVersion: values.keyVersion,
      exportability: tr31KBEncodes.exportability,
      keyBlocks: values.keyBlocks,
      reserved: values.reserved,
      optionalHeaders: values.optionalHeaders,
    };

    if (cloudInput.reserved.length < 2) {
      cloudInput.reserved = '0' + cloudInput.reserved;
      this.setState({ cloudInput: { reserved: "0" + cloudInput.reserved, ...cloudInput.tr31KBEncodes } })
    }
    if (cloudInput.keyBlocks.length < 2) {
      cloudInput.keyBlocks = '0' + cloudInput.keyBlocks;
      this.setState({ cloudInput: { keyBlocks: "0" + cloudInput.keyBlocks, ...cloudInput.tr31KBEncodes } })
    }
    if (cloudInput.keyVersion.length < 2) {
      cloudInput.keyVersion = '0' + cloudInput.keyVersion;
      this.setState({ cloudInput: { keyVersion: "0" + cloudInput.keyVersion, ...cloudInput.tr31KBEncodes } })
    }
    //onTKBEncode(cloudInput);
    getResponse(cloudInput, "/cloud/v1/ThalesKeyblock/Encode")
      .then((resp) => {
        if (resp.resultCode !== "-1") {
          this.setState({ respData: [resp.resultData] })
          this.addSuccessItem();
        }
        else {
          this.setState({ respErrorData: [resp.resultDesc] })
          this.addFailItem();
        }
      })

  }


  handleSelectVersionId(e) {
    this.setState({
      tr31KBEncodes: ({
        ...this.state.tr31KBEncodes,
        versionId: e.value
      })
    })
  }
  handleSelectKeyUsage(e) {
    this.setState({
      tr31KBEncodes: ({
        ...this.state.tr31KBEncodes,
        keyUsage: e.value
      })
    })
  }
  handleSelectAlgorithm(e) {
    this.setState({
      tr31KBEncodes: ({
        ...this.state.tr31KBEncodes,
        algorithm: e.value
      })
    })
  }
  handleSelectModeOfUse(e) {
    this.setState({
      tr31KBEncodes: ({
        ...this.state.tr31KBEncodes,
        modeOfUse: e.value
      })
    })
  }
  handleSelectExportability(e) {
    this.setState({
      tr31KBEncodes: ({
        ...this.state.tr31KBEncodes,
        exportability: e.value
      })
    });
  }

  addSuccessItem = () => {
    this.state.items.push(<Col key={Date.now()} >
      <Card>
        <CardBody>
          <Alert className="alert-success" role="alert">
            <h4 className="alert-heading">{this.props.t("keyBlockEncryptOperationSuccess")}</h4>
            <hr />
            <div className="display-linebreak">{this.state.respData}</div>
          </Alert>
        </CardBody>
      </Card>
    </Col>
    )
    this.setState({ items: [...this.state.items] })

  }
  addFailItem = () => {
    this.state.items.push(<Col key={Date.now()} >
      <Card>
        <CardBody>
          <Alert className="alert-danger" role="alert">
            <h4 className="alert-heading">{this.props.t("keyBlockEncryptOperationFail")}</h4>
            <hr />
            <div className="display-linebreak">{this.state.respErrorData}</div>
          </Alert>
        </CardBody>
      </Card>
    </Col>
    );
    this.setState({ items: [...this.state.items] })
  }
  kbpkChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ kbpkChange: event.target.value.length })
  }

  plainKeyChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh2: true })
    } else {
      this.setState({ disthresh2: false })
    }
    this.setState({ plainKeyChange: event.target.value.length })
  }

  paddingChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh3: true })
    } else {
      this.setState({ disthresh3: false })
    }
    this.setState({ paddingChange: event.target.value.length })
  }
  render() {
    const versionID = [
      { value: "A", label: this.props.t("keyVariantBindingMethod") },
      { value: "B", label: this.props.t("tdeaKeyDerivationBindingMethod") },
      { value: "C", label: this.props.t("tdeaKeyVariantBindingMethod") },
      { value: "D", label: this.props.t("aesKeyDerivationBindingMethod") },
      { value: "0", label: "0" },
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
      { value: "8", label: "8" },
      { value: "9", label: "9" },

    ]
    const keyUsage = [
      { value: "01", label: this.props.t("watchWordKey") },
      { value: "02", label: this.props.t("rsaPublicKey") },
      { value: "03", label: this.props.t("rsaPrivateKeyForSigningKeyMgt") },
      { value: "04", label: this.props.t("rsaPrivateKeyForICCs") },
      { value: "05", label: this.props.t("rsaPrivateKeyForPINTranslation") },
      { value: "06", label: this.props.t("rsaPrivateKeyForTLSPreMasterSecretDecryption") },
      { value: "B0", label: this.props.t("baseDevirationKeyBDK1") },
      { value: "41", label: this.props.t("baseDevirationKeyBKD2") },
      { value: "42", label: this.props.t("baseDevirationKeyBKD3") },
      { value: "43", label: this.props.t("baseDevirationKeyBKD4") },
      { value: "44", label: this.props.t("baseDevirationKeyBKD5") },
      { value: "B1", label: this.props.t("dukptInitialKey") },
      { value: "C0", label: this.props.t("cardVerificationKey") },
      { value: "11", label: this.props.t("cardVerificationKeyAmericanExpressCSC") },
      { value: "12", label: this.props.t("cardVerificationKeyMastercardCVC") },
      { value: "13", label: this.props.t("cardVerificationKeyVisaCVV") },
      { value: "D0", label: this.props.t("dataEncyptionKeyGeneric") },
      { value: "21", label: this.props.t("dataEncyptionKeyDEK") },
      { value: "22", label: this.props.t("dataEncyptionKeyZEK") },
      { value: "23", label: this.props.t("dataEncyptionKeyTEK") },
      { value: "24", label: this.props.t("keyEncryptionKeyTransportKey") },
      { value: "25", label: this.props.t("ctrDataEncryptionKey") },
      { value: "E0", label: this.props.t("emvchipCardMasterKeyApplicationCryptogramMKAC") },
      { value: "E1", label: this.props.t("emvchipCardMasterKeySecureMessagingForConfidentialityMKSMC") },
      { value: "E2", label: this.props.t("emvchipCardMasterKeySecureMessagingForIntegrityMKSMI") },
      { value: "E3", label: this.props.t("emvchipCardMasterKeyDataAuthenticationCodeMKDAC") },
      { value: "E4", label: this.props.t("emvchipCardMasterKeyDynamicNumbersMKDN") },
      { value: "E5", label: this.props.t("emvchipCardMasterKeyCardPersonalization") },
      { value: "E6", label: this.props.t("emvChipCardMasterKeyOther") },
      { value: "E7", label: this.props.t("envMasterPersonalizationKey") },
      { value: "31", label: this.props.t("visaCashMasterLoadKeyKML") },
      { value: "32", label: this.props.t("dynamicCVVMasterKeyMKCVC3") },
      { value: "33", label: this.props.t("mobileRemoteManagementMasterKeyForMessageConfidentianlity") },
      { value: "34", label: this.props.t("mobileRemoteManagementMasterKeyForMessageIntegrity") },
      { value: "35", label: this.props.t("mobileRemoteManagementSessionKeyForMessageConfidentianlity") },
      { value: "36", label: this.props.t("mobileRemoteManagementSessionKeyForMessageIntegrity") },
      { value: "37", label: this.props.t("emvCardKeyForCryptograms") },
      { value: "38", label: this.props.t("emvCardKeyForIntegrity") },
      { value: "39", label: this.props.t("emvCardKeyForEncryption") },
      { value: "40", label: this.props.t("emvPersonalizationSystemKey") },
      { value: "47", label: this.props.t("emvSessionKeyCryptograms") },
      { value: "48", label: this.props.t("emvSessionKeyIntegrity") },
      { value: "49", label: this.props.t("emvSessionKeyEncryption") },
      { value: "I0", label: this.props.t("initializationValue") },
      { value: "K0", label: this.props.t("keyEncyptionWrappingKeyGeneric") },
      { value: "51", label: this.props.t("terminalKeyEncryptionTMK") },
      { value: "52", label: this.props.t("zoneKeyEncryptionZMK") },
      { value: "53", label: this.props.t("zkaMasterKey") },
      { value: "54", label: this.props.t("keyEncriptionKeyKEK") },
      { value: "55", label: this.props.t("keyEncriptionKeyTransportKey") },
      { value: "M0", label: this.props.t("iso16609MACAlgorithm1") },
      { value: "M1", label: this.props.t("iso9797MACAlgorithm1") },
      { value: "M2", label: this.props.t("iso9797MACAlgorithm2") },
      { value: "M3", label: this.props.t("iso9797MACAlgorithm3") },
      { value: "M4", label: this.props.t("iso9797MACAlgorithm4") },
      { value: "M5", label: this.props.t("iso97971999MACAlgorithm5") },
      { value: "M6", label: this.props.t("iso97972011MACAlgorithm5CMAC") },
      { value: "61", label: this.props.t("hmacKeySHA1") },
      { value: "62", label: this.props.t("hmacKeySHA224") },
      { value: "63", label: this.props.t("hmacKeySHA256") },
      { value: "64", label: this.props.t("hmacKeySHA384") },
      { value: "65", label: this.props.t("hmacKeySHA512") },
      { value: "P0", label: this.props.t("pinEncryptionKey") },
      { value: "71", label: this.props.t("terminalPINEncryptionKey") },
      { value: "72", label: this.props.t("zoneEncryptionKey") },
      { value: "73", label: this.props.t("transactionKeySchemeTerminalKeyRegister") },
      { value: "V0", label: this.props.t("pinVerificationKeyGeneric") },
      { value: "V1", label: this.props.t("pinVerificationKeyIBM3624Algorithm") },
      { value: "V2", label: this.props.t("pinVerificationKeyVisaPVVAlgorithm") }
    ]
    const algorithm = [
      { value: "A", label: this.props.t("aes") },
      { value: "D", label: this.props.t("des") },
      { value: "E", label: this.props.t("ellipticCurve") },
      { value: "H", label: this.props.t("hmac") },
      { value: "R", label: this.props.t("rsa") },
      { value: "S", label: this.props.t("dsa") },
      { value: "T", label: this.props.t("3-DES") }
    ]
    const modOfUse = [
      { value: "B", label: this.props.t("bothEncryptionAndDecryption") },
      { value: "C", label: this.props.t("bothGenerateAndVerify") },
      { value: "D", label: this.props.t("decryptOnly") },
      { value: "E", label: this.props.t("encryptOnly") },
      { value: "G", label: this.props.t("generateOnly") },
      { value: "N", label: this.props.t("noSpecialRestrictionsOrNotApplicable") },
      { value: "S", label: this.props.t("signatureGenerationOnly") },
      { value: "V", label: this.props.t("verifyOnly") },
      { value: "X", label: this.props.t("derivationOnly") }
    ]
    const exportability = [
      { value: "E", label: this.props.t("mayOnlyBeExportedInATrustedKeyBlock") },
      { value: "N", label: this.props.t("noExportPermitted") },
      { value: "S", label: this.props.t("sensitive") }

    ]
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | {this.props.t("tr31KeyBlock")} {this.props.t("encode")}</title>
              <meta name="description" content="TR-31 Key Block Encode refers to the process of encoding cryptographic key blocks according to the TR-31 (Technical Report 31) standard, which specifies a method for securely exchanging cryptographic keys between devices such as point-of-sale (POS) terminals and key injection facilities. Explore the principles and techniques behind TR-31 key block encoding, its applications in secure key management, and its significance in ensuring data confidentiality and integrity in payment systems." />
              <meta name="keywords" content="TR-31 Key Block Encode, TR-31, Technical Report 31, key block encryption, key management, secure key exchange, POS terminals, key injection, data confidentiality, data integrity, payment systems" />
            </MetaTags>
          </Helmet>
          
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("tr31KeyBlock")} breadcrumbItem={this.props.t("encode")} />

            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <p className="card-title-desc">{this.props.t("fillAllInformationBelow")}</p>
                    <AvForm onValidSubmit={this.handleValidSubmit}>
                      <Row>
                        <Col sm="12">
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("kbpk")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Kbpk"
                            >
                              {this.props.t("kbpkInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="kbpk"
                              type="input"
                              placeholder={this.props.t("fill")}
                              value={this.state.tr31KBEncodes
                                ? this.state.tr31KBEncodes.kbpk : ""}
                              onChange={this.kbpkChange}
                              validate={{
                                pattern: { value: /^[A-Fa-f0-9]+$/ },
                                required: { value: true }
                              }}
                            />
                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.kbpkChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Kbpk"
                              ></i></div>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("plainKey")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="PlainKey"
                            >
                              {this.props.t("plainKeyInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="plainKey"
                              placeholder={this.props.t("fill")}
                              type="input"
                              value={this.state.tr31KBEncodes
                                ? this.state.tr31KBEncodes.plainKey : ""}
                              onChange={this.plainKeyChange}
                              validate={{
                                pattern: { value: /^[A-Fa-f0-9]+$/ },
                                required: { value: true }
                              }}
                            />
                            {this.state.disthresh2 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.plainKeyChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="PlainKey"
                              ></i></div>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup className="select2-container">
                            <Label className="control-label">{this.props.t("padding")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Padding"
                            >
                              {this.props.t("paddingInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="padding"
                              placeholder={this.props.t("fill")}
                              type="input"
                              value={this.state.tr31KBEncodes
                                ? this.state.tr31KBEncodes.padding : ""}
                              onChange={this.paddingChange}
                              validate={{
                                pattern: { value: /^[A-Fa-f0-9]+$/ },
                              }}
                            />
                            {this.state.disthresh3 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.paddingChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Padding"
                              ></i></div>
                          </FormGroup>
                        </Col>

                        <Col sm="12">
                          <FormGroup id="tooltipshowtest" className="select-container">
                            <Label className="control-label">{this.props.t("versionId")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="VersionId"
                            >
                              {this.props.t("versionIdInfo")}
                            </UncontrolledTooltip>
                            <Select
                              options={versionID}
                              onChange={this.handleSelectVersionId}
                              placeholder={this.props.t("select")}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="VersionId"
                              ></i></div>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup className="select2-container">
                            <Label className="control-label">{this.props.t("keyUsage")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="KeyUsage"
                            >
                              {this.props.t("keyUsageInfo")}
                            </UncontrolledTooltip>
                            <Select
                              options={keyUsage}
                              onChange={this.handleSelectKeyUsage}
                              placeholder={this.props.t("select")}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="KeyUsage"
                              ></i></div>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup className="select2-container">
                            <Label className="control-label">{this.props.t("algorithm")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Algorithm"
                            >
                              {this.props.t("keyAlgorithmInfo")}
                            </UncontrolledTooltip>
                            <Select
                              options={algorithm}
                              onChange={this.handleSelectAlgorithm}
                              placeholder={this.props.t("select")}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Algorithm"
                              ></i></div>
                          </FormGroup>
                        </Col>

                        <Col sm="12">
                          <FormGroup className="select2-container">
                            <Label className="control-label">{this.props.t("modeOfUse")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="ModelOfUse"
                            >
                              {this.props.t("modeOfUseInfo")}
                            </UncontrolledTooltip>
                            <Select
                              options={modOfUse}
                              onChange={this.handleSelectModeOfUse}
                              placeholder={this.props.t("select")}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="ModelOfUse"
                              ></i></div>
                          </FormGroup>
                        </Col>

                        <Col sm="12">
                          <FormGroup className="select2-container ">
                            <Label className="control-label">{this.props.t("keyVersion")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="KeyVersion"
                            >
                              {this.props.t("keyVersionInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="keyVersion"
                              placeholder={this.props.t("fill")}
                              type="number"
                              value={this.state.tr31KBEncodes
                                ? this.state.tr31KBEncodes.keyVersion : ""}
                              validate={{
                                required: { value: true },
                                pattern: { value: /^[0-9]+$/ }
                              }} />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="KeyVersion"
                              ></i></div>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup className="select2-container">
                            <Label className="control-label">{this.props.t("exportability")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Exportability"
                            >
                              {this.props.t("exportabilityInfo")}
                            </UncontrolledTooltip>
                            <Select
                              options={exportability}
                              onChange={this.handleSelectExportability}
                              placeholder={this.props.t("select")}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Exportability"
                              ></i></div>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup className="select2-container ">
                            <Label className="control-label">{this.props.t("optKeyBlocks")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="KeyBlocks"
                            >
                              {this.props.t("optKeyBlocksInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="keyBlocks"
                              placeholder={this.props.t("fill")}
                              type="number"
                              value={this.state.tr31KBEncodes
                                ? this.state.tr31KBEncodes.keyBlocks : ""}
                              validate={{
                                required: { value: true }
                              }}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="KeyBlocks"
                              ></i></div>
                          </FormGroup>
                        </Col>

                        <Col sm="12">
                          <FormGroup className="select2-container">
                            <Label className="control-label">{this.props.t("reserved")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Reserved"
                            >
                              {this.props.t("reservedInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="reserved"
                              placeholder={this.props.t("fill")}
                              type="number"
                              value={this.state.tr31KBEncodes
                                ? this.state.tr31KBEncodes.reserved : ""}
                              max={99}
                              validate={{
                                required: { value: true }
                              }}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Reserved"
                              ></i></div>
                          </FormGroup>
                        </Col>

                        <Col sm="12">
                          <FormGroup className="select2-container">
                            <Label className="control-label">{this.props.t("optionalHeaders")}</Label>
                            <UncontrolledTooltip
                              placement="right"
                              target="OptionalHeaders"
                            >
                              {this.props.t("optionalHeadersInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="optionalHeaders"
                              placeholder={this.props.t("fill")}
                              type="input"
                              value={this.state.tr31KBEncodes.optionalHeaders}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="OptionalHeaders"
                              ></i></div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          onClick={() => window.location.reload()}
                          color="secondary"
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={this.state.buttonTimer}
                        >
                          {this.props.t("decodeButton")}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

TR31KBEncode.propTypes = {
  t: PropTypes.any,
}

export default (withTranslation()(TR31KBEncode));
