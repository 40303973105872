import React, { Component, useState } from "react"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import "../../pages.scss"

import { getResponse } from "../../../helpers/api_helper"

//i18n
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class AESEncrypt extends Component {
  constructor(props) {
    super(props)
    this.state = {
      aesEncrypts: [],
      respData: [],
      respErrorData: [],
      successAlert: [],
      items: [],
      paddingMode: [],
      disthresh1: false,
      disthresh2: false,
      disthresh3: false,
      ivDsb: true,
      dataDsb: false,
      dataInputDsb: false,
      isEncrypt: false,
      decodeDsb: false,
      buttonTimer: false,
      aesController: "AES-128",
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.addFailItem = this.addFailItem.bind(this);
    this.addSuccessItem = this.addSuccessItem.bind(this);
    this.ivChange = this.ivChange.bind(this);
    this.keyChange = this.keyChange.bind(this)
    this.dataChange = this.dataChange.bind(this)
    this.handleSelectPadding = this.handleSelectPadding.bind(this)
    this.handleAesController = this.handleAesController.bind(this)
  }

  handleValidSubmit = (e, values) => {
    this.setState({ buttonTimer: true })
    if (this.state.aesEncrypts.padding == null || this.state.aesEncrypts.padding == "")
      this.setState({
        aesEncrypts: ({
          ...this.state.aesEncrypts,
          paddingMode: "1"
        })
      })
    if (this.state.isEncrypt == true) {
      const cloudInput = {
        key: values.key,
        iv: values.iv,
        data: values.data,
        paddingMode: this.state.aesEncrypts.paddingMode,
        cipherMode: values.mode,
        dataInputType: values.dataInput,
        // algorithm: values.algorithm,
        // dataInput: values.dataInput,
      };

      getResponse(cloudInput, "/cloud/v1/Aes/AesEncrypt")
        .then((resp) => {
          this.setState({ buttonTimer: false })
          if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
            this.setState({ respData: [resp.resultData] })
            const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
            if (dnm != null && dnm != 0) {
              this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
              const a = [...this.state.histjson]
              a.push(resp)
              localStorage.setItem("jsonHistory", JSON.stringify(a));
            } else {
              localStorage.setItem("jsonHistory", JSON.stringify([resp]));
            }
            this.addSuccessItem();
          }
          else {
            this.setState({ respErrorData: [resp.resultDesc] })
            const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
            if (dnm != null && dnm != 0) {
              this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
              const a = [...this.state.histjson]
              a.push(resp)
              localStorage.setItem("jsonHistory", JSON.stringify(a));
            } else {
              localStorage.setItem("jsonHistory", JSON.stringify([resp]));
            }
            this.addFailItem();
          }
        })

    }
    if (this.state.isEncrypt == false) {
      const cloudInput = {
        key: values.key,
        iv: values.iv,
        data: values.data,
        paddingMode: this.state.aesEncrypts.paddingMode,
        cipherMode: values.mode,
        dataInputType: values.dataInput,
        // algorithm: values.algorithm,
        // dataInput: values.dataInput,
      };

      getResponse(cloudInput, "/cloud/v1/Aes/AesDecrypt")
        .then((resp) => {
          this.setState({ buttonTimer: false })
          if (resp.resultCode !== "-1" && resp.resultCode !== undefined) {
            this.setState({ respData: [resp.resultData] })
            const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
            if (dnm != null && dnm != 0) {
              this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
              const a = [...this.state.histjson]
              a.push(resp)
              localStorage.setItem("jsonHistory", JSON.stringify(a));
            } else {
              localStorage.setItem("jsonHistory", JSON.stringify([resp]));
            }
            this.addSuccessItem();
          }
          else {
            this.setState({ respErrorData: [resp.resultDesc] })
            const dnm = JSON.parse(localStorage.getItem("jsonHistory"))
            if (dnm != null && dnm != 0) {
              this.setState({ histjson: JSON.parse(localStorage.getItem("jsonHistory")) })
              const a = [...this.state.histjson]
              a.push(resp)
              localStorage.setItem("jsonHistory", JSON.stringify(a));
            } else {
              localStorage.setItem("jsonHistory", JSON.stringify([resp]));
            }
            this.addFailItem();
          }
        })
    }
  }


  addSuccessItem = () => {

    this.setState({ items: [] });
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()}>
          <Card>
            <CardBody>
              <Alert className="alert-success" role="alert">
                <h4 className="alert-heading">{this.props.t("aesOperationSuccess")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));
    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-success" role="alert">
    //         <h4 className="alert-heading">{this.props.t("aesOperationSuccess")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // )
    // this.setState({ items: [...this.state.items] })

  }
  addFailItem = () => {


    this.setState({ items: [] });


    this.setState(prevState => ({
      items: [
        ...prevState.items,
        <Col key={Date.now()} >
          <Card>
            <CardBody>
              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">{this.props.t("aesOperationFail")}</h4>
                <hr />
                <div className="display-linebreak">{this.state.respErrorData}</div>
              </Alert>
            </CardBody>
          </Card>
        </Col>
      ]
    }));

    // this.state.items.push(<Col key={Date.now()} >
    //   <Card>
    //     <CardBody>
    //       <Alert className="alert-danger" role="alert">
    //         <h4 className="alert-heading">{this.props.t("aesOperationFail")}</h4>
    //         <hr />
    //         <div className="display-linebreak">{this.state.respErrorData}</div>
    //       </Alert>
    //     </CardBody>
    //   </Card>
    // </Col>
    // );
    // this.setState({ items: [...this.state.items] })
  }
  keyChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh1: true })
    } else {
      this.setState({ disthresh1: false })
    }
    this.setState({ keyChange: event.target.value.length })
  }

  dataChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh2: true })
    } else {
      this.setState({ disthresh2: false })
    }
    this.setState({ dataChange: event.target.value.length })
  }

  ivChange(event) {
    const count = event.target.value.length
    if (count > 0) {
      this.setState({ disthresh3: true })
    } else {
      this.setState({ disthresh3: false })
    }
    this.setState({ ivChange: event.target.value.length })
  }

  handleSelectPadding(e) {
    this.setState({
      aesEncrypts: ({
        ...this.state.aesEncrypts,
        paddingMode: e.value
      })
    })
  }

  handleAesController() {
    if (this.state.aesController == "AES-128") {
      minLength = { value: 4, errorMessage: "AES-128" }
      maxLength = { value: 8, errorMessage: "AAAAAAAAAA" }
      { required: true }
    }
    else if (this.state.aesController == "AES-192") { }
    else if (this.state.aesController == "AES-256") {

    }

  }

  render() {
    const padding = [
      { value: "1", label: this.props.t("none") },
      { value: "2", label: this.props.t("pkcs7") },
      { value: "3", label: this.props.t("zeros") },
      { value: "4", label: this.props.t("ansiX923") },
      { value: "5", label: this.props.t("iso10126") },
    ]
    const defaultValues = { algorithm: "AES-128", iv: "00000000000000000000000000000000", padding: { value: "1", label: this.props.t("none") }, mode: "2" }
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <MetaTags>
              <title>Wechip - Wechip Payment Tools | {this.props.t("aes")}</title>
              <meta name="description" content="AES (Advanced Encryption Standard) is a symmetric encryption algorithm used to encrypt and decrypt data securely. Learn about AES encryption, decryption, cipher modes, key lengths, and more." />
              <meta name="keywords" content="AES, Advanced Encryption Standard, Encryption, Decrypt, Cipher, AES-128, AES-192, AES-256, Block Cipher, CBC, ECB, CTR, GCM, Encryption Modes, Symmetric Encryption, Symmetric Key, Rijndael, Data Encryption, Algorithm, Cryptography, Security" />

            </MetaTags>
          </Helmet>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("aes")} breadcrumbItem={this.props.t("aesEncrypt")} />
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidSubmit} model={defaultValues}>
                      <Row className="rowAes">
                        <Col>
                          <FormGroup>
                            <AvRadioGroup inline name="algorithm" errorMessage={this.props.t("pickOne")} >
                              <UncontrolledTooltip
                                placement="bottom"
                                target="Algorithm"
                              >
                                {this.props.t("cipherAlgorithmInfo")}
                              </UncontrolledTooltip>
                              <h6>{this.props.t("algorithm")}</h6>
                              <AvRadio label="AES-128" value="AES-128"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ aesController: "AES-128" }) } }}
                              />
                              <AvRadio label="AES-192" value="AES-192"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ aesController: "AES-192" }) } }}
                              />
                              <AvRadio label="AES-256" value="AES-256"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ aesController: "AES-256" }) } }}
                              />
                            </AvRadioGroup>
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Algorithm"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <AvRadioGroup inline name="mode" required errorMessage={this.props.t("pickOne")}>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="Mode"
                              >
                                {this.props.t("cipherModeInfo")}
                              </UncontrolledTooltip>
                              <h6>{this.props.t("mode")}</h6>
                              <AvRadio label="ECB" value="2"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ ivDsb: true, dataDsb: false, dataInputDsb: false, decodeDsb: false }) } }}
                              />
                              <AvRadio label="CBC" value="1"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ ivDsb: false, dataDsb: false, dataInputDsb: false, decodeDsb: false }) } }}
                              />
                              {/* <AvRadio label="CFB" value="4"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ ivDsb: false, dataDsb: false, dataInputDsb: false, decodeDsb: false }) } }}
                              />
                              <AvRadio label="OFB" value="3"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ ivDsb: false, dataDsb: false, dataInputDsb: false, decodeDsb: false }) } }}
                              />
                              <AvRadio label="CTS" value="5"
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ ivDsb: false, dataDsb: false, dataInputDsb: false, decodeDsb: false }) } }}
                              /> */}
                              {/* <AvRadio label="KCV" value="KCV" disabled
                                onChange={(e) => { if (e.target.checked == true) { this.setState({ ivDsb: true, dataDsb: true, dataInputDsb: true, decodeDsb: true }) } }}
                              /> */}
                            </AvRadioGroup>
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Mode"
                              ></i></div>
                          </FormGroup>
                        </Col>
                        <Col>
                          {/* <FormGroup className="select2-container">
                            <Label className="control-label">Padding</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Padding"
                            >
                              {this.props.t("cipherPaddingInfo")}
                            </UncontrolledTooltip>
                            <Select
                              defaultValue={padding[0]}
                              options={padding}
                              onChange={this.handleSelectPadding}
                            />
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Padding"
                              ></i></div>
                          </FormGroup> */}
                        </Col>

                        <Col>
                          <FormGroup>
                            <Label htmlFor="productname">{this.props.t("key")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Key"
                            >
                              {this.props.t("cipherKeyInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="key"
                              placeholder={this.props.t("fill")}
                              type="input"
                              value={this.state.aesEncrypts
                                ? this.state.aesEncrypts.key : ""}
                              onChange={this.keyChange}
                              validate={this.state.aesController == "AES-128" ? {
                                required: { value: true },
                                minLength: { value: 32, errorMessage: "Key has " + this.state.keyChange + " characters, its size has to be 32 characters." },
                                maxLength: { value: 32, errorMessage: "Key has " + this.state.keyChange + " characters, its size has to be 32 characters." },
                              }
                                : this.state.aesController == "AES-192" ? {
                                  required: { value: true },
                                  minLength: { value: 48, errorMessage: "Key has " + this.state.keyChange + " characters, its size has to be 48 characters." },
                                  maxLength: { value: 48, errorMessage: "Key has " + this.state.keyChange + " characters, its size has to be 48 characters." },
                                } : this.state.aesController == "AES-256" ? {
                                  required: { value: true },
                                  minLength: { value: 64, errorMessage: "Key has " + this.state.keyChange + " characters, its size has to be 64 characters." },
                                  maxLength: { value: 64, errorMessage: "Key has " + this.state.keyChange + " characters, its size has to be 64 characters." },
                                } : ""
                              }
                            />
                            {this.state.disthresh1 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.keyChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Key"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="DataInput"
                            >
                              {this.props.t("cipherDataInputInfo")}
                            </UncontrolledTooltip>
                            <AvRadioGroup inline name="dataInput" errorMessage={this.props.t("pickOne")}>
                              <h6>{this.props.t("dataInput")}</h6>
                              <AvRadio label={this.props.t("ascii")} value="1" />
                              <AvRadio label={this.props.t("hexadecimal")} value="2" />
                            </AvRadioGroup>
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="DataInput"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="productdesc">
                              {this.props.t("data")}
                            </Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="Data"
                            >
                              {this.props.t("cipherDataInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              style={{ height: 100 }}
                              name="data"
                              placeholder={this.props.t("fill")}
                              type="textarea"
                              disabled={this.state.dataDsb ? true : false}
                              value={this.state.aesEncrypts
                                ? this.state.aesEncrypts.data : ""}
                              className="form-control"
                              onChange={this.dataChange}
                              validate={
                                this.state.dataDsb ? {
                                  required: { value: false }
                                } : { required: { value: true } }}
                            />
                            {this.state.disthresh2 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.dataChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="Data"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup mb="2">
                            <Label htmlFor="productname">{this.props.t("iv")}</Label>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="IV"
                            >
                              {this.props.t("cipherIVInfo")}
                            </UncontrolledTooltip>
                            <AvField
                              name="iv"
                              placeholder={this.props.t("fill")}
                              type="input"
                              value={this.state.aesEncrypts
                                ? this.state.aesEncrypts.iv : ""}
                              onChange={this.ivChange}
                              disabled={this.state.ivDsb ? true : false}
                              validate={
                                this.state.ivDsb ? {
                                  required: { value: false }
                                } : {
                                  required: { value: true }
                                }}
                            />
                            {this.state.disthresh3 ? (
                              <span className="badgecount badge bg-success"
                                style={{
                                  position: "absolute",
                                  marginTop: 1,
                                  marginLeft: 3
                                }}
                              >
                                {this.state.ivChange}{" Length"}
                              </span>
                            ) : null}
                            <div className="infoSymbol">
                              <i
                                className="dripicons-information "
                                id="IV"
                              ></i>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="secondary"
                          onClick={() => window.location.reload()}
                          style={{ textAlign: "start" }}
                          className="buttonDecode"
                        >
                          {this.props.t("clearButton")}
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          onClick={() => this.setState({ isEncrypt: true })}
                          disabled={this.state.buttonTimer}
                        >
                          {this.props.t("encodeButton")}
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          onClick={() => { this.setState({ isEncrypt: false }) }}
                          disabled={this.state.buttonTimer}
                        >
                          {this.props.t("decodeButton")}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" >
                <Card className="scroll-col">
                  {this.state.items.slice().reverse()}
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
AESEncrypt.propTypes = {
  t: PropTypes.any,
}

export default (withTranslation()(AESEncrypt));
